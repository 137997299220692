import { useState } from "react";
import { UseDialogResult } from "interfaces/UseDialogResult";

export function useDialog(): UseDialogResult {
    const [open, setOpen] = useState<boolean>(false);
    const openDialog = () => setOpen(true);
    const closeDialog = () => {
        setOpen(false);
    };

    return {
        open,
        openDialog,
        closeDialog,
    };
}
