import React, { ReactElement } from "react";
import {
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    Grid,
    Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

export interface DashboardItemProps {
    sm?: number;
    xs?: number;
    headerBGColor?: string;
    headerBGHoverColor?: string;
    link: string;
    boxTitle: string;
    title: string;
    children: React.ReactNode;
}

export const DashboardItem = (props: DashboardItemProps): ReactElement => {
    const {
        sm = 12,
        xs = 12,
        headerBGColor = "success.main",
        headerBGHoverColor = "success.dark",
        link,
        boxTitle,
        title,
        children,
    } = props;
    return (
        <Grid item sm={sm} xs={xs}>
            <Card
                sx={{
                    width: 1,
                    // maxHeight: 350,
                }}
            >
                <CardActionArea>
                    <Link
                        style={{
                            textDecoration: "none",
                            color: "text.primary",
                        }}
                        to={link}
                    >
                        <CardHeader
                            sx={{
                                padding: 1,
                                backgroundColor: headerBGColor,
                                "&:hover": {
                                    backgroundColor: headerBGHoverColor,
                                },
                            }}
                            title={boxTitle}
                        >
                            <Typography
                                gutterBottom
                                variant="h4"
                                component="div"
                            >
                                {title}
                            </Typography>
                        </CardHeader>
                    </Link>
                </CardActionArea>
                <CardContent
                    sx={{
                        maxHeight: 350,
                        overflow: "auto",
                    }}
                >
                    {children}
                </CardContent>
            </Card>
        </Grid>
    );
};
