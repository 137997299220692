import { BaseBuilder } from "./BaseBuilder";
import { ConversionRateEntityDTO } from "@fspringveldt/qf-budget-generated-api-types";

export class ConversionRateBuilder extends BaseBuilder<ConversionRateEntityDTO> {
    constructor(
        protected model: ConversionRateEntityDTO = {
            code: "",
            isOwned: false,
            value: 0,
        }
    ) {
        super();
    }

    static empty(): ConversionRateEntityDTO {
        return new ConversionRateBuilder().build();
    }

    build(): ConversionRateEntityDTO {
        return this.model;
    }
}
