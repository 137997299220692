import { BaseBuilder } from "./BaseBuilder";
import {
    BudgetShareDTO,
    BudgetSharePermissionEnum,
} from "@fspringveldt/qf-budget-generated-api-types";
import { BudgetBuilder } from "./BudgetBuilder";

export class BudgetShareBuilder extends BaseBuilder<BudgetShareDTO> {
    constructor(
        protected model: BudgetShareDTO = {
            budget: BudgetBuilder.empty(),
            email: "",
            permission: BudgetSharePermissionEnum.Read,
            isOwned: true,
        }
    ) {
        super();
    }

    static empty(): BudgetShareDTO {
        return new BudgetShareBuilder().build();
    }

    build(): BudgetShareDTO {
        return this.model;
    }
}
