import { ModelListView } from "components/views/ModelListView";
import { useAppStateContext } from "components/App";
import { useGetListQuery } from "hooks/useGetListQuery";
import {
    TAX_TIER_CACHE_KEY,
    TAX_TIER_GET_ALL,
    TAX_YEAR_GET_ONE,
    TAX_YEAR_GET_ONE_CACHE_KEY,
} from "../../constants";
import React from "react";
import { TaxTierModel } from "models/TaxTierModel";
import { useParams } from "react-router-dom";
import { useGetOneQuery } from "../../hooks/useGetOneQuery";
import { Alert } from "@mui/material";
import {
    TaxTierEntityDTO,
    TaxYearEntityDTO,
} from "@fspringveldt/qf-budget-generated-api-types";

type ParamTypes = string;

export const TaxTiers = (): React.ReactElement => {
    const {
        state: { userAccessToken },
    } = useAppStateContext();
    const { taxYearId } = useParams<ParamTypes>();
    const {
        error,
        data,
        isLoading: isLoadingTaxTier,
    } = useGetListQuery<TaxTierEntityDTO>(
        TAX_TIER_GET_ALL.replace(":taxYearId", taxYearId!!),
        TAX_TIER_CACHE_KEY + taxYearId,
        userAccessToken
    );
    const {
        error: taxYearError,
        data: taxYear,
        isLoading,
    } = useGetOneQuery<TaxYearEntityDTO>(
        TAX_YEAR_GET_ONE.replace(":taxYearId", taxYearId!!),
        TAX_YEAR_GET_ONE_CACHE_KEY + taxYearId,
        userAccessToken
    );

    if (taxYearError || !taxYear || !taxYearId) {
        return (
            <Alert severity="error">
                Something went wrong fetching your data.
            </Alert>
        );
    }

    const initialData: TaxTierEntityDTO = {
        end: 0,
        isOwned: false,
        position: 0,
        rate: 0.0,
        start: 0.0,
        taxYear,
    };

    return (
        <ModelListView
            model={TaxTierModel(taxYearId)}
            error={error}
            rows={data}
            initialData={initialData}
            isLoading={isLoading || isLoadingTaxTier}
        />
    );
};
