import React, { useEffect } from "react";
import { auth0config } from "../constants";
import { useAuth0 } from "@auth0/auth0-react";
import {
    AppReducerActions,
    doLogin,
    setAuth0Error,
    setAuth0User,
} from "../reducers/AppActionCreators";
import { useAppStateContext } from "components/App";

export function useAuth(dispatch: React.Dispatch<AppReducerActions>) {
    const {
        state: { userAccessToken, auth0User },
    } = useAppStateContext();
    const {
        getAccessTokenSilently,
        isAuthenticated,
        user,
        getAccessTokenWithPopup,
    } = useAuth0();

    useEffect(() => {
        async function getUserAccessToken() {
            const options = {
                audience: auth0config.audience,
                scopes: ["openid", "profile", "email"],
            };
            let token: string;
            if (!isAuthenticated || !userAccessToken) {
                try {
                    token = await getAccessTokenSilently(options);
                    dispatch(doLogin(token));
                } catch (e) {
                    try {
                        token = await getAccessTokenWithPopup(options);
                        dispatch(doLogin(token));
                    } catch (e) {
                        dispatch(setAuth0Error(true));
                        console.error("Error getting access tokens:", e);
                    }
                }
            }
        }

        !isAuthenticated && getUserAccessToken();
        // Set auth0User
        user && !auth0User && dispatch(setAuth0User(user));
    }, [
        dispatch,
        getAccessTokenSilently,
        isAuthenticated,
        user,
        userAccessToken,
        auth0User,
        getAccessTokenWithPopup,
    ]);
}
