import React from "react";
import { validator } from "models/UserModel";
import { Box, FormControl, Grid } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { useFormState } from "../../../hooks/useFormState";
import { USER_CACHE_KEY, USER_GET_ALL } from "../../../constants";
import {
    MeDTO,
    UserEntityDTO,
} from "@fspringveldt/qf-budget-generated-api-types/";
import { CountrySelector } from "../../CountrySelector";

interface UserUpdateFormContainerProps {
    initialData: MeDTO;
    picture?: string;
}

export function UserPreferencesForm(props: UserUpdateFormContainerProps) {
    const { initialData, picture } = props;
    const userExists = !!initialData.id;
    const endPoint = userExists
        ? `${USER_GET_ALL}${initialData.id}/`
        : USER_GET_ALL;

    console.log("Moo", initialData);
    const successNotice = !userExists ? "User created" : "User updated";
    const errorNotice = !userExists ? "Create failed" : "Update failed";

    const userEntity: UserEntityDTO = {
        defaultCountry: initialData.defaultCountry,
        email: "",
        firstName: "",
        lastName: "",
        isOwned: true,
    };
    const {
        state,
        setFieldValue,
        doSave,
        validationResult,
        mutationQuery,
        isSaving,
    } = useFormState({
        initialData: userEntity,
        endPoint,
        cacheKey: USER_CACHE_KEY,
        successNotice,
        errorNotice,
        validator,
    });
    return (
        <>
            <Grid spacing={2} container>
                {picture && (
                    <Grid item xs={12}>
                        <img
                            aria-label={"User profile picture"}
                            src={picture}
                            alt={"User profile"}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <FormControl variant={"filled"} fullWidth>
                        <CountrySelector
                            fieldName={"defaultCountry"}
                            fieldValue={state.defaultCountry}
                            setFieldValue={setFieldValue}
                            validationResult={validationResult}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ textAlign: "right" }}>
                        <LoadingButton
                            className={"save-user-profile"}
                            onClick={doSave(mutationQuery)}
                            color="primary"
                            aria-label="Save user profile"
                            startIcon={<SaveIcon />}
                            loading={isSaving}
                            loadingPosition="start"
                            variant="contained"
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
