import { MutationProps } from "../../interfaces/MutationProps";
import { useDialog } from "../../hooks/useDialog";
import { useFormState } from "../../hooks/useFormState";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import React from "react";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { BaseDTOType } from "../../mocks/builders/BaseBuilder";

export function ModelAddDialog<T extends BaseDTOType>(
    props: MutationProps<T>
): React.ReactElement {
    const { render, queryKey, postURL, dialogTitle, initialData } = props;
    const { open, openDialog, closeDialog } = useDialog();

    const doCloseDialog = () => {
        resetState();
        closeDialog();
    };
    const {
        isSaving: isLoading,
        mutationQuery: mutate,
        doSave,
        setFieldValue,
        state,
        validationResult,
        resetState,
    } = useFormState({
        initialData,
        endPoint: postURL,
        cacheKey: queryKey,
        successNotice: `New item ${dialogTitle}`,
        errorNotice: undefined,
        validator: props.validator,
        onSuccess: () => closeDialog(),
        onError: undefined,
    });

    return (
        <>
            <IconButton
                id={"btnAdd"}
                onClick={openDialog}
                color="primary"
                aria-label={`Add table ${dialogTitle}`}
                component="span"
                className={"crud-table-btn-add"}
            >
                <AddCircleRoundedIcon />
            </IconButton>
            <Dialog open={open} onClose={closeDialog}>
                <DialogTitle>{`Create ${dialogTitle}`}</DialogTitle>
                <DialogContent>
                    {render(state, setFieldValue, validationResult)}
                </DialogContent>
                <DialogActions>
                    <IconButton
                        onClick={doCloseDialog}
                        color="secondary"
                        aria-label={`Close dialog`}
                    >
                        <CancelIcon />
                    </IconButton>
                    <IconButton
                        onClick={doSave(mutate)}
                        color="primary"
                        aria-label={`Save ${dialogTitle}`}
                        component="span"
                    >
                        {!isLoading && <SaveIcon />}
                        {isLoading && <CircularProgress />}
                    </IconButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
