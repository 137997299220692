import { EXPENSE_CACHE_KEY, EXPENSE_GET_ALL } from "../constants";
import { TableCell } from "@mui/material";
import { ExpenseForm } from "../components/forms/ExpenseForm";
import { Model, ValidatorResult } from "../types/Model";
import { ExpenseEntityDTO } from "@fspringveldt/qf-budget-generated-api-types";
import {
    displayNoneXs,
    ModelListViewSMTableCell,
} from "../components/views/ModelListView";
import React from "react";

export const ExpenseModel = (budgetId: string): Model<ExpenseEntityDTO> => {
    const url = EXPENSE_GET_ALL.replace(":budgetId", budgetId);
    return {
        cacheKey: EXPENSE_CACHE_KEY + budgetId,
        columns: [
            { sortByField: "name", label: "Name" },
            { sortByField: "country", label: "Country" },
            { sortByField: "amount", label: "Amount" },
            { sortByField: "frequency", label: "Frequency" },
        ],
        addURL: url,
        deleteURL: url,
        editURL: url,
        label: "Expenses",
        validator,
        renderMutation: (row, setFieldValue, validationResult) => (
            <ExpenseForm
                state={row}
                setFieldValue={setFieldValue}
                validationResult={validationResult}
            />
        ),
        render: (dto) => (
            <>
                <ModelListViewSMTableCell
                    heading={dto.name}
                    info={[dto.country, dto.amount.toString(), dto.frequency]}
                />
                <TableCell sx={displayNoneXs}>{dto.name}</TableCell>
                <TableCell sx={displayNoneXs}>{dto.country}</TableCell>
                <TableCell sx={displayNoneXs}>{dto.amount}</TableCell>
                <TableCell sx={displayNoneXs}>{dto.frequency}</TableCell>
            </>
        ),
    };
};

export function validator(state: ExpenseEntityDTO): ValidatorResult {
    let errors: Record<string, string> = {};
    if (!state.name) {
        errors.name = "Name is required";
    }
    if (!state.amount) {
        errors.amount = "Amount is required";
    }
    if (!state.frequency) {
        errors.frequency = "Frequency is required";
    }
    if (!state.startDate) {
        errors.startDate = "Start date is required";
    }
    if (!state.country) {
        errors.country = "Country is required";
    }
    if (!state.expenseCategory) {
        errors.expenseCategory = "Expenses category is required";
    }
    if (!state.fundAccount) {
        errors.fundAccount = "Fund account is required";
    }
    return errors;
}
