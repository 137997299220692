import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import React from "react";
import { MonthTotal } from "../../models/PivotTableModel";

interface Props {
    monthTotal: MonthTotal;
    year: number | string;
}

export const ExpenseBudgetMonthDetail = ({ monthTotal }: Props) => {
    return (
        <Grid item xs={12}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Expense name</TableCell>
                        <TableCell>Amount</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {monthTotal.debitDetails.map((el) => (
                        <TableRow key={el.name}>
                            <TableCell>{el.name}</TableCell>
                            <TableCell>{el.amount.toFixed(0)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Grid>
    );
};
