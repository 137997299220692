import { useEffect, useState } from "react";
import orderBy from "lodash.orderby";

export enum SortDirection {
    Ascending = "asc",
    Descending = "desc",
}

interface DataSortResult<T> {
    sortDirection: string;
    sortField: string | undefined;
    sortedRows: T[] | undefined;
    doSetSortColumn: (field: string) => () => void;
}

export function useDataSort<T>(rows: T[] | undefined): DataSortResult<T> {
    const [sortDirection, setSortDirection] = useState<string>(
        SortDirection.Ascending
    );

    const [sortField, setSortField] = useState<string | undefined>();

    const [sortedData, setSortedData] = useState<T[] | undefined>(rows);
    useEffect(() => {
        if (rows) {
            const fields = sortField ? [sortField] : [];
            const direction =
                sortDirection === SortDirection.Ascending
                    ? [SortDirection.Ascending]
                    : [SortDirection.Descending];
            setSortedData(orderBy(rows, fields, direction));
        }
    }, [rows, sortField, sortDirection]);

    function doSetSortColumn(field: string) {
        return function () {
            setSortField(field);
            if (sortDirection === SortDirection.Ascending) {
                setSortDirection(SortDirection.Descending);
                setSortedData(
                    orderBy(rows, [field], [SortDirection.Descending])
                );
            } else {
                setSortDirection(SortDirection.Ascending);
                setSortedData(
                    orderBy(rows, [field], [SortDirection.Ascending])
                );
            }
        };
    }

    return {
        sortDirection,
        sortField,
        sortedRows: sortedData,
        doSetSortColumn,
    };
}
