import {
    Box,
    Button,
    IconButton,
    Skeleton,
    Toolbar,
    Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAppStateContext } from "../App";
import { doLogout, doRequestLogin } from "reducers/AppActionCreators";
import { useAuth0 } from "@auth0/auth0-react";
import { SideMenu } from "./SideMenu";

export function TopMenu(): React.ReactElement {
    const [sideMenuState, setSideMenuState] = useState<boolean>(false);
    const {
        state: { isUserReady, isAuthTokenRequested, isAuthenticated },
        dispatch,
    } = useAppStateContext();
    const { loginWithRedirect, logout: auth0Logout } = useAuth0();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    const login = async () => {
        dispatch(doRequestLogin());
        await loginWithRedirect();
    };

    const logout = () => {
        dispatch(doLogout());
        auth0Logout({ returnTo: window.location.origin });
    };
    const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" ||
                (event as React.KeyboardEvent).key === "Shift")
        ) {
            return;
        }

        setSideMenuState(!sideMenuState);
    };
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    {isUserReady && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1 }}
                    >
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "text.primary",
                            }}
                            to="/"
                        >
                            <Box sx={{ mt: 1 }}>
                                <img
                                    alt="logo"
                                    width={matches ? "300" : "200"}
                                    src={"/logos/logo_white_cropped.png"}
                                />
                            </Box>
                        </Link>
                    </Typography>
                    {!isAuthenticated && (
                        <Button color="inherit" onClick={login}>
                            {isAuthTokenRequested ? <Skeleton /> : <>Login</>}
                        </Button>
                    )}
                    {isUserReady && (
                        <Button color="inherit" onClick={logout}>
                            Logout
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
            {isUserReady && (
                <SideMenu
                    toggleDrawer={toggleDrawer}
                    openState={sideMenuState}
                />
            )}
        </Box>
    );
}
