import { monthValues } from "../analytics/Analytics";
import { Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { lastYear, nextYear } from "../../constants";
import { navDefinitions } from "../routes/NavDefinitions";
import { Link } from "react-router-dom";

interface MonthAndYearSpinnerProps {
    budgetId: string;
    dashYear: number;
    dashMonth: number;
}

export const MonthAndYearSpinner = (props: MonthAndYearSpinnerProps) => {
    const { budgetId, dashYear, dashMonth } = props;

    const getPath = (year: number, month: number) => {
        let pathMonth = month;
        let pathYear = year;

        if (month > 12 && year < nextYear) {
            pathMonth = 1;
            pathYear = pathYear + 1;
        }

        if (month < 1 && year > lastYear) {
            pathMonth = 12;
            pathYear = pathYear - 1;
        }

        if (year === nextYear && month > 12) {
            pathMonth = 12;
        }

        if (year === lastYear && month < 1) {
            pathMonth = 1;
        }

        return navDefinitions.dashboards.path
            .replace(":budgetId", budgetId)
            .replace(":year", pathYear.toString())
            .replace(":month", pathMonth.toString());
    };
    const disablePrevYear = dashYear <= lastYear;
    const disableNextYear = dashYear >= nextYear;
    const disableFirstMonth = disablePrevYear && dashMonth === 1;
    const disableLastMonth = disableNextYear && dashMonth === 12;
    const disablePrevMonth = disablePrevYear && disableFirstMonth;
    const disableNextMonth = disableNextYear && disableLastMonth;
    return (
        <Typography variant={"h5"} align={"center"} marginBottom={2}>
            <CustomLink
                title={"View first month"}
                to={getPath(dashYear, 1)}
                disabled={disableFirstMonth}
            >
                &laquo;
            </CustomLink>
            &nbsp;
            <CustomLink
                title={"View previous month"}
                to={getPath(dashYear, dashMonth - 1)}
                disabled={disablePrevMonth}
            >
                &lt;
            </CustomLink>
            &nbsp;
            {monthValues.find((el) => el.number === dashMonth)?.name}{" "}
            <CustomLink
                title={"View next month"}
                to={getPath(dashYear, dashMonth + 1)}
                disabled={disableNextMonth}
            >
                &gt;
            </CustomLink>
            &nbsp;
            <CustomLink
                title={"View last month"}
                to={getPath(dashYear, 12)}
                disabled={disableLastMonth}
            >
                &raquo;
            </CustomLink>
            &nbsp;
            <CustomLink
                title={"View previous year"}
                to={getPath(dashYear - 1, dashMonth)}
                disabled={disablePrevYear}
            >
                &lt;
            </CustomLink>
            &nbsp;
            {dashYear}&nbsp;
            <CustomLink
                title={"View next year"}
                to={getPath(dashYear + 1, dashMonth)}
                disabled={disableNextYear}
            >
                &gt;
            </CustomLink>
            &nbsp;
        </Typography>
    );
};

interface CustomLinkProps {
    title: string;
    to: string;
    children: ReactNode;
    disabled?: boolean;
}

/**
 * Returns either a link or a span
 * @constructor
 */
const CustomLink = (props: CustomLinkProps) => {
    if (props.disabled) {
        return <span title={props.title}>{props.children}</span>;
    }

    return (
        <Link title={props.title} to={props.to}>
            {props.children}
        </Link>
    );
};
