import FaceIcon from "@mui/icons-material/Face";
import CategoryIcon from "@mui/icons-material/Category";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CalculateIcon from "@mui/icons-material/Calculate";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PivotTableChartIcon from "@mui/icons-material/PivotTableChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import TimelineIcon from "@mui/icons-material/Timeline";
import PaidIcon from "@mui/icons-material/Paid";

export interface NavRoute {
    /**
     * The path to this route
     */
    path: string;
    /**
     * Route's title to be used in menus
     */
    menuTitle: string;
    /**
     * An optional svg icon to associate with this route
     */
    icon?: React.ReactElement;
    /**
     * A list of roles required to view this NavRoute
     */
    roles?: string[];
}

type NavRouteType =
    | "home"
    | "expenseCategory"
    | "fundAccount"
    | "nonWorkingDay"
    | "expense"
    | "all"
    | "user"
    | "expenseBudget"
    | "expenseBudgetDetail"
    | "incomeBudget"
    | "incomeBudgetDetail"
    | "logout"
    | "firstTimeSetup"
    | "firstTimeSetupStep2"
    | "firstTimeSetupStep3"
    | "income"
    | "schedule"
    | "analytics"
    | "taxRate"
    | "taxTier"
    | "taxHolder"
    | "conversionRate"
    | "creditDetail"
    | "budgets"
    | "budget"
    | "dashboards"
    | "budgetShare"
    | "budgetShareAccept"
    | "budgetShareDecline";

export type NavDefinitionsType = Record<NavRouteType, NavRoute>;

export const [budgets] = ["/budgets"];

export const navDefinitions: NavDefinitionsType = {
    budgetShareDecline: {
        menuTitle: "Invitation Decline",
        path: `${budgets}/:budgetId/respond-to-invite/:budgetShareId/decline`,
    },
    budgetShareAccept: {
        menuTitle: "Invitation Accept",
        path: `${budgets}/:budgetId/respond-to-invite/:budgetShareId/accept`,
    },
    budgetShare: {
        menuTitle: "Share",
        path: `${budgets}/:budgetId/share`,
    },
    dashboards: {
        menuTitle: "Dashboard",
        path: `${budgets}/:budgetId/:year/:month`,
    },
    budget: {
        path: `${budgets}/:budgetId`,
        menuTitle: "Budget",
    },
    budgets: {
        menuTitle: "Budgets",
        path: budgets,
        icon: <PaidIcon />,
    },
    expenseBudget: {
        menuTitle: "Expenses Budget",
        path: `${budgets}/:budgetId/expense-budget`,
        icon: <PivotTableChartIcon />,
    },
    expenseBudgetDetail: {
        menuTitle: "Expenses Detail",
        path: `${budgets}/:budgetId/expense-budget/:year`,
    },
    expense: {
        menuTitle: "Expenses Setup",
        path: `${budgets}/:budgetId/expenses`,
        icon: <PointOfSaleIcon />,
    },
    incomeBudget: {
        menuTitle: "Income Budget",
        path: `${budgets}/:budgetId/income-budget`,
        icon: <PivotTableChartIcon />,
    },
    incomeBudgetDetail: {
        menuTitle: "Income Budget Detail",
        path: `${budgets}/:budgetId/income-budget/:year`,
    },
    income: {
        menuTitle: "Income Setup",
        path: `${budgets}/:budgetId/income`,
        icon: <AttachMoneyIcon />,
    },
    creditDetail: {
        menuTitle: "Credit detail",
        path: "/credit-detail/:creditId/:year/:month/",
    },
    nonWorkingDay: {
        menuTitle: "Non-working day",
        path: "/non-working-day/",
        icon: <CalendarMonthIcon />,
    },
    taxHolder: {
        menuTitle: "Tax",
        path: "/tax",
    },
    conversionRate: {
        menuTitle: "Conversion Rates",
        path: "/conversion-rate/",
        icon: <CalculateIcon />,
        roles: ["budget:admin"],
    },
    taxTier: {
        menuTitle: "Tax Rate Tiers",
        path: "/tax/rate/:taxYearId/tier/",
        icon: <CalculateIcon />,
        roles: ["budget:admin"],
    },
    taxRate: {
        menuTitle: "Tax Rate",
        path: "/tax/rate",
        icon: <CalculateIcon />,
        roles: ["budget:admin"],
    },
    schedule: {
        menuTitle: "Expenses Schedule",
        path: "/schedule/:budgetId/:year/:month",
        icon: <TimelineIcon />,
    },
    analytics: {
        menuTitle: "Analytics",
        path: "/analytics/:budgetId/:year/:month",
        icon: <BarChartIcon />,
    },
    firstTimeSetup: {
        menuTitle: "First time setup",
        path: "/first-time-setup",
    },
    firstTimeSetupStep2: {
        menuTitle: "First time setup step 2",
        path: "/first-time-setup/step2",
    },
    firstTimeSetupStep3: {
        menuTitle: "First time setup step 3",
        path: "/first-time-setup/step3",
    },
    logout: { menuTitle: "Logout", path: "/logout" },
    home: {
        menuTitle: "Home",
        path: "/",
    },
    expenseCategory: {
        menuTitle: "Expenses Categories",
        path: "/expense-categories",
        icon: <CategoryIcon />,
    },
    fundAccount: {
        menuTitle: "Fund Accounts",
        path: "/account-types",
        icon: <AccountBalanceWalletIcon />,
    },
    user: {
        menuTitle: "Profile",
        path: "/me",
        icon: <FaceIcon />,
    },
    all: { menuTitle: "Not Found", path: "*" },
};

export const sideMenuSetupSection: NavRoute[] = [
    navDefinitions.income,
    navDefinitions.expense,
    navDefinitions.incomeBudget,
    navDefinitions.expenseBudget,
    navDefinitions.analytics,
    navDefinitions.schedule,
];

export const sideMenuSettingsSection: NavRoute[] = [
    navDefinitions.user,
    navDefinitions.expenseCategory,
    navDefinitions.fundAccount,
    navDefinitions.nonWorkingDay,
    navDefinitions.taxRate,
    navDefinitions.conversionRate,
];
