import React, { ReactElement, useState } from "react";
import {
    Badge,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { CalendarDayItem, COL_WIDTH } from "./Calendar";

interface CalendarDayProps extends CalendarDayItem {
    month: number;
    year: number;
}

export const CalendarDay = ({
    day,
    entries,
    month,
    year,
}: CalendarDayProps): ReactElement => {
    const today = new Date().getDate();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const closeDialog = () => setOpenDialog(false);

    return (
        <Grid xs={COL_WIDTH} item className="calendar-item">
            <Grid item className="calendar-item-day">
                {entries?.length && (
                    <Button
                        onClick={() => {
                            setOpenDialog(true);
                        }}
                    >
                        <Badge badgeContent={entries?.length} color={"primary"}>
                            {day}
                        </Badge>
                    </Button>
                )}
                {!entries?.length && (
                    <Badge badgeContent={entries?.length} color={"primary"}>
                        {today === day ? (
                            <u>
                                <strong>{day}</strong>
                            </u>
                        ) : (
                            day
                        )}
                    </Badge>
                )}
            </Grid>

            <Dialog open={openDialog} onClose={closeDialog}>
                <DialogTitle>
                    Entries for {day}/{month}/{year}
                </DialogTitle>
                <DialogContent>
                    <Grid item xs={12}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {entries?.map((el) => (
                                    <TableRow key={el.name}>
                                        <TableCell>{el.name}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <IconButton
                        onClick={closeDialog}
                        color="secondary"
                        aria-label="Close dialog"
                    >
                        <CancelIcon />
                    </IconButton>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};
