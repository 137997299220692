import {
    Bar,
    BarChart,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import {
    BUDGET_GRAPH_MONTH,
    thisMonth,
    thisYear,
    USER_GRAPH_MONTH_CACHE_KEY,
} from "../../constants";
import React from "react";
import { MoneyMovementDTO } from "models/MoneyMovementModel";
import { Box, Skeleton, Stack } from "@mui/material";
import { useGetListQuery } from "hooks/useGetListQuery";
import { useAppStateContext } from "../App";
import { useParams } from "react-router-dom";

interface Props {
    month?: number;
    year?: number;
}

export function MonthToDateBarChart({
    month = thisMonth,
    year = thisYear,
}: Props) {
    const {
        state: { userAccessToken },
    } = useAppStateContext();
    const { budgetId = "" } = useParams<string>();
    const { data } = useGetListQuery<MoneyMovementDTO>(
        BUDGET_GRAPH_MONTH.replace(":budgetId", budgetId)
            .replace(":year", year.toString())
            .replace(":month", month.toString()),
        USER_GRAPH_MONTH_CACHE_KEY + month + year,
        userAccessToken
    );

    return (
        <>
            {data ? (
                <Box sx={{ mx: "auto", width: 350, maxWidth: "100%" }}>
                    <ResponsiveContainer width={"100%"} height={250}>
                        <BarChart data={[data]}>
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar
                                name="Money In"
                                dataKey="moneyIn"
                                fill="#8884d8"
                            />
                            <Bar
                                name=" Money Out"
                                dataKey="moneyOut"
                                fill="#82ca9d"
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </Box>
            ) : (
                <Stack spacing={1}>
                    <Skeleton variant="text" />
                    <Skeleton variant="rectangular" height={100} />
                </Stack>
            )}
        </>
    );
}
