import jwt_decode from "jwt-decode";
import { JwtPayloadExtended } from "../types/JwtPayloadExtended";

export const checkUserRoles = (
    userAccessToken: string,
    roles?: string[]
): boolean => {
    if (!userAccessToken) {
        return false;
    }
    if (roles) {
        try {
            const decoded: JwtPayloadExtended = jwt_decode(userAccessToken);
            if (!roles.every((p) => decoded.permissions.includes(p))) {
                return false;
            }
        } catch (e) {
            console.error("jwt_decode error", e);
            return false;
        }
    }

    return true;
};
