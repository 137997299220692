import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ExpenseCategories } from "./ExpenseCategories";
import { FundAccounts } from "./FundAccounts";
import { ExpenseBudget } from "./ExpenseBudget";
import { ExpenseBudgetDetail } from "./ExpenseBudgetDetail";
import { Expenses } from "./Expenses";
import { UserPreferences } from "./UserPreferences";
import { Dashboard } from "../dashboard/Dashboard";
import { LogMeOut } from "./LogMeOut";
import React, { ReactNode } from "react";
import { navDefinitions } from "components/routes/NavDefinitions";
import { Incomes } from "./Incomes";
import { Layout } from "../layout/Layout";
import { Analytics } from "../analytics/Analytics";
import { Schedule } from "../schedule/Schedule";
import { SecureRoute } from "../utils/SecureRoute";
import { TaxYears } from "./TaxYears";
import { Alert } from "@mui/material";
import { TaxTiers } from "./TaxTiers";
import { TaxHolder } from "./TaxHolder";
import { ConversionRate } from "./ConversionRate";
import { NonWorkingDays } from "./NonWorkingDays";
import { IncomeBudgetDetail } from "./IncomeBudgetDetail";
import { IncomeBudget } from "./IncomeBudget";
import { Budgets } from "./Budgets";
import { Budget } from "./Budget";
import { BudgetShares } from "./BudgetShares";
import { BudgetShareReply } from "./BudgetShareReply";

interface Props {
    children?: ReactNode;
}

export const AppRoutes = ({ children }: Props) => {
    return (
        <BrowserRouter>
            {children}
            <Routes>
                <Route path={"/"} element={<Layout />}>
                    <Route
                        path={navDefinitions.all.path}
                        element={
                            <Alert severity="error">Oops, nothing's here</Alert>
                        }
                    />
                    {/* Secure routes */}
                    <Route element={<SecureRoute />}>
                        <Route index element={<Budgets />} />

                        <Route path={navDefinitions.budgets.path}>
                            <Route index element={<Budgets />} />
                            <Route
                                index
                                path={navDefinitions.dashboards.path}
                                element={<Dashboard />}
                            />
                            <Route
                                path={navDefinitions.budgetShare.path}
                                element={<BudgetShares />}
                            />
                            <Route
                                path={navDefinitions.budgetShareAccept.path}
                                element={<BudgetShareReply accept />}
                            />
                            <Route
                                path={navDefinitions.budgetShareDecline.path}
                                element={<BudgetShareReply />}
                            />
                            <Route
                                path={navDefinitions.budget.path}
                                element={<Budget />}
                            />
                            <Route
                                path={navDefinitions.income.path}
                                element={<Incomes />}
                            />
                            <Route
                                path={navDefinitions.incomeBudget.path}
                                element={<IncomeBudget />}
                            />
                            <Route
                                path={navDefinitions.incomeBudgetDetail.path}
                                element={<IncomeBudgetDetail />}
                            />
                            <Route
                                path={navDefinitions.expense.path}
                                element={<Expenses />}
                            />
                            <Route
                                path={navDefinitions.expenseBudget.path}
                                element={<ExpenseBudget />}
                            />
                            <Route
                                path={navDefinitions.expenseBudgetDetail.path}
                                element={<ExpenseBudgetDetail />}
                            />
                        </Route>
                        <Route
                            path={navDefinitions.schedule.path}
                            element={<Schedule />}
                        />
                        <Route
                            path={navDefinitions.analytics.path}
                            element={<Analytics />}
                        />
                        <Route
                            path={navDefinitions.expenseCategory.path}
                            element={<ExpenseCategories />}
                        />
                        <Route
                            path={navDefinitions.fundAccount.path}
                            element={<FundAccounts />}
                        />
                        <Route
                            path={navDefinitions.nonWorkingDay.path}
                            element={<NonWorkingDays />}
                        />
                        <Route
                            path={navDefinitions.user.path}
                            element={<UserPreferences />}
                        />
                        <Route
                            path={navDefinitions.logout.path}
                            element={<LogMeOut />}
                        />
                        <Route
                            path={navDefinitions.creditDetail.path}
                            element={<IncomeBudgetDetail />}
                        />
                    </Route>

                    {/* Budget admin routes */}
                    <Route element={<SecureRoute roles={["budget:admin"]} />}>
                        <Route
                            path={navDefinitions.taxHolder.path}
                            element={<TaxHolder />}
                        >
                            <Route index element={<TaxYears />} />
                            <Route
                                path={navDefinitions.taxRate.path}
                                element={<TaxYears />}
                            />
                            <Route
                                path={navDefinitions.taxTier.path}
                                element={<TaxTiers />}
                            />
                            <Route
                                path={navDefinitions.all.path}
                                element={
                                    <Alert severity="error">
                                        Oops, nothing's here
                                    </Alert>
                                }
                            />
                        </Route>
                        <Route
                            path={navDefinitions.conversionRate.path}
                            element={<ConversionRate />}
                        />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};
