import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "components/App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Auth0Provider } from "@auth0/auth0-react";
import { auth0config } from "./constants";
import { Box } from "@mui/material";
import ErrorBoundary from "./components/ErrorBoundary";

if (process.env.REACT_APP_ENVIRONMENT === "development") {
    const { worker } = require("./mocks/browser");
    worker.start();
}

const queryClient = new QueryClient();

export const AppTheme = createTheme({
    palette: {
        mode: "dark",
    },
});
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={AppTheme}>
                    <Auth0Provider
                        domain={auth0config.domain || ""}
                        clientId={auth0config.clientId || ""}
                        redirectUri={auth0config.redirectUri}
                        audience={auth0config.audience}
                        cacheLocation="localstorage"
                    >
                        <Box
                            sx={{
                                bgcolor: "background.default",
                                minHeight: "100vh",
                                color: "text.primary",
                            }}
                        >
                            <App />
                        </Box>
                    </Auth0Provider>
                </ThemeProvider>
            </QueryClientProvider>
        </ErrorBoundary>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
