import { format, isValid } from "date-fns";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import "@mui/lab";
import React, { useEffect } from "react";
import { ValidationResult } from "../../../interfaces/ValidationResult";

const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";

interface FormFieldProps {
    label: string;
    fieldName: string;
    fieldValue?: Date;
    setFieldValue: (key: string, value: string) => void;
    validationResult?: ValidationResult;
    required?: boolean;
}

export function Calendar(props: FormFieldProps) {
    const { validationResult, fieldValue, setFieldValue, label, fieldName } =
        props;

    useEffect(() => {}, []);
    return (
        <DatePicker
            label={label}
            mask={"____-__-__"}
            inputFormat={DEFAULT_DATE_FORMAT}
            value={fieldValue || null}
            onChange={(newValue: any) => {
                if (newValue === null) {
                    setFieldValue(fieldName, newValue);
                }
                newValue &&
                    isValid(newValue) &&
                    setFieldValue(
                        fieldName,
                        format(newValue, DEFAULT_DATE_FORMAT)
                    );
            }}
            renderInput={(params: any) => {
                return (
                    <TextField
                        {...params}
                        autoComplete={"off"}
                        variant="filled"
                        error={!!validationResult?.errors?.[fieldName]}
                        helperText={validationResult?.errors?.[fieldName]}
                        required={props.required}
                    />
                );
            }}
        />
    );
}
