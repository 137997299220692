import { ValidatorResult } from "../types/Model";
import {
    ModelBuilder,
    RenderMutationType,
    RenderType,
    ValidatorType,
} from "./ModelBuilder";
import { BUDGET_SHARE_CACHE_KEY, BUDGET_SHARE_GET_ALL } from "../constants";
import {
    Container,
    FormControl,
    Grid,
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import {
    FormDataProps,
    ViewDataProps,
} from "../components/forms/FormDataProps";
import React from "react";
import { validateEmail } from "../utils/utils";
import { Dropdown } from "../components/forms/dropdown/Dropdown";
import { budgetSharePermissions } from "../data/BudgetPermissions";
import { BudgetShareDTO } from "@fspringveldt/qf-budget-generated-api-types";

const columns = [
    { sortByField: "email", label: "Email" },
    { sortByField: "recipientName", label: "Name" },
    { sortByField: "permission", label: "Permission" },
    { sortByField: "sent", label: "Sent" },
    { sortByField: "accepted", label: "Accepted" },
    { sortByField: "declined", label: "Declined" },
];

const render: RenderType<BudgetShareDTO> = (row) => {
    return (
        <>
            <TableCell>{row.email}</TableCell>
            <TableCell>{row.recipientName}</TableCell>
            <TableCell>{row.permission}</TableCell>
            <TableCell>{row.sent}</TableCell>
            <TableCell>{row.accepted}</TableCell>
            <TableCell>{row.declined}</TableCell>
        </>
    );
};

const validator: ValidatorType<BudgetShareDTO> = (state): ValidatorResult => {
    let errors: ValidatorResult = {};

    if (!state.email) {
        errors.email = "Email is required";
    }

    if (!state.recipientName) {
        errors.recipientName = "Name is required";
    }
    if (state.email && !validateEmail(state.email)) {
        errors.email = "Email is invalid, please check";
    }
    if (!state.permission) {
        errors.permission = "Permission is required";
    }
    return errors;
};

const renderMutation: RenderMutationType<BudgetShareDTO> = (
    row,
    setFieldValue,
    validationResult
) => (
    <BudgetShareForm
        state={row}
        setFieldValue={setFieldValue}
        validationResult={validationResult}
    />
);

const renderView: RenderType<BudgetShareDTO> = (row) => (
    <BudgetShareView state={[row]} />
);

export const BudgetShareModel = (budgetId: string) => {
    const url = BUDGET_SHARE_GET_ALL.replace(":budgetId", budgetId);
    return new ModelBuilder<BudgetShareDTO>(
        "Budget Share",
        BUDGET_SHARE_CACHE_KEY + budgetId,
        columns,
        render
    )
        .withAddURL(url)
        .withDeleteURL(url)
        .withEditURL(url)
        .withValidator(validator)
        .withRenderView(renderView)
        .withRenderMutation(renderMutation)
        .build();
};

function BudgetShareForm<T extends BudgetShareDTO>({
    state,
    setFieldValue,
    validationResult,
}: FormDataProps<T>) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormControl variant={"filled"} fullWidth>
                    <TextField
                        id="email"
                        type={"email"}
                        required
                        variant="filled"
                        label="Email"
                        defaultValue={state.email}
                        onChange={(e) => setFieldValue("email", e.target.value)}
                        error={!!validationResult?.errors?.email}
                        helperText={validationResult?.errors?.email}
                        autoFocus={true}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl variant={"filled"} fullWidth>
                    <TextField
                        id="recipientName"
                        required
                        variant="filled"
                        label="Recipient name"
                        defaultValue={state.recipientName}
                        onChange={(e) =>
                            setFieldValue("recipientName", e.target.value)
                        }
                        error={!!validationResult?.errors?.recipientName}
                        helperText={validationResult?.errors?.recipientName}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Dropdown
                        label="Permission"
                        fieldName={"permission"}
                        fieldValue={state.permission}
                        setFieldValue={setFieldValue}
                        validationResult={validationResult}
                        options={budgetSharePermissions}
                        returnValueOnly={true}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
}

const BudgetShareView = <T extends BudgetShareDTO[]>({
    state,
}: ViewDataProps<T>) => {
    return (
        <>
            <Grid container>
                <Grid item>
                    <Typography gutterBottom variant={"h4"}>
                        Budget sharing
                    </Typography>
                </Grid>
            </Grid>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <Container fixed>
                    <Typography variant={"h6"}>Incomes</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Permission</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {state.map((dto) => (
                                        <TableRow>
                                            <TableCell>{dto.id}</TableCell>
                                            <TableCell>{dto.email}</TableCell>
                                            <TableCell>
                                                {dto.permission}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </>
    );
};
