import { ValidationResult } from "../interfaces/ValidationResult";
import { UseMutateFunction } from "@tanstack/react-query";
import { useAppStateContext } from "../components/App";
import { useCallback, useEffect, useState } from "react";
import { useMutationQuery } from "./useMutationQuery";
import {
    setErrorNotice,
    setSuccessNotice,
} from "../reducers/AppActionCreators";

interface IUseFormStateResult<T> {
    state: T;
    setFieldValue: (key: string, value: any) => void;
    doSave: (
        mutationFunction: UseMutateFunction<Response, unknown, T>
    ) => () => void;
    validationResult: ValidationResult | undefined;
    resetState: () => void;
    validate: () => ValidationResult;
    isSaving: boolean;
    isSaved: boolean;
    mutationQuery: UseMutateFunction<Response, unknown, T>;
}

export function createValidationResult() {
    return { isValid: true, errors: {} };
}

interface IUseFormStateProps<T> {
    initialData: T;
    endPoint: string;
    cacheKey: string;
    successNotice: string;
    errorNotice?: string;
    validator?: (state: T) => Record<string, string> | undefined;
    onSuccess?: () => void;
    onError?: () => void;
    doResetStateOnSuccess?: boolean;
}

export function useFormState<T>({
    initialData,
    endPoint,
    cacheKey,
    successNotice,
    errorNotice,
    validator,
    onSuccess,
    onError,
    doResetStateOnSuccess = true,
}: IUseFormStateProps<T>): IUseFormStateResult<T> {
    const {
        state: { isProfileIncomplete, userAccessToken },
        dispatch,
    } = useAppStateContext();

    const {
        mutate,
        isPending: isLoading,
        isError,
        error,
        isSuccess,
        reset,
    } = useMutationQuery<T>(endPoint, cacheKey, userAccessToken);

    const [isValidated, setIsValidated] = useState<boolean>(false);
    const [isSaved, setIsSaved] = useState<boolean>(false);

    const [state, updateState] = useState<T>(initialData);
    const [validationResult, setValidationResult] = useState<
        ValidationResult | undefined
    >();

    const resetState = useCallback(() => {
        setValidationResult(undefined);
        updateState(initialData);
        console.log("State reset", initialData);
    }, [initialData]);

    function setFieldValue(name: string, value: any) {
        // If validation happened and a field value is updated, remove it from validation
        if (isValidated && validationResult) {
            const updatedErrors = { ...validationResult?.errors };
            delete updatedErrors[name];
            const newValidationResult = { ...validationResult };
            newValidationResult.errors = { ...updatedErrors };
            setValidationResult(newValidationResult);
        }
        updateState({ ...state, [name]: value });
    }

    function doSave(mutationFunction: UseMutateFunction<Response, unknown, T>) {
        return function () {
            if (validator) {
                if (validate().isValid) {
                    mutationFunction(state);
                    setIsValidated(false);
                }
            } else {
                mutationFunction(state);
                setIsValidated(false);
            }
            setIsSaved(true);
        };
    }

    function validate(): ValidationResult {
        let result = createValidationResult();
        if (validator) {
            const errors = validator(state);

            if (errors) {
                if (Object.keys(errors).length) {
                    result.isValid = false;
                }
                result.errors = { ...errors };
            }
        }
        setValidationResult(result);
        setIsValidated(true);
        return result;
    }

    useEffect(() => {
        if (isSuccess) {
            dispatch(setSuccessNotice(successNotice));
            onSuccess?.();
            doResetStateOnSuccess && resetState();
            reset();
        }

        if (isError) {
            const errorMessage = errorNotice ?? error?.message;
            console.error("useFormStateWithMutation", errorMessage);
            dispatch(setErrorNotice(errorMessage));
            onError?.();
            reset();
        }
    }, [
        isSuccess,
        isError,
        reset,
        isProfileIncomplete,
        dispatch,
        onSuccess,
        onError,
        doResetStateOnSuccess,
        error?.message,
        errorNotice,
        resetState,
        successNotice,
    ]);

    return {
        state,
        setFieldValue,
        doSave,
        validationResult,
        mutationQuery: mutate,
        isSaving: isLoading,
        resetState,
        validate,
        isSaved,
    };
}
