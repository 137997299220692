import { BaseBuilder } from "./BaseBuilder";
import { BudgetDTO } from "@fspringveldt/qf-budget-generated-api-types";

export class BudgetBuilder extends BaseBuilder<BudgetDTO> {
    constructor(
        protected model: BudgetDTO = {
            name: "",
            incomes: [],
            expenses: [],
            isOwned: true,
        }
    ) {
        super();
    }

    static empty(): BudgetDTO {
        return new BudgetBuilder().build();
    }

    build(): BudgetDTO {
        return this.model;
    }
}
