import React, { ReactElement, useEffect, useState } from "react";
import { Skeleton, Stack } from "@mui/material";
import { UserPreferencesForm } from "../forms/user/UserPreferencesForm";
import { useAppStateContext } from "../App";
import { MeDTO } from "@fspringveldt/qf-budget-generated-api-types";
import { MeBuilder } from "../../mocks/builders/MeBuilder";

/**
 * Either creates or update a user based on whether /user/me was found or not
 * @returns
 */
export function UserPreferences(): ReactElement {
    const {
        state: { userInfo, auth0User: user },
    } = useAppStateContext();
    const [displayUser, setDisplayUser] = useState<MeDTO | undefined>();
    useEffect(() => {
        const builder = new MeBuilder({
            defaultCountry: userInfo?.defaultCountry ?? "",
        });
        if (userInfo?.id) {
            builder.withId(userInfo.id);
        }
        setDisplayUser(builder.build());
    }, [userInfo?.defaultCountry, userInfo?.id]);
    return (
        <>
            {displayUser ? (
                <UserPreferencesForm
                    initialData={displayUser}
                    picture={user?.picture}
                />
            ) : (
                <Stack spacing={1}>
                    <Skeleton variant="text" />
                    <Skeleton variant="rectangular" width={96} height={96} />
                    <Skeleton variant="rectangular" height={56} />
                    <Skeleton variant="rectangular" height={56} />
                </Stack>
            )}
        </>
    );
}
