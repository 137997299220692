// API Endpoints
export const BUDGET_GET_ALL = `${process.env.REACT_APP_API_URL}/api/v1/budget/`;
export const BUDGET_SHARE_GET_ALL = `${process.env.REACT_APP_API_URL}/api/v1/budget/:budgetId/share/`;
export const BUDGET_SHARE_GET_ONE = `${process.env.REACT_APP_API_URL}/api/v1/budget/:budgetId/share/:budgetShareId`;
export const BUDGET_SHARE_ACCEPT = `${process.env.REACT_APP_API_URL}/api/v1/budget/:budgetId/share/:budgetShareId/accept/`;
export const BUDGET_SHARE_DECLINE = `${process.env.REACT_APP_API_URL}/api/v1/budget/:budgetId/share/:budgetShareId/decline/`;
export const BUDGET_GET_ONE = `${process.env.REACT_APP_API_URL}/api/v1/budget/:budgetId`;
export const EXPENSE_GET_ALL = `${BUDGET_GET_ALL}:budgetId/expenses/`;
export const INCOME_GET_ALL = `${BUDGET_GET_ALL}:budgetId/incomes/`;
export const EXPENSE_CATEGORY_GET_ALL = `${process.env.REACT_APP_API_URL}/api/v1/expense-category/`;
export const NON_WORKING_DAY_GET_ALL = `${process.env.REACT_APP_API_URL}/api/v1/non-working-day/`;
export const FUND_ACCOUNT_GET_ALL = `${process.env.REACT_APP_API_URL}/api/v1/fund-account/`;
export const DEBIT_GET_ALL = `${process.env.REACT_APP_API_URL}/api/v1/debit/`;
export const USER_GET_ALL = `${process.env.REACT_APP_API_URL}/api/v1/user/`;
export const USER_UPLOAD_BANK_CSV = `${process.env.REACT_APP_API_URL}/api/v1/user/upload-bank-csv`;
export const BUDGET_FORECAST = `${BUDGET_GET_ALL}:budgetId/forecast/:year/`;
export const BUDGET_TIMELINE_GET = `${BUDGET_GET_ALL}:budgetId/timeline/:year/:month/`;
export const USER_GET_ME = `${process.env.REACT_APP_API_URL}/api/v1/user/me/`;
export const BUDGET_GRAPH_MONTH = `${BUDGET_GET_ALL}:budgetId/graph-month/:year/:month/`;
export const BUDGET_GRAPH_YEAR = `${BUDGET_GET_ALL}:budgetId/user/graph-year/:year/`;
export const EXPENSE_BUDGET_DETAIL = `${BUDGET_GET_ALL}:budgetId/expenses/preview/:year/`;
export const INCOME_BUDGET_DETAIL = `${BUDGET_GET_ALL}:budgetId/incomes/preview/:year/`;
export const TAX_YEAR_GET_ALL = `${process.env.REACT_APP_API_URL}/api/v1/tax-rate/`;
export const TAX_TIER_GET_ALL = `${process.env.REACT_APP_API_URL}/api/v1/tax-tier/:taxYearId/`;
export const CONVERSION_RATES_GET_ALL = `${process.env.REACT_APP_API_URL}/api/v1/conversion-rate/`;
export const TAX_YEAR_GET_ONE = `${process.env.REACT_APP_API_URL}/api/v1/tax-rate/:taxYearId/`;

// Cache keys
export const BUDGET_CACHE_KEY = "budgetData";
export const BUDGET_SHARE_CACHE_KEY = "budgetShareData";
export const EXPENSE_CACHE_KEY = "expenseData";
export const EXPENSE_CATEGORY_CACHE_KEY = "expenseCategoryData";
export const NON_WORKING_DAY_CACHE_KEY = "nonWorkingDayData";
export const FUND_ACCOUNT_CACHE_KEY = "fundAccountData";
export const USER_CACHE_KEY = "userData";
export const INCOME_CACHE_KEY = "creditData";
export const INCOME_BUDGET_DETAIL_CACHE_KEY = "incomeBudgetDetailData";
export const EXPENSE_BUDGET_DETAIL_CACHE_KEY = "expenseBudgetDetailData";
export const USER_GRAPH_MONTH_CACHE_KEY = "userGraphMonthData";
export const USER_GRAPH_YEAR_CACHE_KEY = "userGraphYearData";
export const TIMELINE_CACHE_KEY = "timelineData";
export const TAX_YEAR_CACHE_KEY = "taxRateData";
export const TAX_TIER_CACHE_KEY = "taxTierData";
export const CONVERSION_RATE_CACHE_KEY = "conversionRateData";
export const TAX_YEAR_GET_ONE_CACHE_KEY = "taxYearGetOneData";

export const auth0config = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    scope: process.env.REACT_APP_AUTH0_SCOPE,
};
export const thisYear = new Date().getFullYear();
export const thisMonth = new Date().getMonth() + 1;
export const nextYear = thisYear + 1;
export const lastYear = thisYear - 1;
export const pivotYears = [thisYear, nextYear];
