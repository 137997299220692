import { TopMenu } from "../Menu/TopMenu";
import React from "react";
import { Container, Skeleton, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import { BreadCrumbs } from "../breadCrumbs/BreadCrumbs";
import "./Layout.css";
import { useAppStateContext } from "../App";
import { LoggedOutPage } from "./LoggedOutPage";

export function Layout() {
    const {
        state: { isUserReady, isAuthenticated, isAuthTokenRequested },
    } = useAppStateContext();

    return (
        <Container
            maxWidth={false}
            sx={{
                paddingLeft: 0,
                paddingRight: 0,
            }}
        >
            <TopMenu />

            {isUserReady ? (
                <>
                    <BreadCrumbs />
                    <Outlet />
                </>
            ) : (
                <>
                    {(isAuthenticated || isAuthTokenRequested) && (
                        <Stack spacing={1}>
                            <Skeleton variant="text" height={40} />
                            <Skeleton variant={"rectangular"} height={40} />
                            <Skeleton variant="rectangular" height={400} />
                        </Stack>
                    )}
                    {!isAuthenticated && <LoggedOutPage />}
                </>
            )}
        </Container>
    );
}
