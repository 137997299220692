import React from "react";
import {
    thisMonth,
    thisYear,
    TIMELINE_CACHE_KEY,
    BUDGET_TIMELINE_GET,
} from "../../constants";
import { TimelineDTO } from "models/ScheduleModel";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from "@mui/lab";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { Divider, Skeleton, Stack, Typography } from "@mui/material";
import { monthValues } from "../analytics/Analytics";
import { asCountriesByCurrenciesRecord } from "../../data/Countries";
import { asCurrenciesBySymbolRecord } from "../../data/Currencies";
import { useGetOneQuery } from "hooks/useGetOneQuery";
import { useAppStateContext } from "../App";
import { useParams } from "react-router-dom";

const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

const currencies = asCountriesByCurrenciesRecord();
const currencySymbols = asCurrenciesBySymbolRecord();

function includeSumOfAmounts(schedule: TimelineDTO): TimelineDTO {
    const result = { ...schedule };
    result.items.forEach((day) => {
        const dayTotals = day.items.reduce((acc, curr) => {
            const start = acc[curr.country] || 0;
            acc[curr.country] = start + curr.amount;
            return acc;
        }, {} as Record<string, number>);
        day.scheduleTotals = Object.keys(dayTotals).map((key) => ({
            country: key,
            sumOfAmounts: dayTotals[key],
        }));
    });

    return result;
}

interface Props {
    noHeader?: boolean;
}

export function Schedule({ noHeader }: Props) {
    const {
        state: { userAccessToken },
    } = useAppStateContext();
    const { budgetId = "", month = "", year = "" } = useParams<string>();

    const analyticsYear = parseInt(year) ?? thisYear;
    const analyticsMonth = parseInt(month) ?? thisMonth;

    const { data, isLoading } = useGetOneQuery<TimelineDTO>(
        BUDGET_TIMELINE_GET.replace(":budgetId", budgetId)
            .replace(":year", analyticsYear.toString())
            .replace(":month", analyticsMonth.toString()),
        TIMELINE_CACHE_KEY + analyticsMonth + analyticsYear,
        userAccessToken
    );

    return (
        <>
            {!isLoading && data ? (
                <>
                    {!noHeader && (
                        <Typography variant={"h4"}>
                            Schedule&nbsp;
                            {
                                monthValues.find(
                                    (v) => v.number === analyticsMonth
                                )?.name
                            }{" "}
                            - {analyticsYear}
                        </Typography>
                    )}
                    {data && (
                        <Timeline>
                            {includeSumOfAmounts(data)
                                .items.sort((a, b) => (a.day > b.day ? 1 : -1))
                                .map((item, index) => (
                                    <TimelineItem key={index}>
                                        <TimelineOppositeContent
                                            sx={{ m: "auto 0" }}
                                            align="right"
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            {item.day}&nbsp;
                                            <Typography variant={"caption"}>
                                                {
                                                    monthValues.find(
                                                        (v) =>
                                                            v.number ===
                                                            analyticsMonth
                                                    )?.name
                                                }
                                                ,&nbsp;
                                                {days[
                                                    new Date(
                                                        analyticsYear,
                                                        analyticsMonth - 1,
                                                        item.day
                                                    ).getDay()
                                                ].substring(0, 3)}
                                            </Typography>
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot variant={"outlined"}>
                                                <PointOfSaleIcon />
                                            </TimelineDot>
                                            {index < data.items.length - 1 && (
                                                <TimelineConnector />
                                            )}
                                        </TimelineSeparator>
                                        <TimelineContent
                                            sx={{ py: "12px", px: 2 }}
                                        >
                                            {item.scheduleTotals?.map(
                                                (sum, i) => (
                                                    <Typography
                                                        variant={"subtitle1"}
                                                        key={i}
                                                    >
                                                        {sum.country}:{" "}
                                                        {
                                                            currencySymbols[
                                                                currencies[
                                                                    sum.country
                                                                ]
                                                            ]
                                                        }
                                                        {sum.sumOfAmounts}
                                                    </Typography>
                                                )
                                            )}
                                            <Divider light />
                                            {item.items.map((expense, i) => (
                                                <Typography
                                                    key={i}
                                                    variant={"subtitle2"}
                                                >
                                                    {expense.name}&nbsp;
                                                    {
                                                        currencySymbols[
                                                            currencies[
                                                                expense.country
                                                            ]
                                                        ]
                                                    }
                                                    {expense.amount}
                                                </Typography>
                                            ))}
                                        </TimelineContent>
                                    </TimelineItem>
                                ))}
                        </Timeline>
                    )}
                </>
            ) : (
                <Stack spacing={1}>
                    <Skeleton variant="rectangular" width={153} height={36} />
                    <Skeleton variant="rectangular" height={400} />
                </Stack>
            )}
        </>
    );
}
