import {
    EXPENSE_CATEGORY_CACHE_KEY,
    EXPENSE_CATEGORY_GET_ALL,
} from "../constants";
import { FormControl, Grid, TableCell, TextField } from "@mui/material";
import { Model } from "../types/Model";
import { ExpenseCategory } from "@fspringveldt/qf-budget-generated-api-types";

export const ExpenseCategoryModel: Model<ExpenseCategory> = {
    cacheKey: EXPENSE_CATEGORY_CACHE_KEY,
    addURL: EXPENSE_CATEGORY_GET_ALL,
    editURL: EXPENSE_CATEGORY_GET_ALL,
    deleteURL: EXPENSE_CATEGORY_GET_ALL,
    columns: [
        {
            sortByField: "name",
            label: "Name",
        },
    ],
    label: "Expenses Category",
    render: ({ name }) => <TableCell>{name}</TableCell>,
    renderMutation: (row, setFieldValue, validationResult) => (
        <Grid container spacing={2}>
            <Grid item>
                <FormControl fullWidth>
                    <TextField
                        required
                        value={row.name}
                        onChange={(e) => setFieldValue("name", e.target.value)}
                        label="Category name"
                        variant="filled"
                        error={!!validationResult?.errors?.name}
                        helperText={validationResult?.errors?.name}
                        autoFocus={true}
                    />
                </FormControl>
            </Grid>
        </Grid>
    ),
};
