import {
    NON_WORKING_DAY_CACHE_KEY,
    NON_WORKING_DAY_GET_ALL,
} from "../../constants";
import { ModelListView } from "../views/ModelListView";
import { useGetListQuery } from "hooks/useGetListQuery";
import { useAppStateContext } from "../App";
import { NonWorkingDayModel } from "models/NonWorkingDayModel";
import { NonWorkingDayBuilder } from "../../mocks/builders/NonWorkingDayBuilder";
import { NonWorkingDayEntityDTO } from "@fspringveldt/qf-budget-generated-api-types";

export function NonWorkingDays(): React.ReactElement {
    const {
        state: { userAccessToken },
    } = useAppStateContext();

    const { error, data, isLoading } = useGetListQuery<NonWorkingDayEntityDTO>(
        NON_WORKING_DAY_GET_ALL,
        NON_WORKING_DAY_CACHE_KEY,
        userAccessToken
    );

    return (
        <ModelListView
            model={NonWorkingDayModel}
            dialogTitle={"Non-working day"}
            error={error}
            rows={data}
            initialData={NonWorkingDayBuilder.empty()}
            isLoading={isLoading}
        />
    );
}
