import { Grid, Typography } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import BarChartIcon from "@mui/icons-material/BarChart";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineIcon from "@mui/icons-material/Timeline";
import PivotTableChartIcon from "@mui/icons-material/PivotTableChart";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import CategoryIcon from "@mui/icons-material/Category";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const steps = [
    "Confirm your details",
    "Add your income sources",
    "Add all your expenses",
    "That's it",
];

export const LoggedOutPage = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    const slideshowHeight = matches ? "441px" : "219px";
    return (
        <>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    sx={{
                        mt: 2,
                        mb: 4,
                    }}
                >
                    <Typography variant={"h4"} align={"center"}>
                        Budgets made simple.
                    </Typography>
                    <Typography variant={"body1"} align={"center"}>
                        Just add your income and expenses and let QB generate
                        meaningful insights, keeping you on top of things.
                    </Typography>
                    <Typography variant={"body1"} align={"center"}>
                        Click login to continue.
                    </Typography>
                </Grid>
                <Grid
                    id={"dashboard-slider"}
                    sx={{ mt: 4, height: slideshowHeight }}
                    textAlign={"center"}
                    alignContent={"center"}
                    overflow={"hidden"}
                    xs={12}
                    lg={8}
                    item
                >
                    <img src={"/images/slider1.png"} alt={"dashboard slide"} />

                    <img src={"/images/slider2.png"} alt={"schedule slide"} />

                    <img src={"/images/slider3.png"} alt={"analytics slide"} />

                    <img
                        src={"/images/slider4.png"}
                        alt={"expense summary slide"}
                    />

                    <img src={"/images/slider5.png"} alt={"income slide"} />

                    <img src={"/images/slider6.png"} alt={"expenses slide"} />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        mt: 6,
                    }}
                >
                    <Typography variant={"h4"} align={"center"}>
                        How does it work?
                    </Typography>
                    <Typography variant={"body1"} align={"center"}>
                        Budgeting with QB is quick and easy. Follow the below
                        steps and you're off.
                    </Typography>
                    <Stepper activeStep={1} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        mt: 6,
                        pb: 10,
                    }}
                    style={{ textAlign: "center" }}
                >
                    <Typography variant={"h3"} align={"center"}>
                        Features
                    </Typography>
                    <Timeline position="alternate">
                        <TimelineItem>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot>
                                    <BarChartIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: "12px", px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Analytics
                                </Typography>
                                <Typography>
                                    View income and expense trends
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary">
                                    <TimelineIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: "12px", px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Schedule
                                </Typography>
                                <Typography>
                                    Know exactly when, what and how much is paid
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <PivotTableChartIcon />
                                </TimelineDot>
                                <TimelineConnector
                                    sx={{
                                        bgcolor: "secondary.main",
                                    }}
                                />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: "12px", px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Expense pivot
                                </Typography>
                                <Typography>
                                    Summarise both past and future expenses
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <CurrencyExchangeIcon />
                                </TimelineDot>
                                <TimelineConnector
                                    sx={{
                                        bgcolor: "secondary.main",
                                    }}
                                />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: "12px", px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Exchange rate conversions
                                </Typography>
                                <Typography>
                                    On the fly exchange rate conversions to and
                                    from your default country's currency.
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <CategoryIcon />
                                </TimelineDot>
                                <TimelineConnector
                                    sx={{
                                        bgcolor: "secondary.main",
                                    }}
                                />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: "12px", px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Custom categories
                                </Typography>
                                <Typography>
                                    Create custom expense categories and fund
                                    accounts. Expense pivot recognises these and
                                    reports them
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <TipsAndUpdatesIcon />
                                </TimelineDot>
                                <TimelineConnector
                                    sx={{
                                        bgcolor: "secondary.main",
                                    }}
                                />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: "12px", px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Updates
                                </Typography>
                                <Typography>
                                    QuiteFrankly Budget receives continuous
                                    updates and new features providing great
                                    value
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>
                </Grid>
            </Grid>
        </>
    );
};
