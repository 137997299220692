import { Frequency } from "../models/Frequency";

interface FrequencyInterface {
    label: string;
    value: Frequency;
}

export const frequencies: FrequencyInterface[] = [
    { label: "Once off", value: Frequency.ONCE_OFF },
    { label: "Weekly", value: Frequency.WEEKLY },
    { label: "Fortnightly", value: Frequency.FORTNIGHTLY },
    { label: "Monthly", value: Frequency.MONTHLY },
    { label: "Annually", value: Frequency.ANNUALLY },
];

export const incomeFrequencies: FrequencyInterface[] = [
    { label: "Once off", value: Frequency.ONCE_OFF },
    { label: "Hourly", value: Frequency.HOURLY },
    { label: "Weekly", value: Frequency.WEEKLY },
    { label: "Fortnightly", value: Frequency.FORTNIGHTLY },
    { label: "Monthly", value: Frequency.MONTHLY },
    { label: "Annually", value: Frequency.ANNUALLY },
];
