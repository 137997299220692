import { BaseBuilder } from "./BaseBuilder";
import { FundAccountEntityDTO } from "@fspringveldt/qf-budget-generated-api-types";

export class FundAccountBuilder extends BaseBuilder<FundAccountEntityDTO> {
    constructor(
        protected model: FundAccountEntityDTO = {
            name: "",
            isOwned: true,
        }
    ) {
        super();
    }

    static empty(): FundAccountEntityDTO {
        return new FundAccountBuilder().build();
    }

    build(): FundAccountEntityDTO {
        return this.model;
    }
}
