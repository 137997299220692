import {
    EXPENSE_CATEGORY_CACHE_KEY,
    EXPENSE_CATEGORY_GET_ALL,
} from "../../constants";
import { ModelListView } from "../views/ModelListView";
import { ExpenseCategoryModel } from "models/ExpenseCategoryModel";
import { useGetListQuery } from "hooks/useGetListQuery";
import { useAppStateContext } from "../App";
import { ValidatorResult } from "../../types/Model";
import { ExpenseCategory } from "@fspringveldt/qf-budget-generated-api-types";
import { ExpenseCategoryBuilder } from "../../mocks/builders/ExpenseCategoryBuilder";

export function ExpenseCategories(): React.ReactElement {
    const {
        state: { userAccessToken },
    } = useAppStateContext();

    const { error, data, isLoading } = useGetListQuery<ExpenseCategory>(
        EXPENSE_CATEGORY_GET_ALL,
        EXPENSE_CATEGORY_CACHE_KEY,
        userAccessToken
    );

    return (
        <ModelListView
            model={ExpenseCategoryModel}
            dialogTitle={"Expenses Category"}
            error={error}
            rows={data}
            initialData={ExpenseCategoryBuilder.empty()}
            isLoading={isLoading}
        />
    );
}

export function validator(state: ExpenseCategory): ValidatorResult {
    let errors: Record<string, string> = {};
    if (!state.name) {
        errors.name = "Name is required";
    }

    return errors;
}
