import {
    BUDGET_SHARE_ACCEPT,
    BUDGET_SHARE_CACHE_KEY,
    BUDGET_SHARE_DECLINE,
} from "../../constants";
import { useAppStateContext } from "../App";
import React, { ReactElement, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutationQuery } from "../../hooks/useMutationQuery";
import { Alert, Paper, Skeleton, Stack } from "@mui/material";

export function BudgetShareReply({
    accept,
}: {
    accept?: boolean;
}): ReactElement {
    const {
        state: { userAccessToken },
    } = useAppStateContext();

    const { budgetId = "", budgetShareId = "" } = useParams();

    const url = (accept ? BUDGET_SHARE_ACCEPT : BUDGET_SHARE_DECLINE)
        .replace(":budgetId", budgetId)
        .replace(":budgetShareId", budgetShareId);
    const successResponse = accept ? "accepted" : "declined";

    const {
        mutate,
        data,
        isPending: isLoading,
        isSuccess,
        isError,
    } = useMutationQuery(
        url,
        BUDGET_SHARE_CACHE_KEY + budgetId + budgetShareId,
        userAccessToken
    );

    useEffect(() => {
        mutate(undefined);
    }, [mutate]);

    console.log("url", url);
    console.log("data", data);
    console.log("isLoading", isLoading);
    console.log("isSuccess", isSuccess);
    console.log("isError", isError);

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            {!isLoading && isSuccess && (
                <Alert severity={"success"}>
                    You have {successResponse} the invitation.
                </Alert>
            )}
            {!isLoading && isError && (
                <Alert severity="error">
                    Something went wrong saving your response. Please try again.
                </Alert>
            )}
            {isLoading && (
                <Stack spacing={1}>
                    <Skeleton />
                </Stack>
            )}
        </Paper>
    );
}
