import { MeDTO } from "@fspringveldt/qf-budget-generated-api-types";
import { BaseBuilder } from "./BaseBuilder";

export class MeBuilder extends BaseBuilder<MeDTO> {
    constructor(
        protected model: MeDTO = {
            defaultCountry: "",
        }
    ) {
        super();
    }

    static empty(): MeDTO {
        return new MeBuilder().build();
    }

    withDefaultCountry(defaultCountry: string) {
        this.model.defaultCountry = defaultCountry;
        return this;
    }

    build(): MeDTO {
        return this.model;
    }
}
