import { ModelListView } from "components/views/ModelListView";
import { TaxYearModel } from "models/TaxYearModel";
import { useAppStateContext } from "components/App";
import { useGetListQuery } from "hooks/useGetListQuery";
import { TAX_YEAR_CACHE_KEY, TAX_YEAR_GET_ALL } from "../../constants";
import React from "react";
import { TaxYearEntityDTO } from "@fspringveldt/qf-budget-generated-api-types";
import { TaxYearBuilder } from "../../mocks/builders/TaxYearBuilder";

export const TaxYears = (): React.ReactElement => {
    const {
        state: { userAccessToken },
    } = useAppStateContext();

    const { error, data, isLoading } = useGetListQuery<TaxYearEntityDTO>(
        TAX_YEAR_GET_ALL,
        TAX_YEAR_CACHE_KEY,
        userAccessToken
    );
    return (
        <ModelListView
            model={TaxYearModel}
            error={error}
            rows={data}
            initialData={TaxYearBuilder.empty()}
            isLoading={isLoading}
        />
    );
};
