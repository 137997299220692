import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { ValidationResult } from "../../../interfaces/ValidationResult";

export interface DropdownFieldValue {
    label?: string;
    value?: string;
}

export interface DropdownResultValue {
    id?: string;
    name: string;
}

export interface DropdownProps {
    label?: string;
    fieldName: string;
    fieldValue?: DropdownResultValue | string;
    setFieldValue: (
        key: string,
        value: DropdownResultValue | string | undefined | DropdownResultValue[]
    ) => void;
    validationResult?: ValidationResult;
    options?: DropdownFieldValue[];
    returnValueOnly?: boolean;
    multiple?: boolean;
}

const isDropdownFieldValue = (obj: any): obj is DropdownFieldValue => {
    return obj && typeof obj === "object" && "label" in obj && "value" in obj;
};

export const isDropdownResultValue = (obj: any): obj is DropdownResultValue => {
    return obj && typeof obj === "object" && "name" in obj;
};

const isDropdownFieldValueArray = (obj: any): obj is DropdownFieldValue[] => {
    return Array.isArray(obj) && !obj.find((o) => !isDropdownFieldValue(o));
};

const fieldValueToResultValue = (
    value: DropdownFieldValue | DropdownFieldValue[]
): DropdownResultValue | DropdownResultValue[] => {
    if (isDropdownFieldValueArray(value)) {
        return value.map((i) => ({
            id: i?.value,
            name: i?.label || "",
        }));
    } else {
        return {
            id: value?.value,
            name: value?.label || "",
        };
    }
};

export function Dropdown(props: DropdownProps) {
    const {
        options,
        validationResult,
        fieldValue,
        setFieldValue,
        label,
        fieldName,
        returnValueOnly = false,
        multiple = false,
    } = props;

    if (!options) {
        return null;
    }

    const getValue = (): undefined | DropdownFieldValue | null | string => {
        if (typeof fieldValue === "string") {
            return options.find((option) => option.value === fieldValue);
        }
        if (fieldValue === null || fieldValue === undefined) {
            return fieldValue;
        }

        return {
            value: fieldValue?.id,
            label: fieldValue?.name,
        };
    };
    return (
        <Autocomplete
            id={fieldName}
            autoComplete={false}
            value={getValue()}
            multiple={multiple}
            renderInput={(params) => (
                <TextField
                    {...params}
                    aria-autocomplete="none"
                    autoComplete="off"
                    required
                    label={label}
                    variant={"filled"}
                    error={!!validationResult?.errors?.[fieldName]}
                    helperText={validationResult?.errors?.[fieldName]}
                />
            )}
            onChange={(_, newValue) => {
                if (returnValueOnly) {
                    if (typeof newValue === "string") {
                        setFieldValue(fieldName, newValue);
                        return;
                    }

                    if (isDropdownFieldValue(newValue)) {
                        setFieldValue(fieldName, newValue?.value);
                        return;
                    }
                }

                if (typeof newValue === "string") {
                    setFieldValue(fieldName, newValue);
                    return;
                }

                if (isDropdownFieldValue(newValue)) {
                    const value: DropdownResultValue = {
                        id: newValue?.value,
                        name: newValue?.label || "",
                    };
                    setFieldValue(fieldName, value);
                }

                if (isDropdownFieldValueArray(newValue)) {
                    setFieldValue(fieldName, fieldValueToResultValue(newValue));
                }
            }}
            options={options}
        />
    );
}
