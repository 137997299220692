import { BaseBuilder, BaseDTOType } from "./BaseBuilder";
import type { BudgetDTO } from "@fspringveldt/qf-budget-generated-api-types";

export interface BaseIncomeOrExpenseType extends BaseDTOType {
    name: string;
    isActive: boolean;
    startDate: string;
    endDate?: string;
    amount: number;
    country: string;
    budget?: BudgetDTO;
    active?: boolean;
}

export abstract class BaseIncomeOrExpenseBuilder<
    T extends BaseIncomeOrExpenseType
> extends BaseBuilder<T> {
    withAmount(amount: number) {
        this.model.amount = amount;
        return this;
    }

    withCountry(country: string) {
        this.model.country = country;
        return this;
    }

    withBudget(budget: BudgetDTO) {
        this.model.budget = budget;
        return this;
    }

    withActive(active: boolean) {
        this.model.active = active;
        return this;
    }

    withIsActive(isActive: boolean) {
        this.model.isActive = isActive;
        return this;
    }

    withName(name: string) {
        this.model.name = name;
        return this;
    }

    withStartDate(startDate: string) {
        this.model.startDate = startDate;
        return this;
    }

    withEndDate(endDate: string) {
        this.model.endDate = endDate;
        return this;
    }
}
