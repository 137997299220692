import "./Calendar.css";
import React from "react";
import { lastDayOfMonth } from "date-fns";
import { Grid } from "@mui/material";
import { CalendarDay } from "./CalendarDay";

export interface CalendarSettings {
    weekStartDay?: "Mon" | "Sun";
}

export interface CalendarDayItem {
    day: number;
    entries?: { name: string }[];
}

export interface CalendarProps extends CalendarSettings {
    month: number;
    year: number;
    budgetId: string;
    dailyEntries: CalendarDayItem[];
}

export type CalendarContainerProps = Pick<
    CalendarProps,
    "budgetId" | "month" | "year" | "weekStartDay"
>;

export const COL_WIDTH = 1.714285714285714;

export const Calendar = ({
    month,
    year,
    dailyEntries,
    weekStartDay = "Mon",
}: CalendarProps): React.ReactElement => {
    const startOfMonth = new Date(year, month - 1, 1);
    const calendarDays = Array.from(
        Array(lastDayOfMonth(startOfMonth).getDate()).keys()
    );
    const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    if (weekStartDay === "Mon") {
        weekDays.shift();
        weekDays.push("Sun");
    }

    const startOfMonthDay = startOfMonth.getDay();
    const padDay =
        weekStartDay === "Sun" || startOfMonthDay === 0
            ? startOfMonthDay
            : startOfMonthDay - 1;
    const padDays = Array.from(Array(padDay).keys());

    return (
        <Grid container spacing={1} className="calendar-container">
            <Grid container item>
                {weekDays.map((day) => (
                    <Grid
                        item
                        xs={COL_WIDTH}
                        key={day}
                        className="calendar-item calendar-heading"
                    >
                        {day}
                    </Grid>
                ))}
            </Grid>
            <Grid container item>
                {padDays.map((_, index) => (
                    <Grid
                        item
                        xs={COL_WIDTH}
                        key={index}
                        className="calendar-item"
                    />
                ))}
                {calendarDays.map((day) => (
                    <CalendarDay
                        key={day}
                        day={day + 1}
                        month={month}
                        year={year}
                        entries={
                            dailyEntries.find((v) => v.day === day + 1)?.entries
                        }
                    />
                ))}
            </Grid>
        </Grid>
    );
};
