import { MonthToDateBarChart } from "../charts/MonthToDateBarChart";
import { YearToDateBarChart } from "../charts/YearToDateBarChart";
import React from "react";
import { RecalculateButton } from "../RecalculateButton";
import { Grid } from "@mui/material";
import {
    thisMonth,
    thisYear,
    USER_GRAPH_MONTH_CACHE_KEY,
    USER_GRAPH_YEAR_CACHE_KEY,
} from "../../constants";
import { useParams } from "react-router-dom";

export interface MonthValue {
    name: string;
    number: number;
}

export const monthValues: MonthValue[] = [
    { name: "Jan", number: 1 },
    { name: "Feb", number: 2 },
    { name: "Mar", number: 3 },
    { name: "Apr", number: 4 },
    { name: "May", number: 5 },
    { name: "Jun", number: 6 },
    { name: "Jul", number: 7 },
    { name: "Aug", number: 8 },
    { name: "Sep", number: 9 },
    { name: "Oct", number: 10 },
    { name: "Nov", number: 11 },
    { name: "Dec", number: 12 },
];

interface Props {
    month?: number;
    noHeader?: boolean;
    year?: number;
}

export function Analytics({
    month: monthProp,
    year: yearProp,
    noHeader,
}: Props) {
    const { budgetId = "", month = "", year = "" } = useParams<string>();

    const analyticsYear = yearProp ?? parseInt(year) ?? thisYear;
    const analyticsMonth = monthProp ?? parseInt(month) ?? thisMonth;
    return (
        <Grid container>
            {!noHeader && (
                <Grid item xs={12}>
                    <RecalculateButton
                        refreshCacheKeys={[
                            USER_GRAPH_MONTH_CACHE_KEY +
                                analyticsMonth +
                                analyticsYear +
                                budgetId,
                            USER_GRAPH_YEAR_CACHE_KEY +
                                analyticsYear +
                                budgetId,
                            USER_GRAPH_YEAR_CACHE_KEY +
                                analyticsMonth +
                                budgetId,
                        ]}
                        year={year}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <h2>
                    Income vs Expenses&nbsp;
                    {
                        monthValues.find((v) => v.number === analyticsMonth)
                            ?.name
                    }{" "}
                    - {year}
                </h2>
                <MonthToDateBarChart
                    year={analyticsYear}
                    month={analyticsMonth}
                />
            </Grid>
            {!month && (
                <Grid item xs={12}>
                    <h2>Income vs Expenses - {analyticsYear}</h2>
                    <YearToDateBarChart year={analyticsYear} />
                </Grid>
            )}
        </Grid>
    );
}
