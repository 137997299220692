import { BUDGET_CACHE_KEY, BUDGET_GET_ALL } from "../../constants";
import { ModelListView } from "../views/ModelListView";
import { useGetListQuery } from "hooks/useGetListQuery";
import { useAppStateContext } from "../App";
import { ReactElement } from "react";
import { BudgetModel } from "../../models/BudgetModel";
import { BudgetDTO } from "@fspringveldt/qf-budget-generated-api-types";
import { BudgetBuilder } from "../../mocks/builders/BudgetBuilder";

export function Budgets(): ReactElement {
    const {
        state: { userAccessToken },
    } = useAppStateContext();

    const { error, data, isLoading } = useGetListQuery<BudgetDTO>(
        BUDGET_GET_ALL,
        BUDGET_CACHE_KEY,
        userAccessToken
    );

    return (
        <ModelListView
            model={BudgetModel}
            dialogTitle={"Budget"}
            rows={data}
            error={error}
            initialData={BudgetBuilder.empty()}
            isLoading={isLoading}
        />
    );
}
