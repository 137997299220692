import UpdateIcon from "@mui/icons-material/Update";
import React, { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { useProcessQuery } from "hooks/useProcessQuery";
import { useAppStateContext } from "./App";
import { setErrorNotice, setSuccessNotice } from "reducers/AppActionCreators";
import { BUDGET_FORECAST } from "../constants";
import { useParams } from "react-router-dom";
import { CircularProgress, IconButton } from "@mui/material";

interface Props {
    year: number | string;
    refreshCacheKeys: string[];
    showTitle?: boolean;
}

export function RecalculateButton(props: Props) {
    const {
        state: { userAccessToken },
        dispatch,
    } = useAppStateContext();
    const { year, refreshCacheKeys, showTitle = true } = props;
    const { budgetId = "" } = useParams<string>();

    const {
        isError,
        reset,
        isSuccess,
        mutate: mutateThisYear,
        isPending: isLoading,
    } = useProcessQuery(
        BUDGET_FORECAST.replace(":budgetId", budgetId).replace(
            ":year",
            year.toString()
        ),
        refreshCacheKeys,
        userAccessToken
    );
    useEffect(() => {
        if (isError) {
            dispatch(
                setErrorNotice("Something went wrong while refreshing expenses")
            );
            reset();
        }
        if (isSuccess) {
            dispatch(setSuccessNotice("Expenses refreshed!"));
            reset();
        }
    }, [isSuccess, reset, isError, dispatch]);

    const doProcessThisYear = () => {
        mutateThisYear({ id: "" });
    };

    if (!showTitle) {
        if (isLoading) {
            return <CircularProgress />;
        }
        return (
            <IconButton onClick={doProcessThisYear} title={"Refresh " + year}>
                <UpdateIcon />
            </IconButton>
        );
    }

    return (
        <LoadingButton
            variant="outlined"
            onClick={doProcessThisYear}
            title={"Refresh " + year}
            endIcon={<UpdateIcon />}
            loading={isLoading}
            loadingPosition="end"
        >
            {"Refresh " + year}
        </LoadingButton>
    );
}
