import React from "react";
import {
    FormControl,
    Grid,
    InputAdornment,
    Switch,
    TextField,
} from "@mui/material";
import { FormDataProps } from "./FormDataProps";
import { incomeFrequencies } from "data/Frequencies";
import { CountrySelector } from "../CountrySelector";
import { Calendar } from "./calendar/Calendar";
import { Dropdown } from "./dropdown/Dropdown";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Frequency } from "models/Frequency";
import { AmountField } from "../AmountField";
import { sanitiseDate } from "../../utils/utils";
import { IncomeEntityDTO } from "@fspringveldt/qf-budget-generated-api-types";

export function IncomeForm<T extends IncomeEntityDTO>({
    state,
    setFieldValue,
    validationResult,
}: FormDataProps<T>) {
    const showWithholdingTax =
        state.withholdingTaxPercent !== undefined &&
        state.withholdingTaxPercent !== null;
    const showGST = state.gstPercent !== undefined && state.gstPercent !== null;

    return (
        <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <CountrySelector
                        fieldName={"country"}
                        fieldValue={state.country}
                        setFieldValue={setFieldValue}
                        validationResult={validationResult}
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} sm={12}>
                <FormControl fullWidth>
                    <TextField
                        fullWidth
                        required
                        value={state.name}
                        id="Name"
                        name={"Name"}
                        onChange={(e) => setFieldValue("name", e.target.value)}
                        label="Name"
                        variant="filled"
                        error={!!validationResult?.errors?.name}
                        helperText={validationResult?.errors?.name}
                        autoFocus={true}
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} sm={12}>
                <FormControl fullWidth>
                    <AmountField
                        country={state.country}
                        changeHandler={(e) =>
                            setFieldValue("amount", parseFloat(e.target.value))
                        }
                        error={!!validationResult?.errors?.amount}
                        helperText={validationResult?.errors?.amount}
                        defaultValue={state.amount}
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <Dropdown
                        label="Frequency"
                        fieldName={"frequency"}
                        fieldValue={state.frequency}
                        setFieldValue={setFieldValue}
                        validationResult={validationResult}
                        options={incomeFrequencies}
                        returnValueOnly={true}
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <Calendar
                        label="Start date"
                        fieldName={"startDate"}
                        fieldValue={sanitiseDate(state.startDate)}
                        setFieldValue={setFieldValue}
                        validationResult={validationResult}
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <Calendar
                        label="End date"
                        fieldName={"endDate"}
                        fieldValue={sanitiseDate(state.endDate)}
                        setFieldValue={setFieldValue}
                        validationResult={validationResult}
                    />
                </FormControl>
            </Grid>
            {state.frequency === Frequency.HOURLY && (
                <Grid item md={6} xs={12}>
                    <TextField
                        autoComplete="off"
                        id="hoursPerDay"
                        name={"hoursPerDay"}
                        required={state.frequency === Frequency.HOURLY}
                        variant="filled"
                        label="Hours per day"
                        type={"number"}
                        fullWidth
                        onChange={(e) =>
                            setFieldValue(
                                "hoursPerDay",
                                parseFloat(e.target.value)
                            )
                        }
                        inputProps={{
                            defaultValue: state.hoursPerDay,
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                        }}
                        error={!!validationResult?.errors?.hoursPerDay}
                        helperText={validationResult?.errors?.hoursPerDay}
                    />
                </Grid>
            )}
            <Grid item md={6} xs={12}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={state.isActive}
                            onChange={(e) =>
                                setFieldValue("isActive", e.target.checked)
                            }
                        />
                    }
                    label={"Is active"}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={showGST}
                            onChange={(e) => {
                                const value = e.target.checked ? 0 : undefined;
                                setFieldValue("gstPercent", value);
                            }}
                        />
                    }
                    label={"GST?"}
                />
            </Grid>
            {showGST && (
                <Grid item md={6} xs={12}>
                    <TextField
                        autoComplete="off"
                        id="gstPercent"
                        name={"gstPercent"}
                        required={showGST}
                        variant="filled"
                        label="GST percent"
                        type={"number"}
                        fullWidth
                        onChange={(e) =>
                            setFieldValue(
                                "gstPercent",
                                parseFloat(e.target.value)
                            )
                        }
                        inputProps={{
                            defaultValue: state.gstPercent,
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    %
                                </InputAdornment>
                            ),
                        }}
                        error={!!validationResult?.errors?.gstPercent}
                        helperText={validationResult?.errors?.gstPercent}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={showWithholdingTax}
                            onChange={(e) => {
                                const value = e.target.checked ? 0 : undefined;
                                setFieldValue("withholdingTaxPercent", value);
                            }}
                        />
                    }
                    label={"Add withholding tax?"}
                />
            </Grid>
            {showWithholdingTax && (
                <Grid item md={6} xs={12}>
                    <TextField
                        autoComplete="off"
                        id="withholdingTaxPercent"
                        name={"withholdingTaxPercent"}
                        required={showWithholdingTax}
                        variant="filled"
                        label="Withholding tax %"
                        type={"number"}
                        fullWidth
                        onChange={(e) =>
                            setFieldValue(
                                "withholdingTaxPercent",
                                parseFloat(e.target.value)
                            )
                        }
                        inputProps={{
                            defaultValue: state.withholdingTaxPercent,
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    %
                                </InputAdornment>
                            ),
                        }}
                        error={
                            !!validationResult?.errors?.withholdingTaxPercent
                        }
                        helperText={
                            validationResult?.errors?.withholdingTaxPercent
                        }
                    />
                </Grid>
            )}
        </Grid>
    );
}
