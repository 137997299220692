import {
    EXPENSE_BUDGET_DETAIL,
    EXPENSE_BUDGET_DETAIL_CACHE_KEY,
    pivotYears,
    thisMonth,
    thisYear,
} from "../../constants";
import { useParams } from "react-router-dom";
import { useAppStateContext } from "../App";
import { useGetOneQuery } from "hooks/useGetOneQuery";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { RecalculateButton } from "../RecalculateButton";
import {
    Dropdown,
    DropdownFieldValue,
    isDropdownResultValue,
} from "../forms/dropdown/Dropdown";
import {
    MonthTotal,
    MonthTotalMap,
    PivotData,
} from "../../models/PivotTableModel";
import { monthValues } from "../analytics/Analytics";
import { ExpenseBudgetMonthDetail } from "./ExpenseBudgetMonthDetail";
import CancelIcon from "@mui/icons-material/Cancel";
import {
    DebitEntityDTO,
    YearPreviewDTODebitEntityDTO,
} from "@fspringveldt/qf-budget-generated-api-types";

enum GROUPS {
    FUND_ACCOUNT = "fundAccount",
    EXPENSE_NAME = "name",
    CATEGORY = "category",
}

const groupByOptions: DropdownFieldValue[] = [
    { label: "Fund account", value: GROUPS.FUND_ACCOUNT },
    { label: "Expenses name", value: GROUPS.EXPENSE_NAME },
    { label: "Category", value: GROUPS.CATEGORY },
];

interface Props {
    month?: number;
    noHeader?: boolean;
}

export const ExpenseBudgetDetail = ({ month, noHeader }: Props) => {
    const { year = thisYear } = useParams();
    const [groupDataBy, setGroupDataBy] = useState<DropdownFieldValue>(
        groupByOptions[0]
    );
    const [pivotData, setPivotData] = useState<PivotData | undefined>(
        undefined
    );
    const targetYear = parseInt(String(year), 10) || thisYear;
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [dialogDetail, setDialogDetail] = useState<MonthTotal | undefined>(
        undefined
    );
    const {
        state: { userAccessToken },
    } = useAppStateContext();
    const { budgetId = "" } = useParams<string>();

    const cacheQueryKey = EXPENSE_BUDGET_DETAIL_CACHE_KEY + budgetId + year;

    const { isLoading, data } = useGetOneQuery<YearPreviewDTODebitEntityDTO>(
        EXPENSE_BUDGET_DETAIL.replace(":budgetId", budgetId).replace(
            ":year",
            year.toString()
        ),
        cacheQueryKey,
        userAccessToken,
        {
            enabled: !!userAccessToken && pivotYears.includes(targetYear),
        }
    );
    let monthsToShow = !month ? Array.from(Array(12).keys()) : [month - 1];
    const closeDialog = () => setOpenDialog(false);

    const buildAccumulator = (
        name: string,
        acc: MonthTotalMap[],
        curr: DebitEntityDTO
    ) => {
        if (!curr.expense) {
            return;
        }
        const foundElement = acc.find((el) => el.name === name);
        const item: MonthTotal = {
            month: curr.month,
            amount: curr.amount,
            debitDetails: [],
        };
        if (foundElement) {
            const foundItem = foundElement.items.find(
                (el) => el.month === curr.month
            );
            if (foundItem) {
                foundItem.amount += curr.amount;
                foundItem.debitDetails.push({
                    name: curr.expense.name,
                    amount: curr.amount,
                });
            } else {
                item.debitDetails.push({
                    name: curr.expense.name,
                    amount: curr.amount,
                });
                foundElement.items.push(item);
            }
        } else {
            item.debitDetails.push({
                name: curr.expense.name,
                amount: curr.amount,
            });
            acc.push({ name, items: [item] });
        }
    };

    useEffect(() => {
        if (data) {
            let rows: MonthTotalMap[] = [];
            let label: string = "";
            if (groupDataBy.value === GROUPS.FUND_ACCOUNT) {
                label = "Expenses by fund account";
                rows = data?.items.reduce((acc, curr) => {
                    const name: string | undefined =
                        curr.expense?.fundAccount?.name;
                    name && buildAccumulator(name, acc, curr);
                    return acc;
                }, [] as MonthTotalMap[]);
            }

            if (groupDataBy.value === GROUPS.EXPENSE_NAME) {
                label = "Expenses by expense name";
                rows = data?.items.reduce((acc, curr) => {
                    const name: string | undefined = curr.expense?.name;
                    name && buildAccumulator(name, acc, curr);
                    return acc;
                }, [] as MonthTotalMap[]);
            }

            if (groupDataBy.value === GROUPS.CATEGORY) {
                label = "Expenses by expense category";
                rows = data?.items.reduce((acc, curr) => {
                    const name: string | undefined =
                        curr.expense?.expenseCategory?.name;
                    name && buildAccumulator(name, acc, curr);
                    return acc;
                }, [] as MonthTotalMap[]);
            }

            let columnLabels: string[] = monthValues.map((el) => el.name);

            if (month) {
                rows.forEach((i) => {
                    i.items = i.items.filter((j) => j.month === month);
                });
                columnLabels = monthValues
                    .filter((i) => i.number === month)
                    .map((el) => el.name);
            }

            setPivotData({
                label,
                rows,
                columnLabels,
            });
        }
    }, [groupDataBy, data, month]);

    const handleExpenseGroupItemClick =
        (expenseGroupItem: MonthTotalMap, month: number) => () => {
            const dialogDetail = expenseGroupItem.items.find(
                (el) => el.month === month + 1
            );
            if (dialogDetail) {
                dialogDetail.expenseGroupName = expenseGroupItem.name;
                setDialogDetail(dialogDetail);
                setOpenDialog(true);
            }
        };

    return (
        <>
            {!isLoading && pivotData ? (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <RecalculateButton
                                refreshCacheKeys={[cacheQueryKey]}
                                year={year}
                                showTitle={!noHeader}
                            />
                        </Grid>
                        <Grid item xs={12} md={month ? 12 : 3}>
                            <Dropdown
                                label="Group data by"
                                fieldName={"groupDataBy"}
                                setFieldValue={(key, value) => {
                                    if (isDropdownResultValue(value)) {
                                        setGroupDataBy({
                                            label: value.name,
                                            value: value?.id,
                                        });
                                    }
                                }}
                                options={groupByOptions}
                                fieldValue={{
                                    id: groupDataBy.value,
                                    name: groupDataBy.label || "",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {!noHeader && (
                                <h2>
                                    {pivotData.label} - {year}
                                </h2>
                            )}
                            <TableContainer component={Paper}>
                                <Table stickyHeader aria-label="data table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>&nbsp;</TableCell>
                                            {pivotData.columnLabels.map(
                                                (el) => (
                                                    <TableCell
                                                        key={el}
                                                        className={
                                                            monthValues.find(
                                                                (v) =>
                                                                    v.number ===
                                                                    thisMonth
                                                            )?.name === el &&
                                                            !noHeader
                                                                ? "currMonth"
                                                                : ""
                                                        }
                                                    >
                                                        {el}
                                                    </TableCell>
                                                )
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!pivotData.rows.length && (
                                            <TableRow>
                                                <TableCell colSpan={13}>
                                                    No expenses found.
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {pivotData.rows.map(
                                            (expenseGroupItem) => (
                                                <TableRow
                                                    key={expenseGroupItem.name}
                                                >
                                                    <TableCell>
                                                        {expenseGroupItem.name}
                                                    </TableCell>
                                                    {monthsToShow.map((i) => (
                                                        <TableCell
                                                            key={i}
                                                            className={
                                                                i + 1 ===
                                                                    thisMonth &&
                                                                !noHeader
                                                                    ? "currMonth"
                                                                    : ""
                                                            }
                                                        >
                                                            <Button
                                                                onClick={handleExpenseGroupItemClick(
                                                                    expenseGroupItem,
                                                                    i
                                                                )}
                                                            >
                                                                {expenseGroupItem.items
                                                                    .find(
                                                                        (el) =>
                                                                            el.month ===
                                                                            i +
                                                                                1
                                                                    )
                                                                    ?.amount.toFixed(
                                                                        0
                                                                    ) || 0}
                                                            </Button>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>

                    {dialogDetail && (
                        <Dialog open={openDialog} onClose={closeDialog}>
                            <DialogTitle>
                                {dialogDetail.expenseGroupName}{" "}
                                {dialogDetail.month}-{year}:{" "}
                                {dialogDetail.amount.toFixed(0)}
                            </DialogTitle>
                            <DialogContent>
                                <ExpenseBudgetMonthDetail
                                    monthTotal={dialogDetail}
                                    year={year}
                                />
                            </DialogContent>
                            <DialogActions>
                                <IconButton
                                    onClick={closeDialog}
                                    color="secondary"
                                    aria-label="Close dialog"
                                >
                                    <CancelIcon />
                                </IconButton>
                            </DialogActions>
                        </Dialog>
                    )}
                </>
            ) : (
                <Stack spacing={1}>
                    <Skeleton variant="rectangular" width={153} height={36} />
                    <Skeleton variant="rectangular" height={400} />
                </Stack>
            )}
        </>
    );
};
