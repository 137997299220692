import { useAppStateContext } from "../App";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import React from "react";
import { Alert } from "@mui/material";
import { checkUserRoles } from "../../utils/checkUserRoles";

interface Props {
    roles?: string[];
}

/**
 * Secures routes that needs a logged in user.
 * Also accepts an optional roles prop that gets checked against the logged
 * in user - giving an unauthorised message when the role is not present..
 * @constructor
 */
export function SecureRoute({ roles }: Props) {
    const {
        state: { isUserReady, userAccessToken /*, isProfileIncomplete*/ },
    } = useAppStateContext();
    const location = useLocation();
    const isAllowed = checkUserRoles(userAccessToken, roles);

    if (!isUserReady) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/" state={{ from: location }} replace={true} />;
    }

    // if (
    //     isProfileIncomplete &&
    //     ![
    //         navDefinitions.firstTimeSetup.path,
    //         navDefinitions.firstTimeSetupStep2.path,
    //         navDefinitions.firstTimeSetupStep3.path,
    //     ].includes(location.pathname)
    // ) {
    //     // Redirect them to the first time setup to add select country, add first income + expense
    //     return (
    //         <Navigate to={navDefinitions.firstTimeSetup.path} replace={true} />
    //     );
    // }

    if (!isAllowed) {
        return <Alert severity="error">Unauthorised</Alert>;
    }

    return <Outlet />;
}
