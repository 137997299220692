import {
    useMutation,
    UseMutationResult,
    useQueryClient,
} from "@tanstack/react-query";
import { addBearerTokenHeader, headers } from "./queryHookUtils";

export const handleUseDeleteQuery =
    (url: string, userAccessToken: string) => async (ids: string[]) => {
        const response = await fetch(`${url}${ids.join(",")}/`, {
            method: "DELETE",
            headers: addBearerTokenHeader(userAccessToken, headers),
        });

        if (response.status !== 200) {
            throw new Error(`Delete failed for IDs: ${JSON.stringify(ids)}`);
        }

        return response;
    };

export function useDeleteQuery(
    url: string,
    queryKeyToInvalidate: string,
    userAccessToken: string
): UseMutationResult<Response, Error, string[]> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: handleUseDeleteQuery(url, userAccessToken),
        onSuccess: () => {
            queryClient
                .invalidateQueries({ queryKey: [queryKeyToInvalidate] })
                .then();
        },
    });
}
