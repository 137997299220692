export enum AppActionTypes {
    SET_SUCCESS_NOTICE,
    SET_ERROR_NOTICE,
    SET_IS_PROFILE_INCOMPLETE,
    SET_IS_USER_READY,
    SET_USER_INFO,
    SET_AUTHO_USER,
    SET_AUTHO_ERROR,
    REQUEST_LOGIN,
    LOGOUT,
    LOGIN,
}
