import { FUND_ACCOUNT_CACHE_KEY, FUND_ACCOUNT_GET_ALL } from "../constants";
import { Grid, TableCell, TextField } from "@mui/material";
import { Model, ValidatorResult } from "../types/Model";
import { FundAccount } from "@fspringveldt/qf-budget-generated-api-types";

export const FundAccountModel: Model<FundAccount> = {
    cacheKey: FUND_ACCOUNT_CACHE_KEY,
    columns: [{ sortByField: "name", label: "Name" }],
    label: "Fund Accounts",
    addURL: FUND_ACCOUNT_GET_ALL,
    editURL: FUND_ACCOUNT_GET_ALL,
    deleteURL: FUND_ACCOUNT_GET_ALL,
    validator,
    render: ({ name }) => <TableCell>{name}</TableCell>,
    renderMutation: (row, setFieldValue, validationResult) => (
        <Grid container spacing={2}>
            <Grid item>
                <TextField
                    required
                    defaultValue={row.name}
                    onChange={(e) => setFieldValue("name", e.target.value)}
                    label="Fund account name"
                    variant="filled"
                    error={!!validationResult?.errors?.name}
                    helperText={validationResult?.errors?.name}
                    autoFocus={true}
                />
            </Grid>
        </Grid>
    ),
};

export function validator(state: FundAccount): ValidatorResult {
    let errors: Record<string, string> = {};
    if (!state.name) {
        errors.name = "Name is required";
    }

    return errors;
}
