import produce from "immer";
import { AppActionTypes } from "./AppActionTypes";
import { AppReducerActions } from "./AppActionCreators";
import { AppState } from "../types/AppState";

export function AppReducer(
    state: AppState,
    action: AppReducerActions
): AppState {
    return produce(state, (draft) => {
        switch (action.type) {
            case AppActionTypes.LOGIN:
                draft.userAccessToken = action.token;
                draft.isAuthenticated = !!action.token;
                draft.isAuthTokenRequested = false;
                break;
            case AppActionTypes.LOGOUT:
                draft.userAccessToken = "";
                break;
            case AppActionTypes.REQUEST_LOGIN:
                draft.isAuthTokenRequested = true;
                break;
            case AppActionTypes.SET_AUTHO_ERROR:
                draft.isAuth0Error = action.payload;
                draft.userAccessToken = "";
                break;
            case AppActionTypes.SET_AUTHO_USER:
                draft.auth0User = action.payload;
                break;
            case AppActionTypes.SET_USER_INFO:
                draft.userInfo = action.payload;
                break;
            case AppActionTypes.SET_IS_USER_READY:
                draft.isUserReady = action.payload;
                break;
            case AppActionTypes.SET_IS_PROFILE_INCOMPLETE:
                draft.isProfileIncomplete = action.payload;
                break;
            case AppActionTypes.SET_ERROR_NOTICE:
                draft.errorNotice = action.payload;
                break;
            case AppActionTypes.SET_SUCCESS_NOTICE:
                draft.successNotice = action.payload;
                break;
            default:
                return state;
        }
    });
}
