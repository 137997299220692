import React, { ReactNode, useState } from "react";
import {
    Alert,
    Button,
    Grid,
    Paper,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { SortDirection, useDataSort } from "hooks/useDataSort";
import { TablePaginationActions } from "../TablePaginationActions";
import { useDataDelete, UseDataDeleteResult } from "../../hooks/useDataDelete";
import { Model } from "../../types/Model";
import { BaseDTOType } from "../../mocks/builders/BaseBuilder";
import { ModelAddDialog } from "./ModelAddDialog";
import { ModelEditDialog } from "./ModelEditDialog";

interface Props {
    children?: React.ReactNode;
}

export interface ModelViewProps<TObject> extends Props {
    model: Model<TObject>;
    error: Error | null;
    rows: TObject[] | undefined;
    initialData: TObject;
    rowsPerPage?: number;
    dialogTitle?: string;
    isLoading: boolean;
}

export function ModelListView<TObject extends BaseDTOType>(
    props: ModelViewProps<TObject>
) {
    const {
        initialData,
        error,
        rows,
        dialogTitle = "Item",
        model,
        isLoading = true,
    } = props;

    const { cacheKey, validator, addURL, deleteURL } = model;

    const { selected, SelectAllButton, RowSelectButton, ToolbarButton } =
        useDataDelete(deleteURL!, cacheKey, rows);

    if (!isLoading && error) {
        return (
            <Alert severity="error">
                Something went wrong while fetching your data.
            </Alert>
        );
    }

    return (
        <>
            <Grid container>
                <Grid item xs={2}>
                    {model.renderMutation && addURL && (
                        <ModelAddDialog
                            render={model.renderMutation}
                            queryKey={cacheKey}
                            postURL={addURL}
                            initialData={initialData}
                            validator={validator}
                            dialogTitle={dialogTitle}
                        />
                    )}
                </Grid>
                <Grid item xs={10}>
                    <ToolbarButton />
                </Grid>
            </Grid>

            {props.children}
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <ModelListViewTable
                    model={model}
                    rows={rows}
                    isLoading={isLoading}
                    dialogTitle={dialogTitle}
                    selected={selected}
                    SelectAllButton={SelectAllButton}
                    RowSelectButton={RowSelectButton}
                />
            </Paper>
        </>
    );
}

export const displayNoneXs = { display: { xs: "none", sm: "table-cell" } };
export const displayXs = { display: { xs: "table-cell", sm: "none" } };

export interface IModelListViewTableColumn {
    label: string;
    sortByField?: string;
}

interface ModelListViewTableProps<T extends BaseDTOType>
    extends Pick<
            ModelViewProps<T>,
            "rowsPerPage" | "model" | "rows" | "isLoading" | "dialogTitle"
        >,
        Pick<
            UseDataDeleteResult,
            "selected" | "RowSelectButton" | "SelectAllButton"
        > {}

export const ModelListViewTable = <T extends BaseDTOType>(
    props: ModelListViewTableProps<T>
) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        props.rowsPerPage || 50
    );

    const {
        model,
        rows,
        isLoading,
        selected,
        RowSelectButton,
        SelectAllButton,
        dialogTitle = "Item",
    } = props;

    const { cacheKey, validator, label, editURL, columns } = model;

    const { sortDirection, sortField, sortedRows, doSetSortColumn } =
        useDataSort(rows);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (key: string) => selected?.indexOf(key) !== -1;
    const fieldLabelHash = encodeURI(label);

    return (
        <TableContainer component={Paper}>
            <Table
                stickyHeader
                sx={{ md: { minWidth: 650 } }}
                aria-label="data table"
            >
                <TableHead>
                    <TableRow>
                        <SelectAllButton />
                        {editURL && (
                            <TableCell
                                sx={displayNoneXs}
                                padding={"checkbox"}
                            />
                        )}
                        {columns.map(({ sortByField, label }, key) => (
                            <React.Fragment key={key}>
                                {!sortByField && (
                                    <TableCell
                                        sx={displayNoneXs}
                                        key={`${label}-heading-${key}`}
                                    >
                                        {label}
                                    </TableCell>
                                )}
                                {sortByField && (
                                    <TableCell
                                        sx={displayNoneXs}
                                        onClick={doSetSortColumn(sortByField)}
                                        key={`${label}-heading-${key}`}
                                    >
                                        {(!sortField ||
                                            (sortField &&
                                                sortField !== sortByField)) && (
                                            <Button>{label}</Button>
                                        )}
                                        {sortField &&
                                            sortField === sortByField &&
                                            sortDirection ===
                                                SortDirection.Ascending && (
                                                <Button
                                                    endIcon={
                                                        <ArrowDownwardIcon />
                                                    }
                                                >
                                                    {label}
                                                </Button>
                                            )}
                                        {sortField &&
                                            sortField === sortByField &&
                                            sortDirection ===
                                                SortDirection.Descending && (
                                                <Button
                                                    endIcon={
                                                        <ArrowUpwardIcon />
                                                    }
                                                >
                                                    {label}
                                                </Button>
                                            )}
                                    </TableCell>
                                )}
                            </React.Fragment>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <>
                        {isLoading && (
                            <TableRow>
                                <TableCell colSpan={columns.length + 2}>
                                    <Stack spacing={1}>
                                        <Skeleton />
                                        <Skeleton />
                                        <Skeleton />
                                        <Skeleton />
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        )}
                        {!isLoading &&
                            sortedRows &&
                            (rowsPerPage > 0
                                ? sortedRows?.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                  )
                                : sortedRows
                            )?.reduce(
                                (
                                    result: React.ReactElement[],
                                    row,
                                    rowNumber
                                ) => {
                                    const { id } = row;
                                    if (id !== undefined) {
                                        const isItemSelected = isSelected(id);
                                        result.push(
                                            <TableRow
                                                key={fieldLabelHash + id}
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                selected={isItemSelected}
                                            >
                                                <RowSelectButton
                                                    id={id}
                                                    rowNumber={rowNumber}
                                                />
                                                {model.renderMutation &&
                                                    editURL && (
                                                        <TableCell padding="checkbox">
                                                            <ModelEditDialog
                                                                render={
                                                                    model.renderMutation
                                                                }
                                                                queryKey={
                                                                    cacheKey
                                                                }
                                                                postURL={`${editURL}${row.id}/`}
                                                                initialData={
                                                                    row
                                                                }
                                                                validator={
                                                                    validator
                                                                }
                                                                dialogTitle={
                                                                    dialogTitle
                                                                }
                                                            />
                                                        </TableCell>
                                                    )}
                                                {model.render(row)}
                                            </TableRow>
                                        );
                                    }

                                    return result;
                                },
                                []
                            )}
                    </>
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[
                                5,
                                10,
                                25,
                                50,
                                100,
                                { label: "All", value: -1 },
                            ]}
                            colSpan={columns.length + 2}
                            count={sortedRows?.length || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    "aria-label": "rows per page",
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

interface ModelListViewSMTableCellProps {
    heading: ReactNode;
    info: ReactNode[];
}

export const ModelListViewSMTableCell = ({
    heading,
    info,
}: ModelListViewSMTableCellProps) => {
    return (
        <TableCell sx={displayXs} colSpan={1 + info.length}>
            <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={8} alignItems={"center"}>
                    <Typography variant="subtitle1">{heading}</Typography>
                </Grid>
                <Grid item textAlign={"right"} xs={4}>
                    {info.map((item, i) => (
                        <Typography variant="body2" gutterBottom key={i}>
                            {item}
                        </Typography>
                    ))}
                </Grid>
            </Grid>
        </TableCell>
    );
};
