import React, { useState } from "react";

interface UseSelectableRowsResult {
    selected: string[];
    handleSelectAllClick: (
        event: React.ChangeEvent<HTMLInputElement>,
        items: string[]
    ) => void;
    handleCheckChanged: (id: string) => void;
    setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

export const useSelectableRows = (): UseSelectableRowsResult => {
    const [selected, setSelected] = useState<string[]>([]);

    function handleSelectAllClick(
        event: React.ChangeEvent<HTMLInputElement>,
        items: string[]
    ) {
        if (event.target.checked) {
            setSelected(items);
        } else {
            setSelected([]);
        }
    }

    /**
     * Adds or removes an item from selected, based on whether it is found in
     * the list or not.
     * @param _
     * @param id
     */
    const handleCheckChanged = (id: string) => {
        const selectedIndex = selected.indexOf(id);
        let result = [...selected];

        if (selectedIndex === -1) {
            // Not found, then add it
            result.push(id);
        } else {
            result.splice(selectedIndex, 1);
        }

        setSelected(result);
    };

    return {
        selected,
        handleSelectAllClick,
        handleCheckChanged,
        setSelected,
    };
};
