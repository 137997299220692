import {
    useMutation,
    UseMutationResult,
    useQueryClient,
} from "@tanstack/react-query";
import { addBearerTokenHeader, headers } from "./queryHookUtils";
import { BaseDTOType } from "../mocks/builders/BaseBuilder";
import { ApiErrorMessage } from "../interfaces/ApiErrorMessage";

export const handleUseProcessQuery =
    (url: string, userAccessToken: string) => async () => {
        const response = await fetch(url, {
            method: "POST",
            headers: addBearerTokenHeader(userAccessToken, headers),
        });

        if (response.status !== 200) {
            const apiError = (await response.json()) as ApiErrorMessage;
            throw new Error(apiError.message);
        }
        return response;
    };

export function useProcessQuery<T extends BaseDTOType>(
    url: string,
    queryKeysToInvalidate: string | string[],
    userAccessToken: string
): UseMutationResult<Response, Error, T> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: [queryKeysToInvalidate],
        mutationFn: handleUseProcessQuery(url, userAccessToken),
        onSuccess: () => {
            if (Array.isArray(queryKeysToInvalidate)) {
                queryKeysToInvalidate.forEach((key) =>
                    queryClient.invalidateQueries({ queryKey: [key] })
                );
            } else {
                queryClient
                    .invalidateQueries({
                        queryKey: [queryKeysToInvalidate],
                    })
                    .then();
            }
        },
    });
}
