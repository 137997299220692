import { useEffect } from "react";
import { Navigate } from "react-router";
import { navDefinitions } from "./NavDefinitions";
import { useAppStateContext } from "../App";
import { useAuth0 } from "@auth0/auth0-react";
import { doLogout } from "reducers/AppActionCreators";

export function LogMeOut(): React.ReactElement {
    const { dispatch } = useAppStateContext();

    const { logout } = useAuth0();

    useEffect(() => {
        dispatch(doLogout());
        logout();
    }, [logout, dispatch]);

    return <Navigate to={navDefinitions.home.path} />;
}
