import { BaseBuilder } from "./BaseBuilder";
import { TaxYearEntityDTO } from "@fspringveldt/qf-budget-generated-api-types";
import { thisYear } from "../../constants";

export class TaxYearBuilder extends BaseBuilder<TaxYearEntityDTO> {
    constructor(
        protected model: TaxYearEntityDTO = {
            country: "",
            isOwned: false,
            taxYear: thisYear,
        }
    ) {
        super();
    }

    static empty(): TaxYearEntityDTO {
        return new TaxYearBuilder().build();
    }

    withTaxYear(taxYear: number) {
        this.model.taxYear = taxYear;
        return this;
    }

    build(): TaxYearEntityDTO {
        return this.model;
    }
}
