export interface BaseDTOType {
    id?: string;
    createdDate?: string;
    lastModifiedDate?: string;
    userId?: string;
}

export abstract class BaseBuilder<T extends BaseDTOType> {
    protected abstract model: T;

    build(): T {
        return this.model;
    }

    withId(id: string) {
        this.model.id = id;
        return this;
    }

    withCreatedDate(createdDate: string) {
        this.model.createdDate = createdDate;
        return this;
    }

    withLastModifiedDate(lastModifiedDate: string) {
        this.model.lastModifiedDate = lastModifiedDate;
        return this;
    }

    withUserId(userId: string) {
        this.model.userId = userId;
        return this;
    }
}
