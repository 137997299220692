import {
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from "@tanstack/react-query";
import { addBearerTokenHeader, headers } from "./queryHookUtils";
import { ApiErrorMessage } from "../interfaces/ApiErrorMessage";

export const handleUseGetListQuery =
    (url: string, userAccessToken: string) => async () => {
        const config = {
            method: "GET",
            headers: addBearerTokenHeader(userAccessToken, headers),
        };
        const response = await fetch(url, { ...config });

        if (response.status !== 200) {
            const apiError = (await response.json()) as ApiErrorMessage;
            throw new Error(apiError.message);
        }

        return response.json();
    };

export function useGetListQuery<T>(
    url: string,
    cacheQueryKey: string,
    userAccessToken: string,
    options?:
        | Omit<UseQueryOptions<any, Error, T[]>, "queryKey" | "queryFn">
        | undefined
): UseQueryResult<T[], Error> {
    return useQuery({
        queryKey: [cacheQueryKey],
        queryFn: handleUseGetListQuery(url, userAccessToken),
        enabled: !!userAccessToken,
        ...options,
    });
}
