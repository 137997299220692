import { CRUDColumn, Model, ValidatorResult } from "../types/Model";
import { ValidationResult } from "../interfaces/ValidationResult";
import { ReactElement } from "react";

export type ValidatorType<TObject> = (state: TObject) => ValidatorResult;

export type RenderMutationType<TObject> = (
    row: TObject,
    setFieldValue: (key: string, value: any) => void,
    validationResult: ValidationResult | undefined
) => ReactElement;

export type RenderType<TObject> = (row: TObject) => ReactElement;

export class ModelBuilder<TObject> implements Builder<TObject> {
    addURL?: string;
    deleteURL?: string;
    editURL?: string;
    validator?: ValidatorType<TObject>;
    renderMutation?: RenderMutationType<TObject>;
    renderView?: RenderType<TObject>;

    constructor(
        public label: string,
        public cacheKey: string,
        public columns: CRUDColumn[],
        public render: RenderType<TObject>
    ) {}

    build(): Model<TObject> {
        return {
            addURL: this.addURL,
            deleteURL: this.deleteURL,
            editURL: this.editURL,
            validator: this.validator,
            renderMutation: this.renderMutation,
            label: this.label,
            cacheKey: this.cacheKey,
            columns: this.columns,
            render: this.render,
        };
    }

    withLabel(val: string) {
        this.label = val;
        return this;
    }

    withCacheKey(val: string) {
        this.cacheKey = val;
        return this;
    }

    withColumns(val: CRUDColumn[]) {
        this.columns = val;
        return this;
    }

    withRender(val: RenderType<TObject>) {
        this.render = val;
        return this;
    }

    withRenderView(val: RenderType<TObject>) {
        this.renderView = val;
        return this;
    }

    withRenderMutation(val: RenderMutationType<TObject>) {
        this.renderMutation = val;
        return this;
    }

    withValidator(val: ValidatorType<TObject>) {
        this.validator = val;
        return this;
    }

    withEditURL(val: string) {
        this.editURL = val;
        return this;
    }

    withDeleteURL(val: string) {
        this.deleteURL = val;
        return this;
    }

    withAddURL(val: string) {
        this.addURL = val;
        return this;
    }
}

export interface Builder<TObject> {
    build(): Model<TObject>;
}
