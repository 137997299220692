import {
    useMutation,
    UseMutationResult,
    useQueryClient,
} from "@tanstack/react-query";
import { addBearerTokenHeader, headers } from "./queryHookUtils";
import { ApiErrorMessage } from "../interfaces/ApiErrorMessage";

export const handleUseMutationQuery =
    <T>(url: string, userAccessToken: string) =>
    async (dto: T) => {
        const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(dto),
            headers: addBearerTokenHeader(userAccessToken, headers),
        });

        if (![200, 201].includes(response.status)) {
            const apiError = (await response.json()) as ApiErrorMessage;
            throw new Error(apiError.message);
        }

        return response;
    };

export function useMutationQuery<T>(
    url: string,
    queryKeysToInvalidate: string | string[],
    userAccessToken: string
): UseMutationResult<Response, Error, T> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: [queryKeysToInvalidate],
        mutationFn: handleUseMutationQuery<T>(url, userAccessToken),
        onSuccess: () => {
            const keys = Array.isArray(queryKeysToInvalidate)
                ? queryKeysToInvalidate
                : [queryKeysToInvalidate];
            queryClient.invalidateQueries({ queryKey: keys }).then();
        },
    });
}
