export interface CountryType {
    code: string;
    label: string;
    phone: string;
    suggested?: boolean;
    currencyCode?:
        | "AED"
        | "AFN"
        | "ALL"
        | "AMD"
        | "ANG"
        | "AOA"
        | "ARS"
        | "AUD"
        | "AWG"
        | "AZN"
        | "BAM"
        | "BBD"
        | "BDT"
        | "BGN"
        | "BHD"
        | "BIF"
        | "BMD"
        | "BND"
        | "BOB"
        | "BRL"
        | "BSD"
        | "BTN"
        | "BWP"
        | "BYR"
        | "BZD"
        | "CAD"
        | "CDF"
        | "CHF"
        | "CLP"
        | "CNY"
        | "COP"
        | "CRC"
        | "CUC"
        | "CVE"
        | "CZK"
        | "DJF"
        | "DKK"
        | "DOP"
        | "DZD"
        | "EEK"
        | "EGP"
        | "ERN"
        | "ETB"
        | "EUR"
        | "FJD"
        | "FKP"
        | "GBP"
        | "GEL"
        | "GHS"
        | "GIP"
        | "GMD"
        | "GNF"
        | "GQE"
        | "GTQ"
        | "GYD"
        | "HKD"
        | "HNL"
        | "HRK"
        | "HTG"
        | "HUF"
        | "IDR"
        | "ILS"
        | "INR"
        | "IQD"
        | "IRR"
        | "ISK"
        | "JMD"
        | "JOD"
        | "JPY"
        | "KES"
        | "KGS"
        | "KHR"
        | "KMF"
        | "KPW"
        | "KRW"
        | "KWD"
        | "KYD"
        | "KZT"
        | "LAK"
        | "LBP"
        | "LKR"
        | "LRD"
        | "LSL"
        | "LTL"
        | "LVL"
        | "LYD"
        | "MAD"
        | "MDL"
        | "MGA"
        | "MKD"
        | "MMK"
        | "MNT"
        | "MOP"
        | "MRO"
        | "MUR"
        | "MVR"
        | "MWK"
        | "MXN"
        | "MYR"
        | "MZM"
        | "NAD"
        | "NGN"
        | "NIO"
        | "NOK"
        | "NPR"
        | "NZD"
        | "OMR"
        | "PAB"
        | "PEN"
        | "PGK"
        | "PHP"
        | "PKR"
        | "PLN"
        | "PYG"
        | "QAR"
        | "RON"
        | "RSD"
        | "RUB"
        | "SAR"
        | "SBD"
        | "SCR"
        | "SDG"
        | "SEK"
        | "SGD"
        | "SHP"
        | "SLL"
        | "SOS"
        | "SRD"
        | "SYP"
        | "SZL"
        | "THB"
        | "TJS"
        | "TMT"
        | "TND"
        | "TRY"
        | "TTD"
        | "TWD"
        | "TZS"
        | "UAH"
        | "UGX"
        | "USD"
        | "UYU"
        | "UZS"
        | "VEB"
        | "VND"
        | "VUV"
        | "WST"
        | "XAF"
        | "XCD"
        | "XDR"
        | "XOF"
        | "XPF"
        | "YER"
        | "ZAR"
        | "ZMK"
        | "ZWR";
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const countries: readonly CountryType[] = [
    { code: "AD", label: "Andorra", phone: "376", currencyCode: "EUR" },
    {
        code: "AE",
        label: "United Arab Emirates",
        phone: "971",
        currencyCode: "AED",
    },
    { code: "AF", label: "Afghanistan", phone: "93", currencyCode: "AFN" },
    {
        code: "AG",
        label: "Antigua and Barbuda",
        phone: "1-268",
        currencyCode: "XCD",
    },
    { code: "AI", label: "Anguilla", phone: "1-264", currencyCode: "XCD" },
    { code: "AL", label: "Albania", phone: "355", currencyCode: "ALL" },
    { code: "AM", label: "Armenia", phone: "374", currencyCode: "AMD" },
    { code: "AO", label: "Angola", phone: "244", currencyCode: "AOA" },
    { code: "AQ", label: "Antarctica", phone: "672" },
    { code: "AR", label: "Argentina", phone: "54", currencyCode: "ARS" },
    {
        code: "AS",
        label: "American Samoa",
        phone: "1-684",
        currencyCode: "USD",
    },
    { code: "AT", label: "Austria", phone: "43", currencyCode: "EUR" },
    {
        code: "AU",
        label: "Australia",
        phone: "61",
        suggested: true,
        currencyCode: "AUD",
    },
    { code: "AW", label: "Aruba", phone: "297", currencyCode: "AWG" },
    { code: "AX", label: "Alland Islands", phone: "358", currencyCode: "EUR" },
    { code: "AZ", label: "Azerbaijan", phone: "994", currencyCode: "AZN" },
    {
        code: "BA",
        label: "Bosnia and Herzegovina",
        phone: "387",
        currencyCode: "BAM",
    },
    { code: "BB", label: "Barbados", phone: "1-246", currencyCode: "BBD" },
    { code: "BD", label: "Bangladesh", phone: "880", currencyCode: "BDT" },
    { code: "BE", label: "Belgium", phone: "32", currencyCode: "EUR" },
    { code: "BF", label: "Burkina Faso", phone: "226", currencyCode: "XOF" },
    { code: "BG", label: "Bulgaria", phone: "359", currencyCode: "BGN" },
    { code: "BH", label: "Bahrain", phone: "973", currencyCode: "BHD" },
    { code: "BI", label: "Burundi", phone: "257", currencyCode: "BIF" },
    { code: "BJ", label: "Benin", phone: "229", currencyCode: "XOF" },
    {
        code: "BL",
        label: "Saint Barthelemy",
        phone: "590",
        currencyCode: "EUR",
    },
    { code: "BM", label: "Bermuda", phone: "1-441", currencyCode: "BMD" },
    {
        code: "BN",
        label: "Brunei Darussalam",
        phone: "673",
        currencyCode: "BND",
    },
    { code: "BO", label: "Bolivia", phone: "591", currencyCode: "BOB" },
    { code: "BR", label: "Brazil", phone: "55", currencyCode: "BRL" },
    { code: "BS", label: "Bahamas", phone: "1-242", currencyCode: "BSD" },
    { code: "BT", label: "Bhutan", phone: "975", currencyCode: "INR" },
    { code: "BV", label: "Bouvet Island", phone: "47", currencyCode: "NOK" },
    { code: "BW", label: "Botswana", phone: "267", currencyCode: "BWP" },
    { code: "BY", label: "Belarus", phone: "375", currencyCode: "BYR" },
    { code: "BZ", label: "Belize", phone: "501", currencyCode: "BZD" },
    {
        code: "CA",
        label: "Canada",
        phone: "1",
        suggested: true,
        currencyCode: "CAD",
    },
    {
        code: "CC",
        label: "Cocos (Keeling) Islands",
        phone: "61",
        currencyCode: "AUD",
    },
    {
        code: "CD",
        label: "Congo, Democratic Republic of the",
        phone: "243",
        currencyCode: "CDF",
    },
    {
        code: "CF",
        label: "Central African Republic",
        phone: "236",
        currencyCode: "XAF",
    },
    {
        code: "CG",
        label: "Congo, Republic of the",
        phone: "242",
        currencyCode: "XAF",
    },
    { code: "CH", label: "Switzerland", phone: "41", currencyCode: "CHF" },
    { code: "CI", label: "Cote d'Ivoire", phone: "225", currencyCode: "XOF" },
    { code: "CK", label: "Cook Islands", phone: "682", currencyCode: "NZD" },
    { code: "CL", label: "Chile", phone: "56", currencyCode: "CLP" },
    { code: "CM", label: "Cameroon", phone: "237", currencyCode: "XAF" },
    { code: "CN", label: "China", phone: "86", currencyCode: "CNY" },
    { code: "CO", label: "Colombia", phone: "57", currencyCode: "COP" },
    { code: "CR", label: "Costa Rica", phone: "506", currencyCode: "CRC" },
    { code: "CU", label: "Cuba", phone: "53", currencyCode: "CUC" },
    { code: "CV", label: "Cape Verde", phone: "238", currencyCode: "CVE" },
    { code: "CW", label: "Curacao", phone: "599", currencyCode: "ANG" },
    { code: "CX", label: "Christmas Island", phone: "61", currencyCode: "AUD" },
    { code: "CY", label: "Cyprus", phone: "357", currencyCode: "EUR" },
    { code: "CZ", label: "Czech Republic", phone: "420", currencyCode: "CZK" },
    {
        code: "DE",
        label: "Germany",
        phone: "49",
        suggested: true,
        currencyCode: "EUR",
    },
    { code: "DJ", label: "Djibouti", phone: "253", currencyCode: "DJF" },
    { code: "DK", label: "Denmark", phone: "45", currencyCode: "DKK" },
    { code: "DM", label: "Dominica", phone: "1-767", currencyCode: "XCD" },
    {
        code: "DO",
        label: "Dominican Republic",
        phone: "1-809",
        currencyCode: "DOP",
    },
    { code: "DZ", label: "Algeria", phone: "213", currencyCode: "DZD" },
    { code: "EC", label: "Ecuador", phone: "593", currencyCode: "USD" },
    { code: "EE", label: "Estonia", phone: "372", currencyCode: "EUR" },
    { code: "EG", label: "Egypt", phone: "20", currencyCode: "EGP" },
    { code: "EH", label: "Western Sahara", phone: "212", currencyCode: "MAD" },
    { code: "ER", label: "Eritrea", phone: "291", currencyCode: "ERN" },
    { code: "ES", label: "Spain", phone: "34", currencyCode: "EUR" },
    { code: "ET", label: "Ethiopia", phone: "251", currencyCode: "ETB" },
    { code: "FI", label: "Finland", phone: "358", currencyCode: "EUR" },
    { code: "FJ", label: "Fiji", phone: "679", currencyCode: "FJD" },
    {
        code: "FK",
        label: "Falkland Islands (Malvinas)",
        phone: "500",
        currencyCode: "FKP",
    },
    {
        code: "FM",
        label: "Micronesia, Federated States of",
        phone: "691",
        currencyCode: "USD",
    },
    { code: "FO", label: "Faroe Islands", phone: "298", currencyCode: "DKK" },
    {
        code: "FR",
        label: "France",
        phone: "33",
        suggested: true,
        currencyCode: "EUR",
    },
    { code: "GA", label: "Gabon", phone: "241", currencyCode: "XAF" },
    { code: "GB", label: "United Kingdom", phone: "44", currencyCode: "GBP" },
    { code: "GD", label: "Grenada", phone: "1-473", currencyCode: "XCD" },
    { code: "GE", label: "Georgia", phone: "995", currencyCode: "GEL" },
    { code: "GF", label: "French Guiana", phone: "594", currencyCode: "EUR" },
    { code: "GG", label: "Guernsey", phone: "44", currencyCode: "GBP" },
    { code: "GH", label: "Ghana", phone: "233", currencyCode: "GHS" },
    { code: "GI", label: "Gibraltar", phone: "350", currencyCode: "GIP" },
    { code: "GL", label: "Greenland", phone: "299", currencyCode: "DKK" },
    { code: "GM", label: "Gambia", phone: "220", currencyCode: "GMD" },
    { code: "GN", label: "Guinea", phone: "224", currencyCode: "GNF" },
    { code: "GP", label: "Guadeloupe", phone: "590", currencyCode: "EUR" },
    {
        code: "GQ",
        label: "Equatorial Guinea",
        phone: "240",
        currencyCode: "XAF",
    },
    { code: "GR", label: "Greece", phone: "30", currencyCode: "EUR" },
    {
        code: "GS",
        label: "South Georgia and the South Sandwich Islands",
        phone: "500",
    },
    { code: "GT", label: "Guatemala", phone: "502", currencyCode: "GTQ" },
    { code: "GU", label: "Guam", phone: "1-671", currencyCode: "USD" },
    { code: "GW", label: "Guinea-Bissau", phone: "245", currencyCode: "XOF" },
    { code: "GY", label: "Guyana", phone: "592", currencyCode: "GYD" },
    { code: "HK", label: "Hong Kong", phone: "852", currencyCode: "HKD" },
    {
        code: "HM",
        label: "Heard Island and McDonald Islands",
        phone: "672",
        currencyCode: "AUD",
    },
    { code: "HN", label: "Honduras", phone: "504", currencyCode: "HNL" },
    { code: "HR", label: "Croatia", phone: "385", currencyCode: "HRK" },
    { code: "HT", label: "Haiti", phone: "509", currencyCode: "USD" },
    { code: "HU", label: "Hungary", phone: "36", currencyCode: "HUF" },
    { code: "ID", label: "Indonesia", phone: "62", currencyCode: "IDR" },
    { code: "IE", label: "Ireland", phone: "353", currencyCode: "EUR" },
    { code: "IL", label: "Israel", phone: "972", currencyCode: "ILS" },
    { code: "IM", label: "Isle of Man", phone: "44", currencyCode: "GBP" },
    { code: "IN", label: "India", phone: "91", currencyCode: "INR" },
    {
        code: "IO",
        label: "British Indian Ocean Territory",
        phone: "246",
        currencyCode: "USD",
    },
    { code: "IQ", label: "Iraq", phone: "964", currencyCode: "IQD" },
    {
        code: "IR",
        label: "Iran, Islamic Republic of",
        phone: "98",
        currencyCode: "IRR",
    },
    { code: "IS", label: "Iceland", phone: "354", currencyCode: "ISK" },
    { code: "IT", label: "Italy", phone: "39", currencyCode: "EUR" },
    { code: "JE", label: "Jersey", phone: "44", currencyCode: "GBP" },
    { code: "JM", label: "Jamaica", phone: "1-876", currencyCode: "JMD" },
    { code: "JO", label: "Jordan", phone: "962", currencyCode: "JOD" },
    {
        code: "JP",
        label: "Japan",
        phone: "81",
        suggested: true,
        currencyCode: "JPY",
    },
    { code: "KE", label: "Kenya", phone: "254", currencyCode: "KES" },
    { code: "KG", label: "Kyrgyzstan", phone: "996", currencyCode: "KGS" },
    { code: "KH", label: "Cambodia", phone: "855", currencyCode: "KHR" },
    { code: "KI", label: "Kiribati", phone: "686", currencyCode: "AUD" },
    { code: "KM", label: "Comoros", phone: "269", currencyCode: "KMF" },
    {
        code: "KN",
        label: "Saint Kitts and Nevis",
        phone: "1-869",
        currencyCode: "XCD",
    },
    {
        code: "KP",
        label: "Korea, Democratic People's Republic of",
        phone: "850",
        currencyCode: "KPW",
    },
    {
        code: "KR",
        label: "Korea, Republic of",
        phone: "82",
        currencyCode: "KRW",
    },
    { code: "KW", label: "Kuwait", phone: "965", currencyCode: "KWD" },
    {
        code: "KY",
        label: "Cayman Islands",
        phone: "1-345",
        currencyCode: "KYD",
    },
    { code: "KZ", label: "Kazakhstan", phone: "7", currencyCode: "KZT" },
    {
        code: "LA",
        label: "Lao People's Democratic Republic",
        phone: "856",
        currencyCode: "LAK",
    },
    { code: "LB", label: "Lebanon", phone: "961", currencyCode: "LBP" },
    { code: "LC", label: "Saint Lucia", phone: "1-758", currencyCode: "XCD" },
    { code: "LI", label: "Liechtenstein", phone: "423", currencyCode: "CHF" },
    { code: "LK", label: "Sri Lanka", phone: "94", currencyCode: "LKR" },
    { code: "LR", label: "Liberia", phone: "231", currencyCode: "LRD" },
    { code: "LS", label: "Lesotho", phone: "266", currencyCode: "ZAR" },
    { code: "LT", label: "Lithuania", phone: "370", currencyCode: "EUR" },
    { code: "LU", label: "Luxembourg", phone: "352", currencyCode: "EUR" },
    { code: "LV", label: "Latvia", phone: "371", currencyCode: "EUR" },
    { code: "LY", label: "Libya", phone: "218", currencyCode: "LYD" },
    { code: "MA", label: "Morocco", phone: "212", currencyCode: "MAD" },
    { code: "MC", label: "Monaco", phone: "377", currencyCode: "EUR" },
    {
        code: "MD",
        label: "Moldova, Republic of",
        phone: "373",
        currencyCode: "MDL",
    },
    { code: "ME", label: "Montenegro", phone: "382", currencyCode: "EUR" },
    {
        code: "MF",
        label: "Saint Martin (French part)",
        phone: "590",
        currencyCode: "EUR",
    },
    { code: "MG", label: "Madagascar", phone: "261", currencyCode: "MGA" },
    {
        code: "MH",
        label: "Marshall Islands",
        phone: "692",
        currencyCode: "USD",
    },
    {
        code: "MK",
        label: "Macedonia, the Former Yugoslav Republic of",
        phone: "389",
        currencyCode: "MKD",
    },
    { code: "ML", label: "Mali", phone: "223", currencyCode: "XOF" },
    { code: "MM", label: "Myanmar", phone: "95", currencyCode: "MMK" },
    { code: "MN", label: "Mongolia", phone: "976", currencyCode: "MNT" },
    { code: "MO", label: "Macao", phone: "853", currencyCode: "MOP" },
    {
        code: "MP",
        label: "Northern Mariana Islands",
        phone: "1-670",
        currencyCode: "USD",
    },
    { code: "MQ", label: "Martinique", phone: "596", currencyCode: "EUR" },
    { code: "MR", label: "Mauritania", phone: "222", currencyCode: "MRO" },
    { code: "MS", label: "Montserrat", phone: "1-664", currencyCode: "XCD" },
    { code: "MT", label: "Malta", phone: "356", currencyCode: "EUR" },
    { code: "MU", label: "Mauritius", phone: "230", currencyCode: "MUR" },
    { code: "MV", label: "Maldives", phone: "960", currencyCode: "MVR" },
    { code: "MW", label: "Malawi", phone: "265", currencyCode: "MWK" },
    { code: "MX", label: "Mexico", phone: "52", currencyCode: "MXN" },
    { code: "MY", label: "Malaysia", phone: "60", currencyCode: "MYR" },
    { code: "MZ", label: "Mozambique", phone: "258", currencyCode: "MZM" },
    { code: "NA", label: "Namibia", phone: "264", currencyCode: "ZAR" },
    { code: "NC", label: "New Caledonia", phone: "687", currencyCode: "XPF" },
    { code: "NE", label: "Niger", phone: "227", currencyCode: "XOF" },
    { code: "NF", label: "Norfolk Island", phone: "672", currencyCode: "AUD" },
    { code: "NG", label: "Nigeria", phone: "234", currencyCode: "NGN" },
    { code: "NI", label: "Nicaragua", phone: "505", currencyCode: "NIO" },
    { code: "NL", label: "Netherlands", phone: "31", currencyCode: "EUR" },
    { code: "NO", label: "Norway", phone: "47", currencyCode: "NOK" },
    { code: "NP", label: "Nepal", phone: "977", currencyCode: "NPR" },
    { code: "NR", label: "Nauru", phone: "674", currencyCode: "AUD" },
    { code: "NU", label: "Niue", phone: "683", currencyCode: "NZD" },
    {
        code: "NZ",
        label: "New Zealand",
        phone: "64",
        suggested: true,
        currencyCode: "NZD",
    },
    { code: "OM", label: "Oman", phone: "968", currencyCode: "OMR" },
    { code: "PA", label: "Panama", phone: "507", currencyCode: "USD" },
    { code: "PE", label: "Peru", phone: "51", currencyCode: "PEN" },
    {
        code: "PF",
        label: "French Polynesia",
        phone: "689",
        currencyCode: "XPF",
    },
    {
        code: "PG",
        label: "Papua New Guinea",
        phone: "675",
        currencyCode: "PGK",
    },
    { code: "PH", label: "Philippines", phone: "63", currencyCode: "PHP" },
    { code: "PK", label: "Pakistan", phone: "92", currencyCode: "PKR" },
    { code: "PL", label: "Poland", phone: "48", currencyCode: "PLN" },
    {
        code: "PM",
        label: "Saint Pierre and Miquelon",
        phone: "508",
        currencyCode: "EUR",
    },
    { code: "PN", label: "Pitcairn", phone: "870", currencyCode: "NZD" },
    { code: "PR", label: "Puerto Rico", phone: "1", currencyCode: "USD" },
    {
        code: "PS",
        label: "Palestine, State of",
        phone: "970",
    },
    { code: "PT", label: "Portugal", phone: "351", currencyCode: "EUR" },
    { code: "PW", label: "Palau", phone: "680", currencyCode: "USD" },
    { code: "PY", label: "Paraguay", phone: "595", currencyCode: "PYG" },
    { code: "QA", label: "Qatar", phone: "974", currencyCode: "QAR" },
    { code: "RE", label: "Reunion", phone: "262", currencyCode: "EUR" },
    { code: "RO", label: "Romania", phone: "40", currencyCode: "RON" },
    { code: "RS", label: "Serbia", phone: "381", currencyCode: "RSD" },
    {
        code: "RU",
        label: "Russian Federation",
        phone: "7",
        currencyCode: "RUB",
    },
    { code: "RW", label: "Rwanda", phone: "250" },
    { code: "SA", label: "Saudi Arabia", phone: "966", currencyCode: "SAR" },
    { code: "SB", label: "Solomon Islands", phone: "677", currencyCode: "SBD" },
    { code: "SC", label: "Seychelles", phone: "248", currencyCode: "SCR" },
    { code: "SD", label: "Sudan", phone: "249", currencyCode: "SDG" },
    { code: "SE", label: "Sweden", phone: "46", currencyCode: "SEK" },
    { code: "SG", label: "Singapore", phone: "65", currencyCode: "SGD" },
    { code: "SH", label: "Saint Helena", phone: "290", currencyCode: "SHP" },
    { code: "SI", label: "Slovenia", phone: "386", currencyCode: "EUR" },
    {
        code: "SJ",
        label: "Svalbard and Jan Mayen",
        phone: "47",
        currencyCode: "NOK",
    },
    { code: "SK", label: "Slovakia", phone: "421", currencyCode: "EUR" },
    { code: "SL", label: "Sierra Leone", phone: "232", currencyCode: "SLL" },
    { code: "SM", label: "San Marino", phone: "378", currencyCode: "EUR" },
    { code: "SN", label: "Senegal", phone: "221", currencyCode: "XOF" },
    { code: "SO", label: "Somalia", phone: "252", currencyCode: "SOS" },
    { code: "SR", label: "Suriname", phone: "597", currencyCode: "SRD" },
    { code: "SS", label: "South Sudan", phone: "211", currencyCode: "SDG" },
    {
        code: "ST",
        label: "Sao Tome and Principe",
        phone: "239",
    },
    { code: "SV", label: "El Salvador", phone: "503", currencyCode: "USD" },
    {
        code: "SX",
        label: "Sint Maarten (Dutch part)",
        phone: "1-721",
        currencyCode: "ANG",
    },
    {
        code: "SY",
        label: "Syrian Arab Republic",
        phone: "963",
        currencyCode: "SYP",
    },
    { code: "SZ", label: "Swaziland", phone: "268", currencyCode: "SZL" },
    {
        code: "TC",
        label: "Turks and Caicos Islands",
        phone: "1-649",
        currencyCode: "USD",
    },
    { code: "TD", label: "Chad", phone: "235", currencyCode: "XAF" },
    {
        code: "TF",
        label: "French Southern Territories",
        phone: "262",
        currencyCode: "EUR",
    },
    { code: "TG", label: "Togo", phone: "228", currencyCode: "XOF" },
    { code: "TH", label: "Thailand", phone: "66", currencyCode: "THB" },
    { code: "TJ", label: "Tajikistan", phone: "992", currencyCode: "TJS" },
    { code: "TK", label: "Tokelau", phone: "690", currencyCode: "NZD" },
    { code: "TL", label: "Timor-Leste", phone: "670", currencyCode: "USD" },
    { code: "TM", label: "Turkmenistan", phone: "993", currencyCode: "TMT" },
    { code: "TN", label: "Tunisia", phone: "216", currencyCode: "TND" },
    { code: "TO", label: "Tonga", phone: "676" },
    { code: "TR", label: "Turkey", phone: "90", currencyCode: "TRY" },
    {
        code: "TT",
        label: "Trinidad and Tobago",
        phone: "1-868",
        currencyCode: "TTD",
    },
    { code: "TV", label: "Tuvalu", phone: "688", currencyCode: "AUD" },
    {
        code: "TW",
        label: "Taiwan, Province of China",
        phone: "886",
        currencyCode: "TWD",
    },
    {
        code: "TZ",
        label: "United Republic of Tanzania",
        phone: "255",
        currencyCode: "TZS",
    },
    { code: "UA", label: "Ukraine", phone: "380", currencyCode: "UAH" },
    { code: "UG", label: "Uganda", phone: "256", currencyCode: "UGX" },
    {
        code: "US",
        label: "United States",
        phone: "1",
        suggested: true,
        currencyCode: "USD",
    },
    { code: "UY", label: "Uruguay", phone: "598", currencyCode: "UYU" },
    { code: "UZ", label: "Uzbekistan", phone: "998", currencyCode: "UZS" },
    {
        code: "VA",
        label: "Holy See (Vatican City State)",
        phone: "379",
        currencyCode: "EUR",
    },
    {
        code: "VC",
        label: "Saint Vincent and the Grenadines",
        phone: "1-784",
        currencyCode: "XCD",
    },
    { code: "VE", label: "Venezuela", phone: "58", currencyCode: "VEB" },
    {
        code: "VG",
        label: "British Virgin Islands",
        phone: "1-284",
        currencyCode: "USD",
    },
    {
        code: "VI",
        label: "US Virgin Islands",
        phone: "1-340",
        currencyCode: "USD",
    },
    { code: "VN", label: "Vietnam", phone: "84", currencyCode: "VND" },
    { code: "VU", label: "Vanuatu", phone: "678", currencyCode: "VUV" },
    {
        code: "WF",
        label: "Wallis and Futuna",
        phone: "681",
        currencyCode: "XPF",
    },
    { code: "WS", label: "Samoa", phone: "685", currencyCode: "WST" },
    { code: "XK", label: "Kosovo", phone: "383" },
    { code: "YE", label: "Yemen", phone: "967", currencyCode: "YER" },
    { code: "YT", label: "Mayotte", phone: "262", currencyCode: "EUR" },
    {
        code: "ZA",
        label: "South Africa",
        suggested: true,
        phone: "27",
        currencyCode: "ZAR",
    },
    { code: "ZM", label: "Zambia", phone: "260", currencyCode: "ZMK" },
    { code: "ZW", label: "Zimbabwe", phone: "263", currencyCode: "ZWR" },
];

export function asCountriesByCurrenciesRecord(): Record<string, string> {
    return countries.reduce((acc, c) => {
        acc[c.code] = c.currencyCode || "$";
        return acc;
    }, {} as Record<string, string>);
}
