import * as React from "react";
import { useEffect } from "react";
import { countries as baseCountries } from "../data/Countries";
import { Dropdown, DropdownProps } from "./forms/dropdown/Dropdown";
import { useAppStateContext } from "./App";

const countries = baseCountries.map((country) => ({
    value: country.code,
    label: `${country.label} (${country.code}) +${country.phone}`,
}));

export function CountrySelector(props: DropdownProps) {
    const {
        label = "Choose a country",
        fieldName,
        fieldValue,
        setFieldValue,
        validationResult,
    } = props;

    const {
        state: { userInfo },
    } = useAppStateContext();

    useEffect(() => {
        if (!fieldValue && userInfo?.defaultCountry) {
            setFieldValue(fieldName, userInfo.defaultCountry);
        }
    }, [fieldValue, userInfo?.defaultCountry, fieldName, setFieldValue]);
    return (
        <Dropdown
            label={label}
            fieldName={fieldName}
            fieldValue={fieldValue || userInfo?.defaultCountry}
            options={countries}
            setFieldValue={setFieldValue}
            validationResult={validationResult}
            returnValueOnly={true}
        />
    );
}
