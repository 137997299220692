import { Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { thisMonth, thisYear } from "../../constants";
import { navDefinitions } from "../routes/NavDefinitions";
import { IncomeBudgetDetail } from "../routes/IncomeBudgetDetail";
import { Analytics } from "../analytics/Analytics";
import { ExpenseBudgetDetail } from "../routes/ExpenseBudgetDetail";
import React, { ReactElement } from "react";
import { CalendarContainer } from "../schedule/CalendarContainer";
import { DashboardItem } from "./DashboardItem";
import { MonthAndYearSpinner } from "./MonthAndYearSpinner";

/**
 * Dashboard inspired by https://example.mui.admin.refine.dev/
 * @constructor
 */
export function Dashboard(): ReactElement {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    const { budgetId = "", year = thisYear, month = thisMonth } = useParams();
    const dashMonth = parseInt(String(month), 10);
    const dashYear = parseInt(String(year), 10);

    return (
        <>
            <Typography variant={"h4"} align={"center"}>
                DASHBOARD
            </Typography>
            <MonthAndYearSpinner
                budgetId={budgetId}
                dashYear={dashYear}
                dashMonth={dashMonth}
            />
            <Grid container spacing={matches ? 2 : 0} paddingBottom={4}>
                <DashboardItem
                    sm={6}
                    xs={12}
                    link={navDefinitions.incomeBudgetDetail.path
                        .replace(":year", dashYear.toString())
                        .replace(":budgetId", budgetId.toString())}
                    boxTitle={"Income budget detail"}
                    title={"Income"}
                >
                    <IncomeBudgetDetail month={dashMonth} noHeader />
                </DashboardItem>
                <DashboardItem
                    headerBGColor={"warning.main"}
                    headerBGHoverColor={"warning.dark"}
                    sm={6}
                    xs={12}
                    link={navDefinitions.expenseBudgetDetail.path
                        .replace(":year", dashYear.toString())
                        .replace(":budgetId", budgetId.toString())}
                    boxTitle={"View your expense detail"}
                    title={"Expenses"}
                >
                    <ExpenseBudgetDetail month={dashMonth} noHeader />
                </DashboardItem>
                <DashboardItem
                    headerBGColor={"primary.main"}
                    headerBGHoverColor={"primary.dark"}
                    sm={6}
                    xs={12}
                    link={navDefinitions.analytics.path
                        .replace(":budgetId", budgetId.toString())
                        .replace(":year", dashYear.toString())
                        .replace(":month", dashMonth.toString())}
                    boxTitle={"Metrics and trends"}
                    title={"Analytics"}
                >
                    <Analytics month={dashMonth} year={dashYear} noHeader />
                </DashboardItem>
                <DashboardItem
                    headerBGColor={"info.main"}
                    headerBGHoverColor={"info.dark"}
                    sm={6}
                    xs={12}
                    link={navDefinitions.schedule.path
                        .replace(":budgetId", budgetId.toString())
                        .replace(":year", dashYear.toString())
                        .replace(":month", dashMonth.toString())}
                    boxTitle={"View your schedule"}
                    title={"Calendar"}
                >
                    <CalendarContainer
                        budgetId={budgetId}
                        month={dashMonth}
                        year={dashYear}
                        weekStartDay={"Mon"}
                    />
                </DashboardItem>
            </Grid>
        </>
    );
}
