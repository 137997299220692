import { Card, CardContent, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { navDefinitions } from "./NavDefinitions";
import { pivotYears } from "../../constants";

export function IncomeBudget(): React.ReactElement {
    return (
        <Grid container spacing={2}>
            {pivotYears.map((year) => (
                <Grid item key={year} xs={12} sm={2} md={6}>
                    <Link
                        style={{ textDecoration: "none" }}
                        to={`${navDefinitions.incomeBudgetDetail.path.replace(
                            ":year",
                            year.toString()
                        )}`}
                    >
                        <Card>
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                >
                                    {year}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    View summary for {year}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
            ))}
        </Grid>
    );
}
