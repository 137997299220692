import { BaseBuilder } from "./BaseBuilder";
import { ExpenseCategoryEntityDTO } from "@fspringveldt/qf-budget-generated-api-types";

export class ExpenseCategoryBuilder extends BaseBuilder<ExpenseCategoryEntityDTO> {
    constructor(
        protected model: ExpenseCategoryEntityDTO = {
            name: "",
            isOwned: true,
        }
    ) {
        super();
    }

    static empty(): ExpenseCategoryEntityDTO {
        return new ExpenseCategoryBuilder().build();
    }

    build(): ExpenseCategoryEntityDTO {
        return this.model;
    }
}
