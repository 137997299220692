import { EXPENSE_CACHE_KEY, EXPENSE_GET_ALL } from "../../constants";
import { ModelListView } from "../views/ModelListView";
import { ExpenseModel } from "models/ExpenseModel";
import { useGetListQuery } from "hooks/useGetListQuery";
import { useAppStateContext } from "../App";
import { useParams } from "react-router-dom";
import { ExpenseEntityDTO } from "@fspringveldt/qf-budget-generated-api-types";
import { ExpenseBuilder } from "../../mocks/builders/ExpenseBuilder";

export function Expenses(): React.ReactElement {
    const {
        state: { userAccessToken },
    } = useAppStateContext();
    const { budgetId = "" } = useParams<string>();

    const { error, data, isLoading } = useGetListQuery<ExpenseEntityDTO>(
        EXPENSE_GET_ALL.replace(":budgetId", budgetId),
        EXPENSE_CACHE_KEY + budgetId,
        userAccessToken
    );
    return (
        <ModelListView
            model={ExpenseModel(budgetId)}
            dialogTitle={"Expenses"}
            rows={data}
            error={error}
            initialData={ExpenseBuilder.empty()}
            isLoading={isLoading}
        />
    );
}
