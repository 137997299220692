import { Box, Breadcrumbs, Typography } from "@mui/material";
import Link, { LinkProps } from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";

interface LinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => (
    <Link {...props} component={RouterLink as any} />
);

function HomeIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
        </SvgIcon>
    );
}

export const BreadCrumbs = () => {
    const breadcrumbs = useReactRouterBreadcrumbs();
    return (
        <Box sx={{ my: 0.5 }}>
            <Breadcrumbs aria-label="breadcrumb">
                {breadcrumbs.map((breadCrumbData, index) => {
                    const last = index === breadcrumbs.length - 1;
                    const { match, breadcrumb } = breadCrumbData;
                    if (index === 0) {
                        return (
                            <LinkRouter
                                key={match.pathname}
                                underline="hover"
                                color="inherit"
                                to={match.pathname}
                            >
                                <HomeIcon />
                            </LinkRouter>
                        );
                    }
                    return last ? (
                        <Typography key={match.pathname}>
                            {breadcrumb}
                        </Typography>
                    ) : (
                        <LinkRouter
                            underline="hover"
                            color="inherit"
                            to={match.pathname}
                            key={match.pathname}
                        >
                            {breadcrumb}
                        </LinkRouter>
                    );
                })}
            </Breadcrumbs>
        </Box>
    );
};
