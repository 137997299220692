import { useAppStateContext } from "../App";
import { useGetOneQuery } from "../../hooks/useGetOneQuery";
import { TimelineDTO } from "../../models/ScheduleModel";
import {
    BUDGET_TIMELINE_GET,
    thisMonth,
    thisYear,
    TIMELINE_CACHE_KEY,
} from "../../constants";
import { Skeleton, Stack } from "@mui/material";
import React from "react";
import { Calendar, CalendarContainerProps } from "./Calendar";
import { getCountryCurrency } from "../../data/Currencies";

export const CalendarContainer = (props: CalendarContainerProps) => {
    const { budgetId, year = thisYear, month = thisMonth } = props;
    const {
        state: { userAccessToken },
    } = useAppStateContext();
    const { data, isLoading } = useGetOneQuery<TimelineDTO>(
        BUDGET_TIMELINE_GET.replace(":budgetId", budgetId)
            .replace(":year", year.toString())
            .replace(":month", month.toString()),
        TIMELINE_CACHE_KEY + month + year + budgetId,
        userAccessToken
    );

    const dailyEntries =
        data?.items.map((i) => ({
            day: i.day,
            entries: i.items.map((j) => ({
                name: `${getCountryCurrency(j.country)}${j.amount} ${j.name}`,
            })),
        })) || [];
    console.log("DE", dailyEntries);
    return (
        <>
            {!isLoading && data ? (
                <Calendar
                    {...props}
                    year={year}
                    month={month}
                    dailyEntries={dailyEntries}
                />
            ) : (
                <Stack spacing={1}>
                    <Skeleton variant="rectangular" width={153} height={36} />
                    <Skeleton variant="rectangular" height={400} />
                </Stack>
            )}
        </>
    );
};
