import { ValidatorResult } from "../types/Model";
import {
    ModelBuilder,
    RenderMutationType,
    RenderType,
    ValidatorType,
} from "./ModelBuilder";
import {
    BUDGET_CACHE_KEY,
    BUDGET_GET_ALL,
    thisMonth,
    thisYear,
} from "../constants";
import {
    Badge,
    Container,
    FormControl,
    Grid,
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import {
    FormDataProps,
    ViewDataProps,
} from "../components/forms/FormDataProps";
import { Link } from "react-router-dom";
import { navDefinitions } from "../components/routes/NavDefinitions";
import React from "react";
import { IncomeModel } from "./IncomeModel";
import { ExpenseModel } from "./ExpenseModel";
import { BudgetDTO } from "@fspringveldt/qf-budget-generated-api-types";
import {
    displayNoneXs,
    IModelListViewTableColumn,
    ModelListViewSMTableCell,
} from "../components/views/ModelListView";

const columns: Array<IModelListViewTableColumn> = [
    { sortByField: "name", label: "Name" },
    { sortByField: "incomes", label: "Incomes" },
    { sortByField: "expenses", label: "Expenses" },
    { label: "Dashboard" },
    { label: "Share" },
];

const render: RenderType<BudgetDTO> = (row) => {
    const budgetLink = (
        <Link
            to={navDefinitions.budget.path.replace(
                ":budgetId",
                row.id as string
            )}
        >
            {row.name}
        </Link>
    );
    const incomesLink = (
        <Badge badgeContent={Array.from(row.incomes)?.length} color={"primary"}>
            <Link
                to={navDefinitions.income.path.replace(
                    ":budgetId",
                    row.id as string
                )}
            >
                Incomes
            </Link>
        </Badge>
    );
    const expensesLink = (
        <Badge
            badgeContent={Array.from(row.expenses)?.length}
            color={"primary"}
        >
            <Link
                to={navDefinitions.expense.path.replace(
                    ":budgetId",
                    row.id as string
                )}
            >
                Expenses
            </Link>
        </Badge>
    );
    const dashboardLink = (
        <Link
            to={navDefinitions.dashboards.path
                .replace(":budgetId", row.id as string)
                .replace(":year", thisYear.toString())
                .replace(":month", thisMonth.toString())}
        >
            Dashboard
        </Link>
    );
    const shareLink = (
        <Link
            to={navDefinitions.budgetShare.path.replace(
                ":budgetId",
                row.id as string
            )}
        >
            Share
        </Link>
    );

    return (
        <>
            <ModelListViewSMTableCell
                heading={budgetLink}
                info={[incomesLink, expensesLink, dashboardLink, shareLink]}
            />
            <TableCell sx={displayNoneXs}>{budgetLink}</TableCell>
            <TableCell sx={displayNoneXs}>{incomesLink}</TableCell>
            <TableCell sx={displayNoneXs}>{expensesLink}</TableCell>
            <TableCell sx={displayNoneXs}>{dashboardLink}</TableCell>
            <TableCell sx={displayNoneXs}>{shareLink}</TableCell>
        </>
    );
};

const validator: ValidatorType<BudgetDTO> = (state): ValidatorResult => {
    let errors: ValidatorResult = {};

    if (!state.name) {
        errors.name = "Name is required";
    }
    return errors;
};

const renderMutation: RenderMutationType<BudgetDTO> = (
    row,
    setFieldValue,
    validationResult
) => (
    <BudgetForm
        state={row}
        setFieldValue={setFieldValue}
        validationResult={validationResult}
    />
);

const renderView: RenderType<BudgetDTO> = (row) => <BudgetView state={row} />;

export const BudgetModel = new ModelBuilder<BudgetDTO>(
    "Budget",
    BUDGET_CACHE_KEY,
    columns,
    render
)
    .withAddURL(BUDGET_GET_ALL)
    .withDeleteURL(BUDGET_GET_ALL)
    .withEditURL(BUDGET_GET_ALL)
    .withValidator(validator)
    .withRenderMutation(renderMutation)
    .withRenderView(renderView)
    .build();

function BudgetForm<T extends BudgetDTO>({
    state,
    setFieldValue,
    validationResult,
}: FormDataProps<T>) {
    return (
        <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                    <TextField
                        autoComplete="off"
                        id="name"
                        required
                        defaultValue={state.name}
                        onChange={(e) => setFieldValue("name", e.target.value)}
                        label={"Budget name"}
                        variant={"filled"}
                        error={!!validationResult?.errors?.name}
                        helperText={validationResult?.errors?.name}
                        autoFocus={true}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
}

const BudgetView = <T extends BudgetDTO>({ state }: ViewDataProps<T>) => {
    const budgetId = state.id;

    if (!budgetId) return <></>;

    const incomeModel = IncomeModel(budgetId);
    const expenseModel = ExpenseModel(budgetId);
    return (
        <>
            <Grid container>
                <Grid item>
                    <Typography gutterBottom variant={"h4"}>
                        {state.name}
                    </Typography>
                </Grid>
            </Grid>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <Container fixed>
                    <Typography variant={"h6"}>Incomes</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {incomeModel.columns.map((i) => (
                                                <TableCell>{i.label}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    {Array.from(state.incomes)?.map(
                                        (income) => (
                                            <TableRow>
                                                {incomeModel.render(income)}
                                            </TableRow>
                                        )
                                    )}
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Typography variant={"h6"}>Expenses</Typography>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {expenseModel.columns.map((i) => (
                                                <TableCell>{i.label}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    {Array.from(state.expenses)?.map(
                                        (expense) => (
                                            <TableRow>
                                                {expenseModel.render(expense)}
                                            </TableRow>
                                        )
                                    )}
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </>
    );
};
