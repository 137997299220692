import { BUDGET_SHARE_CACHE_KEY, BUDGET_SHARE_GET_ALL } from "../../constants";
import { useAppStateContext } from "../App";
import { ReactElement } from "react";
import { useParams } from "react-router-dom";
import { BudgetShareModel } from "../../models/BudgetShareModel";
import { useGetListQuery } from "../../hooks/useGetListQuery";
import { ModelListView } from "../views/ModelListView";
import { BudgetShareDTO } from "@fspringveldt/qf-budget-generated-api-types";
import { BudgetShareBuilder } from "../../mocks/builders/BudgetShareBuilder";

export function BudgetShares(): ReactElement {
    const {
        state: { userAccessToken },
    } = useAppStateContext();

    const { budgetId = "" } = useParams();

    const { error, data, isLoading } = useGetListQuery<BudgetShareDTO>(
        BUDGET_SHARE_GET_ALL.replace(":budgetId", budgetId),
        BUDGET_SHARE_CACHE_KEY + budgetId,
        userAccessToken
    );

    return (
        <ModelListView
            model={BudgetShareModel(budgetId)}
            dialogTitle={"Budget Shares"}
            rows={data}
            error={error}
            isLoading={isLoading}
            initialData={BudgetShareBuilder.empty()}
        />
    );
}
