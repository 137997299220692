import { BUDGET_CACHE_KEY, BUDGET_GET_ONE } from "../../constants";
import { useAppStateContext } from "../App";
import { ReactElement } from "react";
import { BudgetModel } from "../../models/BudgetModel";
import { useGetOneQuery } from "../../hooks/useGetOneQuery";
import { useParams } from "react-router-dom";
import { ModelView } from "../views/ModelView";
import { BudgetDTO } from "@fspringveldt/qf-budget-generated-api-types";

export function Budget(): ReactElement {
    const {
        state: { userAccessToken },
    } = useAppStateContext();

    const { budgetId = "" } = useParams();

    const { error, data, isLoading } = useGetOneQuery<BudgetDTO>(
        BUDGET_GET_ONE.replace(":budgetId", budgetId),
        BUDGET_CACHE_KEY + budgetId,
        userAccessToken
    );

    return (
        <ModelView
            model={BudgetModel}
            row={data}
            error={error}
            isLoading={isLoading}
        />
    );
}
