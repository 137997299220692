import { FormDataProps } from "./FormDataProps";
import { FormControl, Grid, TextField } from "@mui/material";
import { CountrySelector } from "../CountrySelector";
import React, { useEffect } from "react";
import { useAppStateContext } from "../App";
import { TaxYearEntityDTO } from "@fspringveldt/qf-budget-generated-api-types";

export const TaxYearForm = <TObject extends TaxYearEntityDTO>(
    props: FormDataProps<TObject>
) => {
    const { state, setFieldValue, validationResult } = props;
    const {
        state: { userInfo },
    } = useAppStateContext();
    useEffect(() => {
        // Set country from default country if empty.
        if (!state.country && userInfo?.defaultCountry) {
            setFieldValue("country", userInfo.defaultCountry);
        }
    }, [setFieldValue, state.country, userInfo]);
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <CountrySelector
                        fieldName={"country"}
                        fieldValue={state.country || userInfo?.defaultCountry}
                        setFieldValue={setFieldValue}
                        validationResult={validationResult}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <TextField
                        autoComplete={"off"}
                        id={"taxYear"}
                        required
                        variant="filled"
                        label="Tax year"
                        type={"number"}
                        value={state.taxYear}
                        onChange={(e) =>
                            setFieldValue(
                                "taxYear",
                                parseInt(e.target.value, 10)
                            )
                        }
                        error={!!validationResult?.errors?.taxYear}
                        helperText={validationResult?.errors?.taxYear}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
};
