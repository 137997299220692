import { FormDataProps } from "./FormDataProps";
import { Grid, TextField } from "@mui/material";
import React from "react";
import { TaxTierEntityDTO } from "@fspringveldt/qf-budget-generated-api-types";

export const TaxTierForm = <TObject extends TaxTierEntityDTO>(
    props: FormDataProps<TObject>
) => {
    const { state, setFieldValue, validationResult } = props;
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <TextField
                    autoComplete={"off"}
                    fullWidth
                    id={"position"}
                    required
                    variant="filled"
                    label="Position"
                    type={"number"}
                    value={state.position}
                    onChange={(e) =>
                        setFieldValue("position", parseInt(e.target.value, 10))
                    }
                    error={!!validationResult?.errors?.position}
                    helperText={
                        validationResult?.errors?.position ??
                        "Position of this tier in the tier list."
                    }
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    autoComplete={"off"}
                    fullWidth
                    id={"rate"}
                    required
                    variant="filled"
                    label="Rate"
                    type={"number"}
                    value={state.rate}
                    onChange={(e) =>
                        setFieldValue("rate", parseFloat(e.target.value))
                    }
                    error={!!validationResult?.errors?.rate}
                    helperText={validationResult?.errors?.rate}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    autoComplete={"off"}
                    fullWidth
                    id={"start"}
                    required
                    variant="filled"
                    label="Start amount"
                    type={"number"}
                    value={state.start}
                    onChange={(e) =>
                        setFieldValue("start", parseFloat(e.target.value))
                    }
                    error={!!validationResult?.errors?.start}
                    helperText={
                        validationResult?.errors?.start ??
                        "Enter to nearest cent."
                    }
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    autoComplete={"off"}
                    fullWidth
                    id={"end"}
                    variant="filled"
                    label="End amount"
                    type={"number"}
                    value={state.end}
                    onChange={(e) =>
                        setFieldValue("end", parseFloat(e.target.value))
                    }
                    error={!!validationResult?.errors?.end}
                    helperText={
                        validationResult?.errors?.end ??
                        "Enter to nearest cent."
                    }
                />
            </Grid>
        </Grid>
    );
};
