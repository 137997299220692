import { BaseBuilder } from "./BaseBuilder";
import { NonWorkingDayEntityDTO } from "@fspringveldt/qf-budget-generated-api-types";

export class NonWorkingDayBuilder extends BaseBuilder<NonWorkingDayEntityDTO> {
    constructor(
        protected model: NonWorkingDayEntityDTO = {
            country: "",
            date: new Date().toDateString(),
            isOwned: false,
        }
    ) {
        super();
    }

    static empty(): NonWorkingDayEntityDTO {
        return new NonWorkingDayBuilder().build();
    }

    build(): NonWorkingDayEntityDTO {
        return this.model;
    }
}
