import React from "react";
import {
    CONVERSION_RATE_CACHE_KEY,
    CONVERSION_RATES_GET_ALL,
} from "../../constants";
import { ModelListView } from "../views/ModelListView";
import { useGetListQuery } from "hooks/useGetListQuery";
import { useAppStateContext } from "../App";
import { ConversionRateModel } from "models/ConversionRateModel";
import { ConversionRateEntityDTO } from "@fspringveldt/qf-budget-generated-api-types";
import { ConversionRateBuilder } from "../../mocks/builders/ConversionRateBuilder";

export function ConversionRate(): React.ReactElement {
    const {
        state: { userAccessToken },
    } = useAppStateContext();

    const { error, data, isLoading } = useGetListQuery<ConversionRateEntityDTO>(
        CONVERSION_RATES_GET_ALL,
        CONVERSION_RATE_CACHE_KEY,
        userAccessToken
    );

    return (
        <ModelListView
            model={ConversionRateModel}
            dialogTitle={"Conversion Rates"}
            error={error}
            rows={data}
            initialData={ConversionRateBuilder.empty()}
            isLoading={isLoading}
        />
    );
}
