import React, { useEffect, useState } from "react";
import { useAppStateContext } from "./App";
import { getCountryCurrency } from "../data/Currencies";
import { InputAdornment, TextField } from "@mui/material";

export const AmountField = ({
    country,
    changeHandler,
    error,
    helperText,
    defaultValue,
}: IAmountFieldProps) => {
    const [currencySymbol, setCurrencySymbol] = useState<string>("$");
    const {
        state: { userInfo },
    } = useAppStateContext();

    useEffect(() => {
        const currencyCountry = country ?? userInfo?.defaultCountry;

        if (currencyCountry) {
            setCurrencySymbol(getCountryCurrency(currencyCountry));
        }
    }, [country, userInfo?.defaultCountry]);

    return (
        <TextField
            autoComplete="off"
            id="Amount"
            required
            variant="filled"
            label="Amount"
            type={"number"}
            onChange={changeHandler}
            inputProps={{
                defaultValue,
                inputMode: "numeric",
                pattern: "[0-9]*",
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        {currencySymbol}
                    </InputAdornment>
                ),
            }}
            error={error}
            helperText={helperText}
        />
    );
};

interface IAmountFieldProps {
    changeHandler: (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void;
    helperText?: React.ReactNode;
    error?: boolean;
    country?: string;
    defaultValue?: string | number | readonly string[];
}
