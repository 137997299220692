import { BudgetSharePermissionEnum } from "@fspringveldt/qf-budget-generated-api-types";

interface BudgetSharePermissionInterface {
    label: string;
    value: BudgetSharePermissionEnum;
}

export const budgetSharePermissions: BudgetSharePermissionInterface[] = [
    { label: "Read", value: BudgetSharePermissionEnum.Read },
    { label: "Write", value: BudgetSharePermissionEnum.Write },
];
