import { AppActionTypes } from "./AppActionTypes";
import { User } from "@auth0/auth0-react";
import { MeDTO } from "@fspringveldt/qf-budget-generated-api-types";

export type AppReducerActions =
    | SetSuccessNotice
    | SetErrorNotice
    | SetIsProfileIncomplete
    | SetIsUserReady
    | SetUserInfo
    | SetAuth0User
    | SetAuth0Error
    | RequestLogin
    | Login
    | Logout
    | Other;

interface Other {
    type: string;
}

interface Login {
    type: AppActionTypes.LOGIN;
    token: string;
}

export const doLogin = (token: string): Login => ({
    type: AppActionTypes.LOGIN,
    token,
});

interface Logout {
    type: AppActionTypes.LOGOUT;
}

export const doLogout = (): Logout => ({
    type: AppActionTypes.LOGOUT,
});

interface RequestLogin {
    type: AppActionTypes.REQUEST_LOGIN;
}

export const doRequestLogin = (): RequestLogin => ({
    type: AppActionTypes.REQUEST_LOGIN,
});

interface SetAuth0Error {
    type: AppActionTypes.SET_AUTHO_ERROR;
    payload: boolean;
}

export const setAuth0Error = (payload: boolean): SetAuth0Error => ({
    type: AppActionTypes.SET_AUTHO_ERROR,
    payload,
});

interface SetAuth0User {
    type: AppActionTypes.SET_AUTHO_USER;
    payload: User | undefined;
}

export const setAuth0User = (payload: User | undefined): SetAuth0User => ({
    type: AppActionTypes.SET_AUTHO_USER,
    payload,
});

interface SetUserInfo {
    type: AppActionTypes.SET_USER_INFO;
    payload?: MeDTO;
}

export const setUserInfo = (payload: MeDTO | undefined): SetUserInfo => ({
    type: AppActionTypes.SET_USER_INFO,
    payload,
});

interface SetIsUserReady {
    type: AppActionTypes.SET_IS_USER_READY;
    payload: boolean;
}

export const setIsUserReady = (payload: boolean): SetIsUserReady => ({
    type: AppActionTypes.SET_IS_USER_READY,
    payload,
});

interface SetIsProfileIncomplete {
    type: AppActionTypes.SET_IS_PROFILE_INCOMPLETE;
    payload: boolean;
}

export const setIsProfileIncomplete = (
    payload: boolean
): SetIsProfileIncomplete => ({
    type: AppActionTypes.SET_IS_PROFILE_INCOMPLETE,
    payload,
});

interface SetSuccessNotice {
    type: AppActionTypes.SET_SUCCESS_NOTICE;
    payload: string;
}

export const setSuccessNotice = (payload: string): SetSuccessNotice => ({
    type: AppActionTypes.SET_SUCCESS_NOTICE,
    payload,
});

interface SetErrorNotice {
    type: AppActionTypes.SET_ERROR_NOTICE;
    payload: string;
}

export const setErrorNotice = (payload: string): SetErrorNotice => ({
    type: AppActionTypes.SET_ERROR_NOTICE,
    payload,
});
