import React, { useEffect, useState } from "react";
import { FormControl, Grid, Switch, TextField } from "@mui/material";
import { CountrySelector } from "../CountrySelector";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
    EXPENSE_CATEGORY_CACHE_KEY,
    EXPENSE_CATEGORY_GET_ALL,
    FUND_ACCOUNT_CACHE_KEY,
    FUND_ACCOUNT_GET_ALL,
} from "../../constants";
import { FormDataProps } from "./FormDataProps";
import { frequencies } from "../../data/Frequencies";
import { Calendar } from "./calendar/Calendar";
import { Dropdown } from "./dropdown/Dropdown";
import { useGetListQuery } from "hooks/useGetListQuery";
import { useAppStateContext } from "../App";
import { AmountField } from "../AmountField";
import { sanitiseDate } from "../../utils/utils";
import {
    ExpenseCategory,
    ExpenseEntityDTO,
    FundAccount,
} from "@fspringveldt/qf-budget-generated-api-types";

export function ExpenseForm<T extends ExpenseEntityDTO>({
    state,
    setFieldValue,
    validationResult,
}: FormDataProps<T>) {
    const {
        state: { userAccessToken },
    } = useAppStateContext();
    const [expenseCategoryData, setExpenseCategoryData] = useState<
        { label: string; value: string | undefined }[]
    >([]);
    const [fundAccountData, setFundAccountData] = useState<
        { label: string; value: string | undefined }[]
    >([]);
    const { data: ecData } = useGetListQuery<ExpenseCategory>(
        EXPENSE_CATEGORY_GET_ALL,
        EXPENSE_CATEGORY_CACHE_KEY,
        userAccessToken
    );
    const { data: faData } = useGetListQuery<FundAccount>(
        FUND_ACCOUNT_GET_ALL,
        FUND_ACCOUNT_CACHE_KEY,
        userAccessToken
    );

    useEffect(() => {
        if (ecData) {
            const data =
                ecData?.map(({ name, id }) => ({
                    label: name,
                    value: id,
                })) || [];
            setExpenseCategoryData(data);
        }
        if (faData) {
            const data =
                faData?.map(({ name, id }) => ({
                    label: name,
                    value: id,
                })) || [];
            setFundAccountData(data);
        }
    }, [ecData, faData]);

    // Ensure default country is set if found. Also set currency symbol
    useEffect(() => {
        if (!state.expenseCategory) {
            const defaultValue = expenseCategoryData.find(
                ({ label }) => label === "Default"
            );

            defaultValue &&
                setFieldValue("expenseCategory", {
                    id: defaultValue.value,
                    name: defaultValue.label,
                });
        }
        if (!state.fundAccount) {
            const defaultValue = fundAccountData.find(
                ({ label }) => label === "Default"
            );

            defaultValue &&
                setFieldValue("fundAccount", {
                    id: defaultValue.value,
                    name: defaultValue.label,
                });
        }
    }, [
        setFieldValue,
        state.expenseCategory,
        state.fundAccount,
        expenseCategoryData,
        fundAccountData,
    ]);

    return (
        <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <CountrySelector
                        fieldName={"country"}
                        fieldValue={state.country}
                        setFieldValue={setFieldValue}
                        validationResult={validationResult}
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <TextField
                        autoComplete="off"
                        id="expenseName"
                        required
                        defaultValue={state.name}
                        onChange={(e) => setFieldValue("name", e.target.value)}
                        label={"Expenses name"}
                        variant="filled"
                        error={!!validationResult?.errors?.name}
                        helperText={validationResult?.errors?.name}
                        autoFocus={true}
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <AmountField
                        changeHandler={(e) =>
                            setFieldValue("amount", parseFloat(e.target.value))
                        }
                        error={!!validationResult?.errors?.amount}
                        helperText={validationResult?.errors?.amount}
                        defaultValue={state.amount}
                        country={state.country}
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <Dropdown
                        label="Frequency"
                        fieldName={"frequency"}
                        fieldValue={state.frequency}
                        setFieldValue={setFieldValue}
                        validationResult={validationResult}
                        options={frequencies}
                        returnValueOnly={true}
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <Calendar
                        label="Start date"
                        fieldName={"startDate"}
                        fieldValue={sanitiseDate(state.startDate)}
                        setFieldValue={setFieldValue}
                        validationResult={validationResult}
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    {expenseCategoryData && (
                        <Dropdown
                            label={"Expenses Category"}
                            fieldName={"expenseCategory"}
                            fieldValue={state.expenseCategory}
                            setFieldValue={setFieldValue}
                            validationResult={validationResult}
                            options={expenseCategoryData}
                        />
                    )}
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <Dropdown
                        label={"Fund Account"}
                        fieldName={"fundAccount"}
                        fieldValue={state.fundAccount}
                        setFieldValue={setFieldValue}
                        validationResult={validationResult}
                        options={fundAccountData}
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <Calendar
                        label="End date"
                        fieldName={"endDate"}
                        fieldValue={sanitiseDate(state.endDate)}
                        setFieldValue={setFieldValue}
                        validationResult={validationResult}
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={state.isActive}
                            onChange={(e) =>
                                setFieldValue("isActive", e.target.checked)
                            }
                        />
                    }
                    label={"Is active"}
                />
            </Grid>
        </Grid>
    );
}
