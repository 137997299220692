import { ValidatorResult } from "../types/Model";
import { UserEntityDTO } from "@fspringveldt/qf-budget-generated-api-types";

export function validator(state: UserEntityDTO): ValidatorResult {
    let errors: Record<string, string> = {};

    if (!state.defaultCountry) {
        errors.defaultCountry = "Default country is required";
    }

    return errors;
}
