import { useAppStateContext } from "../App";
import { FormControl, Grid, TextField } from "@mui/material";
import { Calendar } from "./calendar/Calendar";
import { CountrySelector } from "../CountrySelector";
import React, { useEffect } from "react";
import { FormDataProps } from "./FormDataProps";
import { NonWorkingDayEntityDTO } from "@fspringveldt/qf-budget-generated-api-types";

export const NonWorkingDayForm = <T extends NonWorkingDayEntityDTO>({
    state,
    setFieldValue,
    validationResult,
}: FormDataProps<T>) => {
    const {
        state: { userInfo },
    } = useAppStateContext();

    useEffect(() => {
        if (!state.country && userInfo?.defaultCountry) {
            const { defaultCountry } = userInfo;
            setFieldValue("country", defaultCountry);
        }
        if (!state.date) {
            setFieldValue("date", new Date());
        }
    }, [state.country, userInfo, setFieldValue]);
    return (
        <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <TextField
                        autoComplete="off"
                        id="name"
                        defaultValue={state.name}
                        onChange={(e) => setFieldValue("name", e.target.value)}
                        label={"Name"}
                        variant="filled"
                        error={!!validationResult?.errors?.name}
                        helperText={validationResult?.errors?.name}
                        autoFocus={true}
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <CountrySelector
                        fieldName={"country"}
                        fieldValue={state.country || userInfo?.defaultCountry}
                        setFieldValue={setFieldValue}
                        validationResult={validationResult}
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <Calendar
                        label="Start date"
                        fieldName={"date"}
                        fieldValue={new Date(state.date)}
                        setFieldValue={setFieldValue}
                        validationResult={validationResult}
                        required
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <Calendar
                        label="End date"
                        fieldName={"endDate"}
                        fieldValue={new Date(state.endDate ?? "")}
                        setFieldValue={setFieldValue}
                        validationResult={validationResult}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
};
