import { TAX_TIER_CACHE_KEY, TAX_TIER_GET_ALL } from "../constants";
import { TableCell } from "@mui/material";
import { TaxTierForm } from "../components/forms/TaxTierForm";
import { Model, ValidatorResult } from "../types/Model";
import { TaxTierEntityDTO } from "@fspringveldt/qf-budget-generated-api-types";

export const TaxTierModel = (taxYearId: string): Model<TaxTierEntityDTO> => {
    const url = TAX_TIER_GET_ALL.replace(":taxYearId", taxYearId);
    return {
        addURL: url,
        cacheKey: TAX_TIER_CACHE_KEY + taxYearId,
        columns: [
            { sortByField: "position", label: "Position" },
            { sortByField: "rate", label: "Rate" },
            { sortByField: "start", label: "Start" },
            { sortByField: "end", label: "End" },
        ],
        deleteURL: url,
        editURL: url,
        label: "Tax tier",
        render: (row) => (
            <>
                <TableCell>{row.position}</TableCell>
                <TableCell>{row.rate}</TableCell>
                <TableCell>{row.start}</TableCell>
                <TableCell>{row.end}</TableCell>
            </>
        ),
        renderMutation: (row, setFieldValue, validationResult) => (
            <TaxTierForm
                state={row}
                setFieldValue={setFieldValue}
                validationResult={validationResult}
            />
        ),
        validator,
    };
};

export function validator(state: TaxTierEntityDTO): ValidatorResult {
    let errors: Record<string, string> = {};
    const ENTER_NUMBER = "Enter a number";
    if (isNaN(state.position)) {
        errors.position = ENTER_NUMBER;
    }
    if (isNaN(state.rate)) {
        errors.rate = ENTER_NUMBER;
    }
    if (isNaN(state.start)) {
        errors.start = ENTER_NUMBER;
    }
    if (state.end && isNaN(state.end)) {
        errors.end = `${ENTER_NUMBER} or leave blank`;
    }
    return errors;
}
