import Drawer from "@mui/material/Drawer";
import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    styled,
} from "@mui/material";
import { Link } from "react-router-dom";
import { NavRoute, sideMenuSettingsSection } from "../routes/NavDefinitions";
import Divider from "@mui/material/Divider";
import React from "react";
import "./SideMenu.css";
import { useAppStateContext } from "../App";
import { checkUserRoles } from "../../utils/checkUserRoles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTheme } from "@mui/material/styles";

interface SideMenuProps {
    toggleDrawer: (event: React.KeyboardEvent | React.MouseEvent) => void;
    openState: boolean;
}

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

export const SideMenu = (props: SideMenuProps) => {
    const theme = useTheme();
    const { openState: state, toggleDrawer } = props;
    const {
        state: { userAccessToken },
    } = useAppStateContext();

    const createMenuItems = (source: NavRoute[]) =>
        source.reduce((result: React.ReactElement[], item) => {
            const isAllowed = checkUserRoles(userAccessToken, item.roles);
            isAllowed &&
                result.push(
                    <Link
                        key={item.path}
                        style={{
                            textDecoration: "none",
                            color: "text.primary",
                        }}
                        to={item.path}
                        onClick={toggleDrawer}
                    >
                        <ListItem>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.menuTitle} />
                        </ListItem>
                    </Link>
                );
            return result;
        }, [] as React.ReactElement[]);

    const settingsMenu: React.ReactElement[] = createMenuItems(
        sideMenuSettingsSection
    );

    return (
        <Drawer open={state} anchor={"left"} onClose={toggleDrawer}>
            <Box sx={{ width: 250 }} role="presentation">
                <DrawerHeader>
                    <IconButton onClick={toggleDrawer}>
                        {theme.direction === "ltr" ? (
                            <ChevronLeftIcon />
                        ) : (
                            <ChevronRightIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                {/*<List>{setupMenu}</List>*/}
                {/*<Divider />*/}
                <List>{settingsMenu}</List>
                <Divider />
            </Box>
        </Drawer>
    );
};
