import React, { ReactElement } from "react";
import { INCOME_CACHE_KEY, INCOME_GET_ALL } from "../../constants";
import { ModelListView } from "../views/ModelListView";
import { IncomeModel } from "models/IncomeModel";
import { useGetListQuery } from "hooks/useGetListQuery";
import { useAppStateContext } from "../App";
import { useParams } from "react-router-dom";
import { IncomeEntityDTO } from "@fspringveldt/qf-budget-generated-api-types";
import { IncomeBuilder } from "../../mocks/builders/IncomeBuilder";

export function Incomes(): ReactElement {
    const {
        state: { userAccessToken },
    } = useAppStateContext();
    const { budgetId = "" } = useParams<string>();

    const { error, data, isLoading } = useGetListQuery<IncomeEntityDTO>(
        INCOME_GET_ALL.replace(":budgetId", budgetId),
        INCOME_CACHE_KEY + budgetId,
        userAccessToken
    );

    return (
        <ModelListView
            model={IncomeModel(budgetId)}
            dialogTitle={"Income"}
            error={error}
            rows={data}
            initialData={IncomeBuilder.empty()}
            isLoading={isLoading}
        />
    );
}
