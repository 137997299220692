import {
    DebitEntityDTO,
    ExpenseCategoryEntityDTO,
    ExpenseEntityDTO,
    ExpenseFrequencyEnum,
    FundAccountEntityDTO,
} from "@fspringveldt/qf-budget-generated-api-types";
import { ExpenseCategoryBuilder } from "./ExpenseCategoryBuilder";
import { FundAccountBuilder } from "./FundAccountBuilder";
import { BaseIncomeOrExpenseBuilder } from "./BaseIncomeOrExpenseBuilder";

export class ExpenseBuilder extends BaseIncomeOrExpenseBuilder<ExpenseEntityDTO> {
    constructor(
        protected model: ExpenseEntityDTO = {
            amount: 0,
            country: "",
            debits: [],
            expenseCategory: ExpenseCategoryBuilder.empty(),
            frequency: ExpenseFrequencyEnum.Weekly,
            fundAccount: FundAccountBuilder.empty(),
            isActive: false,
            name: "",
            startDate: "",
            isOwned: true,
        }
    ) {
        super();
    }

    static empty(): ExpenseEntityDTO {
        return new ExpenseBuilder().build();
    }

    build(): ExpenseEntityDTO {
        return this.model;
    }

    withExpenseCategory(expenseCategory: ExpenseCategoryEntityDTO) {
        this.model.expenseCategory = expenseCategory;
        return this;
    }

    withFrequency(frequency: ExpenseFrequencyEnum) {
        this.model.frequency = frequency;
        return this;
    }

    withFundAccount(fundAccount: FundAccountEntityDTO) {
        this.model.fundAccount = fundAccount;
        return this;
    }

    withDebits(debits: Array<DebitEntityDTO>) {
        this.model.debits = debits;
        return this;
    }
}
