export const headers: Record<string, string> = {
    Accept: "application/json",
    "Content-Type": "application/json",
};

export function addBearerTokenHeader(
    userAccessToken: string,
    otherHeaders: Record<string, string>
): Record<string, string> {
    if (userAccessToken) {
        return {
            ...otherHeaders,
            Authorization: `Bearer ${userAccessToken}`,
        };
    }

    return otherHeaders;
}
