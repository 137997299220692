import {
    NON_WORKING_DAY_CACHE_KEY,
    NON_WORKING_DAY_GET_ALL,
} from "../constants";
import { TableCell } from "@mui/material";
import React from "react";
import { NonWorkingDayForm } from "../components/forms/NonWorkingDayForm";
import { Model, ValidatorResult } from "../types/Model";
import { NonWorkingDayEntityDTO } from "@fspringveldt/qf-budget-generated-api-types";

export const NonWorkingDayModel: Model<NonWorkingDayEntityDTO> = {
    cacheKey: NON_WORKING_DAY_CACHE_KEY,
    addURL: NON_WORKING_DAY_GET_ALL,
    editURL: NON_WORKING_DAY_GET_ALL,
    deleteURL: NON_WORKING_DAY_GET_ALL,
    columns: [
        {
            sortByField: "date",
            label: "Date",
        },
        {
            sortByField: "country",
            label: "Country",
        },
        {
            sortByField: "name",
            label: "Name",
        },
    ],
    label: "Non-working day",
    validator,
    render: ({ date, country, name }) => (
        <>
            <TableCell>{date?.toString()}</TableCell>
            <TableCell>{country}</TableCell>
            <TableCell>{name}</TableCell>
        </>
    ),
    renderMutation: (row, setFieldValue, validationResult) => (
        <NonWorkingDayForm
            state={row}
            setFieldValue={setFieldValue}
            validationResult={validationResult}
        />
    ),
};

export function validator(state: NonWorkingDayEntityDTO): ValidatorResult {
    let errors: Record<string, string> = {};

    if (!state.date) {
        errors.date = "Start date is required";
    }
    if (!state.country) {
        errors.country = "Country is required";
    }
    return errors;
}
