import React from "react";
import { Alert, Paper, Skeleton, Stack } from "@mui/material";
import { Model } from "../../types/Model";
import { BaseDTOType } from "../../mocks/builders/BaseBuilder";

interface Props {
    children?: React.ReactNode;
}

export interface ModelViewProps<TObject extends BaseDTOType> extends Props {
    model: Model<TObject>;
    error: Error | null;
    row: TObject | undefined;
    isLoading: boolean;
}

export function ModelView<TObject extends BaseDTOType>(
    props: ModelViewProps<TObject>
) {
    const { error, row, model, isLoading = true } = props;

    const { renderView } = model;

    if (!isLoading && error) {
        return (
            <Alert severity="error">
                Something went wrong while fetching your data.
            </Alert>
        );
    }

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            {!isLoading && row && renderView && renderView(row)}
            {isLoading && (
                <Stack spacing={1}>
                    <Skeleton />
                </Stack>
            )}
        </Paper>
    );
}
