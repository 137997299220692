import { CONVERSION_RATE_CACHE_KEY } from "../constants";
import { TableCell } from "@mui/material";
import React from "react";
import { Model } from "../types/Model";
import { ConversionRateEntityDTO } from "@fspringveldt/qf-budget-generated-api-types";

export const ConversionRateModel: Model<ConversionRateEntityDTO> = {
    cacheKey: CONVERSION_RATE_CACHE_KEY,
    columns: [
        { sortByField: "code", label: "Code" },
        { sortByField: "value", label: "Code" },
    ],
    label: "Currencies",
    render({ code, value }: ConversionRateEntityDTO): React.ReactElement {
        return (
            <>
                <TableCell>{code}</TableCell>
                <TableCell>{value}</TableCell>
            </>
        );
    },
};
