import React, { ReactElement, useEffect } from "react";
import { useDeleteQuery } from "./useDeleteQuery";
import { useAppStateContext } from "components/App";
import { setErrorNotice, setSuccessNotice } from "reducers/AppActionCreators";
import { useSelectableRows } from "./useSelectableRows";
import {
    CircularProgress,
    IconButton,
    TableCell,
    Tooltip,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import { BaseDTOType } from "../mocks/builders/BaseBuilder";
import { displayNoneXs } from "../components/views/ModelListView";

interface RowSelectButtonProps {
    id: string;
    rowNumber: number;
}

export interface UseDataDeleteResult {
    SelectAllButton: () => ReactElement;
    RowSelectButton: (props: RowSelectButtonProps) => ReactElement;
    ToolbarButton: () => ReactElement;
    selected: string[];
}

export function useDataDelete<TObject extends BaseDTOType>(
    deleteURL: string,
    queryKey: string,
    rows: TObject[] | undefined
): UseDataDeleteResult {
    const {
        state: { userAccessToken },
        dispatch,
    } = useAppStateContext();

    const { selected, handleSelectAllClick, handleCheckChanged, setSelected } =
        useSelectableRows();

    const {
        isSuccess: isDeleteSuccess,
        isError: isDeleteError,
        mutate: deleteMutate,
        error: deleteError,
        isPending: deleteIsLoading,
    } = useDeleteQuery(deleteURL, queryKey, userAccessToken);

    useEffect(() => {
        if (isDeleteError && deleteError) {
            console.error(deleteError);
            dispatch(setErrorNotice(deleteError.message));
        }

        if (isDeleteSuccess) {
            dispatch(setSuccessNotice("Delete success."));
        }
    }, [dispatch, isDeleteError, deleteError, isDeleteSuccess]);

    const handleDelete = (items: string[]) => () => {
        deleteMutate(items, {
            onSuccess: () => {
                // Remove deleted items
                setSelected(selected.filter((i) => !items.includes(i)));
            },
        });
    };

    const isSelected = (key: string) => selected?.indexOf(key) !== -1;
    const numSelected = selected.length;
    const rowCount = rows?.length || 0;

    const RowSelectButton = ({ id, rowNumber }: RowSelectButtonProps) => {
        if (!deleteURL) return <></>;

        return (
            <TableCell padding="checkbox">
                <Checkbox
                    onChange={() => handleCheckChanged(id)}
                    color="primary"
                    checked={isSelected(id)}
                    inputProps={{
                        "aria-label": "select " + rowNumber,
                    }}
                />
            </TableCell>
        );
    };

    const SelectAllButton = () => {
        if (!deleteURL) return <></>;
        return (
            <TableCell sx={displayNoneXs} padding="checkbox">
                <Checkbox
                    color="primary"
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={(e) =>
                        rows &&
                        handleSelectAllClick(
                            e,
                            rows.reduce((acc: string[], next) => {
                                next.id && acc.push(next.id.toString());
                                return acc;
                            }, [] as string[])
                        )
                    }
                    inputProps={{
                        "aria-label": "select all",
                    }}
                />
            </TableCell>
        );
    };

    const ToolbarButton = () => {
        const { length } = selected;
        if (!deleteURL || length === 0) return <></>;
        return (
            <Typography
                sx={{ flex: "1 1 100%" }}
                color="inherit"
                variant="subtitle1"
                component="div"
                textAlign={"right"}
            >
                {length} selected
                <Tooltip title="Delete">
                    <>
                        {deleteIsLoading && (
                            <IconButton>
                                <CircularProgress />
                            </IconButton>
                        )}
                        {!deleteIsLoading && (
                            <IconButton onClick={handleDelete(selected)}>
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </>
                </Tooltip>
            </Typography>
        );
    };

    return {
        SelectAllButton,
        RowSelectButton,
        ToolbarButton,
        selected,
    };
}
