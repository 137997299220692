import { TAX_YEAR_CACHE_KEY, TAX_YEAR_GET_ALL } from "../constants";
import { TableCell } from "@mui/material";
import React from "react";
import { TaxYearForm } from "components/forms/TaxYearForm";
import { Link } from "react-router-dom";
import { navDefinitions } from "../components/routes/NavDefinitions";
import { Model, ValidatorResult } from "../types/Model";
import { TaxYearEntityDTO } from "@fspringveldt/qf-budget-generated-api-types";

export const TaxYearModel: Model<TaxYearEntityDTO> = {
    addURL: TAX_YEAR_GET_ALL,
    cacheKey: TAX_YEAR_CACHE_KEY,
    columns: [
        { sortByField: "country", label: "Country" },
        { sortByField: "taxYear", label: "Year" },
        { label: "" },
    ],
    deleteURL: TAX_YEAR_GET_ALL,
    editURL: TAX_YEAR_GET_ALL,
    label: "Tax year",
    render: (row) => (
        <>
            <TableCell>{row.country}</TableCell>
            <TableCell>{row.taxYear}</TableCell>
            <TableCell>
                <Link
                    to={navDefinitions.taxTier.path.replace(
                        ":taxYearId",
                        row.id as string
                    )}
                >
                    View Tiers
                </Link>
            </TableCell>
        </>
    ),
    renderMutation: (row, setFieldValue, validationResult) => (
        <TaxYearForm
            state={row}
            setFieldValue={setFieldValue}
            validationResult={validationResult}
        />
    ),
    validator,
};

export function validator(state: TaxYearEntityDTO): ValidatorResult {
    let errors: Record<string, string> = {};
    if (!state.country) {
        errors.country = "Country is required";
    }
    if (!state.taxYear) {
        errors.taxYear = "Tax year is required";
    }
    return errors;
}
