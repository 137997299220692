import React, { Dispatch, ReactNode, useReducer } from "react";

export interface AppContextType<TStateType, TActionType> {
    state: TStateType;
    dispatch: Dispatch<TActionType>;
}

/**
 * Utility function to create a strongly typed bit of context
 * @param Context
 * @param rootReducer
 * @param initialState
 */
export function createAppContext<TStateType, TActionType>(
    Context: React.Context<AppContextType<TStateType, TActionType>>,
    rootReducer: (state: TStateType, action: TActionType) => TStateType,
    initialState: TStateType
) {
    function AppContextProvider({ children }: { children: ReactNode }) {
        const [state, dispatch] = useReducer(rootReducer, initialState);

        return (
            <Context.Provider value={{ state, dispatch }}>
                {children}
            </Context.Provider>
        );
    }

    return { AppContextProvider } as const;
}
