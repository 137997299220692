import { Frequency } from "./Frequency";
import { INCOME_CACHE_KEY, INCOME_GET_ALL } from "../constants";
import { TableCell } from "@mui/material";
import React from "react";
import { IncomeForm } from "../components/forms/IncomeForm";
import { Model, ValidatorResult } from "../types/Model";
import { IncomeEntityDTO } from "@fspringveldt/qf-budget-generated-api-types";
import {
    displayNoneXs,
    ModelListViewSMTableCell,
} from "../components/views/ModelListView";

export const IncomeModel = (budgetId: string): Model<IncomeEntityDTO> => {
    const url = INCOME_GET_ALL.replace(":budgetId", budgetId);
    return {
        addURL: url,
        cacheKey: INCOME_CACHE_KEY + budgetId,
        columns: [
            { sortByField: "name", label: "Name" },
            { sortByField: "country", label: "Country" },
            { sortByField: "amount", label: "Salary" },
            { sortByField: "frequency", label: "Frequency" },
            { sortByField: "startDate", label: "Start" },
            { sortByField: "endDate", label: "End" },
        ],
        deleteURL: url,
        editURL: url,
        label: "Income",
        render: (row) => (
            <>
                <ModelListViewSMTableCell
                    heading={row.name}
                    info={[
                        row.country,
                        row.amount,
                        row.frequency,
                        row.startDate?.toString(),
                        row.endDate?.toString(),
                    ]}
                />
                <TableCell sx={displayNoneXs}>{row.name}</TableCell>
                <TableCell sx={displayNoneXs}>{row.country}</TableCell>
                <TableCell sx={displayNoneXs}>{row.amount}</TableCell>
                <TableCell sx={displayNoneXs}>{row.frequency}</TableCell>
                <TableCell sx={displayNoneXs}>
                    {row.startDate?.toString()}
                </TableCell>
                <TableCell sx={displayNoneXs}>
                    {row.endDate?.toString()}
                </TableCell>
            </>
        ),
        validator,
        renderMutation: (row, setFieldValue, validationResult) => (
            <IncomeForm
                state={row}
                setFieldValue={setFieldValue}
                validationResult={validationResult}
            />
        ),
    };
};

export function validator(state: IncomeEntityDTO): ValidatorResult {
    let errors: Record<string, string> = {};
    if (!state.name) {
        errors.name = "Name is required";
    }
    if (!state.amount) {
        errors.amount = "After tax salary is required";
    }
    if (!state.frequency) {
        errors.frequency = "Frequency is required";
    }
    if (!state.country) {
        errors.country = "Country is required";
    }
    if (!state.startDate) {
        errors.startDate = "Start date is required";
    }
    if (state.gstPercent !== undefined && isNaN(state.gstPercent)) {
        errors.gstPercent = "Only numeric values allowed.";
    }
    if (
        state.withholdingTaxPercent !== undefined &&
        isNaN(state.withholdingTaxPercent)
    ) {
        errors.withholdingTaxPercent = "Only numeric values allowed.";
    }

    if (state.frequency === Frequency.HOURLY && !state.hoursPerDay) {
        errors.hoursPerDay = "Required if income frequency is hourly";
    }
    return errors;
}
