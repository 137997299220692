import { FUND_ACCOUNT_CACHE_KEY, FUND_ACCOUNT_GET_ALL } from "../../constants";
import { ModelListView } from "../views/ModelListView";
import { FundAccountModel } from "models/FundAccountModel";
import { useGetListQuery } from "hooks/useGetListQuery";
import { useAppStateContext } from "../App";
import { FundAccount } from "@fspringveldt/qf-budget-generated-api-types";
import { FundAccountBuilder } from "../../mocks/builders/FundAccountBuilder";

export function FundAccounts(): React.ReactElement {
    const {
        state: { userAccessToken },
    } = useAppStateContext();
    const { error, data, isLoading } = useGetListQuery<FundAccount>(
        FUND_ACCOUNT_GET_ALL,
        FUND_ACCOUNT_CACHE_KEY,
        userAccessToken
    );
    return (
        <ModelListView
            model={FundAccountModel}
            dialogTitle={"Fund Account"}
            rows={data}
            error={error}
            initialData={FundAccountBuilder.empty()}
            isLoading={isLoading}
        />
    );
}
