import React, { createContext, Dispatch, useContext, useEffect } from "react";
import "./App.css";
import { Alert, Snackbar } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useAuth } from "hooks/useAuth";
import { AppRoutes } from "components/routes/AppRoutes";
import { USER_CACHE_KEY, USER_GET_ME } from "../constants";
import { useGetOneQuery } from "hooks/useGetOneQuery";
import { AppReducer } from "reducers/AppReducer";
import { AppContextType, createAppContext } from "utils/createAppContext";
import {
    AppReducerActions,
    setErrorNotice,
    setIsUserReady,
    setSuccessNotice,
    setUserInfo,
} from "reducers/AppActionCreators";
import { AppState } from "../types/AppState";
import { MeDTO } from "@fspringveldt/qf-budget-generated-api-types";

const autoHideDuration = 2000;

export const initialState: AppState = {
    userAccessToken: "",
    userInfo: undefined,
    isProfileIncomplete: false,
    isUserReady: false,
    isAuthenticated: false,
    successNotice: "",
    errorNotice: "",
    isAuth0Error: false,
    isAuthTokenRequested: false,
};

export const defaultDispatch: Dispatch<AppReducerActions> = () => initialState;

export const appContext = createContext<
    AppContextType<AppState, AppReducerActions>
>({
    state: initialState,
    dispatch: defaultDispatch,
});

// Create app context.
const { AppContextProvider } = createAppContext<AppState, AppReducerActions>(
    appContext,
    AppReducer,
    initialState
);

export const useAppStateContext = () => useContext(appContext);

function App() {
    return (
        <AppContextProvider>
            <AppComponent />
        </AppContextProvider>
    );
}

function AppComponent() {
    const {
        state: { isAuthenticated, errorNotice, successNotice, userAccessToken },
        dispatch,
    } = useAppStateContext();

    // Call useAuth hook
    useAuth(dispatch);

    const getOneQResult = useGetOneQuery<MeDTO>(
        USER_GET_ME,
        USER_CACHE_KEY,
        userAccessToken,
        {
            retry: 0,
        }
    );

    const {
        data: userPreferencesInfo,
        isFetched: isUserPreferencesFetched,
        isError,
        error,
        isLoading: isUserLoading,
    } = getOneQResult;

    function userPreferencesChecks() {
        if (isUserPreferencesFetched) {
            /**
             * The app's user api returns 404 if the user not found. This
             * is interpreted as a new user.
             */
            if (isError && error) {
                dispatch(setUserInfo(undefined));
            }
            if (userPreferencesInfo && !isError) {
                dispatch(setUserInfo(userPreferencesInfo));
            }

            if (isAuthenticated) {
                dispatch(setIsUserReady(true));
            }
        }
    }

    useEffect(userPreferencesChecks, [
        isAuthenticated,
        isUserLoading,
        isUserPreferencesFetched,
        isError,
        error,
        userPreferencesInfo,
        dispatch,
    ]);

    const hideSuccessNotice = () => dispatch(setSuccessNotice(""));
    const hideErrorNotice = () => dispatch(setErrorNotice(""));
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AppRoutes />
            <Snackbar
                open={!!successNotice}
                autoHideDuration={autoHideDuration}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                onClose={hideSuccessNotice}
            >
                <Alert severity="success" onClose={hideSuccessNotice}>
                    {successNotice || "Added."}
                </Alert>
            </Snackbar>
            <Snackbar
                open={!!errorNotice}
                autoHideDuration={autoHideDuration}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                onClose={hideErrorNotice}
            >
                <Alert severity="error" onClose={hideErrorNotice}>
                    {errorNotice ||
                        "An error occurred when trying to add a new item."}
                </Alert>
            </Snackbar>
        </LocalizationProvider>
    );
}

export default App;
