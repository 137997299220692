import {
    INCOME_BUDGET_DETAIL,
    INCOME_BUDGET_DETAIL_CACHE_KEY,
    pivotYears,
    thisMonth,
    thisYear,
} from "../../constants";
import { useParams } from "react-router-dom";
import { useAppStateContext } from "../App";
import { useGetOneQuery } from "hooks/useGetOneQuery";
import {
    Grid,
    Paper,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React from "react";
import { RecalculateButton } from "../RecalculateButton";
import { monthValues } from "../analytics/Analytics";
import groupBy from "lodash.groupby";
import {
    CreditEntityDTO,
    YearPreviewDTOCreditEntityDTO,
} from "@fspringveldt/qf-budget-generated-api-types";

interface Props {
    month?: number;
    noHeader?: boolean;
}

export const IncomeBudgetDetail = ({ month, noHeader }: Props) => {
    const { year = thisYear, budgetId = "" } = useParams();
    const targetYear = parseInt(String(year), 10) || thisYear;
    const {
        state: { userAccessToken },
    } = useAppStateContext();

    const cacheQueryKey = INCOME_BUDGET_DETAIL_CACHE_KEY + budgetId + year;
    const { isLoading, data } = useGetOneQuery<YearPreviewDTOCreditEntityDTO>(
        INCOME_BUDGET_DETAIL.replace(":budgetId", budgetId).replace(
            ":year",
            year.toString()
        ),
        cacheQueryKey,
        userAccessToken,
        {
            enabled: !!userAccessToken && pivotYears.includes(targetYear),
        }
    );

    const rows = !month
        ? data?.items
        : data?.items.filter((i) => i.month === month);

    const months = !month
        ? monthValues
        : monthValues.filter((i) => i.number === month);

    const groupedRows = groupBy(rows, "income.name");

    const filterRows = (
        rows: CreditEntityDTO[] | undefined,
        field: keyof CreditEntityDTO
    ) => rows?.map((r) => ({ month: r.month, value: r[field] }));

    const tableRows = Object.keys(groupedRows).map((incomeName) => ({
        incomeName,
        values: [
            {
                label: "Hours worked",
                values: filterRows(groupedRows[incomeName], "hoursWorked"),
            },
            {
                label: "Gross",
                values: filterRows(groupedRows[incomeName], "gross"),
            },
            {
                label: "GST",
                values: filterRows(groupedRows[incomeName], "gst"),
            },
            {
                label: "Tax",
                values: filterRows(groupedRows[incomeName], "incomeTax"),
            },
            {
                label: "Tax withheld",
                values: filterRows(groupedRows[incomeName], "taxWithheld"),
            },
            {
                label: "Tax remainder",
                values: filterRows(groupedRows[incomeName], "taxRemainder"),
            },
            {
                label: "Deposited",
                values: filterRows(
                    groupedRows[incomeName],
                    "bankDepositAmount"
                ),
            },
            {
                label: "Less tax/gst",
                values: filterRows(groupedRows[incomeName], "nett"),
            },
            {
                label: "Less expenses",
                values: filterRows(groupedRows[incomeName], "lessExpenses"),
            },
        ],
    }));
    console.debug("IncomeBudgetDetailData", data);
    console.debug("IncomeBudgetDetailGroupedRows", groupedRows);
    return (
        <>
            {!isLoading && data ? (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <RecalculateButton
                            refreshCacheKeys={[cacheQueryKey]}
                            year={year}
                            showTitle={!noHeader}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table stickyHeader aria-label="data table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Income details</TableCell>
                                        {months.map((month) => (
                                            <TableCell
                                                align={"center"}
                                                key={month.name}
                                                className={
                                                    month.number ===
                                                        thisMonth && !noHeader
                                                        ? "currMonth"
                                                        : ""
                                                }
                                            >
                                                {month.name}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!tableRows.length && (
                                        <TableRow>
                                            <TableCell colSpan={13}>
                                                No incomes found.
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {tableRows?.map((row, index) => (
                                        <React.Fragment
                                            key={`${row.incomeName}${index}`}
                                        >
                                            {row.values.map(
                                                (
                                                    { label, values },
                                                    valuesIndex
                                                ) => (
                                                    <React.Fragment
                                                        key={`${row.incomeName}${label}`}
                                                    >
                                                        {!valuesIndex && (
                                                            <TableRow>
                                                                <TableCell
                                                                    colSpan={13}
                                                                >
                                                                    <strong>
                                                                        <em>
                                                                            {
                                                                                row.incomeName
                                                                            }
                                                                        </em>
                                                                    </strong>
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                        <TableRow>
                                                            <TableCell
                                                                align={"center"}
                                                            >
                                                                {label}
                                                            </TableCell>
                                                            {months.map(
                                                                (month) => (
                                                                    <TableCell
                                                                        align={
                                                                            "center"
                                                                        }
                                                                        key={`${row.incomeName}${label}${month.name}`}
                                                                        className={
                                                                            month.number ===
                                                                                thisMonth &&
                                                                            !noHeader
                                                                                ? "currMonth"
                                                                                : ""
                                                                        }
                                                                    >
                                                                        {values
                                                                            ?.find(
                                                                                (
                                                                                    v
                                                                                ) =>
                                                                                    v.month ===
                                                                                    month.number
                                                                            )
                                                                            ?.value?.toString()}
                                                                    </TableCell>
                                                                )
                                                            )}
                                                        </TableRow>
                                                    </React.Fragment>
                                                )
                                            )}
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            ) : (
                <Stack spacing={1}>
                    <Skeleton variant="rectangular" width={153} height={36} />
                    <Skeleton variant="rectangular" height={400} />
                </Stack>
            )}
        </>
    );
};
